import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  selectRoles,
  selectUser,
  selectPermissions,
} from "../../../../store/authSlice";
import { Fragment, useState } from "react";
import {
  Popover,
  Dialog,
  Switch,
  Transition,
  Listbox,
} from "@headlessui/react";
import {
  MagnifyingGlassCircleIcon,
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import StackedAvatar from "../../../../components/atoms/StackedAvatar/StackedAvatar";
import FlyoutMenu from "../../../../components/atoms/FlyoutMenu/FlyoutMenu";
import Company from "../../../../api/Company";
import useQuery from "../../../../hooks/useQuery";
import { Pagination } from "antd";
import { formatDate } from "../../../../utils/dateConversion";
import DropDown from "../../../../components/atoms/Selector/DropDown";
import ControlPanel from "../../../../api/ControlPanel";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const BusinessPanel = ({ callback = false }) => {
  const [businessList, setBusinessList] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
    useState(false);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const query = useQuery();
  const [businessListPrepared, setBusinessListPrepared] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchInput, setSearchInput] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [businessId, setBusinessId] = useState(false);

  const sortOptions = [
    { id: 1, name: "No Sort" },
    { id: 2, name: "Type (Enterprise / Single Business)" },
    { id: 3, name: "Most Recently Created" },
    { id: 4, name: "Name (Alphabetically)" },
  ];

  useEffect(() => {
    getAllBusiness();
    callback("Business");
  }, []);

  const getAllBusiness = (
    pageNumber = 1,
    pageSize = 50,
    filter = "",
    sortCriterion = "",
    isExport = false
  ) => {
    const page = pageNumber;
    const limit = pageSize;

    const queryData = `filter[name]=${filter}&sort=${sortCriterion}&page=${page}&limit=${limit}`;

    ControlPanel.getAllBusiness(queryData)
      .then((response) => {
        setBusinessList(response.data.data);
        setTotalRecords(response.data.meta.total);
        setBusinessListPrepared(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          console.log("error");
        } else {
        }
      });
  };

  const capturePageClick = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    getAllBusiness(pageNumber, pageSize, searchInput);
  };

  const captureSearchInput = (searchInput) => {
    setSearchInput(searchInput);
    getAllBusiness(pageNumber, pageSize, searchInput);
  };

  const captureSort = (id) => {
    let criteria = "";
    if (id === 2) {
      criteria = "-is_enterprise";
    } else if (id === 3) {
      criteria = "-created_at";
    } else if (id === 4) {
      criteria = "name";
    }
    setSortCriteria(criteria);
    getAllBusiness(pageNumber, pageSize, searchInput, criteria);
  };

  const confirmDelete = (id) => {
    const xx = ControlPanel.deleteBusiness(id);
    setOpenDeleteConfirmationModal(false);
    getAllBusiness();
  };

  const deleteBusiness = async (id) => {
    setBusinessId(id);
    setOpenDeleteConfirmationModal(true);
  };

  return (
    <div>
      {openDeleteConfirmationModal && (
        <ConfirmationModal
          openTrigger={openDeleteConfirmationModal}
          closeTrigger={() => setOpenDeleteConfirmationModal(false)}
          type="error"
          title="Confirm Action"
          confirmText="Delete"
          selectedId={businessId}
          description="Are you sure you want to delete this company. This is a permanent action."
          confirm={confirmDelete}
        />
      )}
      <div className="divide-y divide-warmgray-300">
        <div className="flex flex-col">
          <div className="flex md:flex-row flex-col float-right divide-x-8 divide-white">
            <div className="w-[650px] pt-2"></div>
            <div className=" min-w-[350px] pt-2">
              <DropDown
                data={sortOptions}
                title="Sort By"
                captureSelection={captureSort}
              />
            </div>
            <div className="min-w-[350px] pt-2">
              <label
                htmlFor="search"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Quick search
              </label>
              <div className="relative mt-2 flex items-center">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-1 py-1.5 pr-14 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-warmgray-600 m:text-sm sm:leading-6"
                  onChange={(e) => captureSearchInput(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                    <MagnifyingGlassCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </kbd>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="space-y-5 pt-12">
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-2">
                Businesses
              </h3>
            </div>
            <div className="w-full border-10 shadow-sm border-black pl-1 pr-1">
              <div className="mt-1 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                          >
                            Business
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                          >
                            Owner
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                          >
                            Number Of Locations
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {businessListPrepared &&
                          businessList.map((business, index) => (
                            <tr key={business.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                                <div className="flex items-center">
                                  <div className="ml-0">
                                    <div
                                      key={index}
                                      onClick={() => {
                                        setBusinessNameShowPanel(
                                          !businessNameShowPanel
                                        );
                                      }}
                                      className="font-medium text-gray-900"
                                    >
                                      Name: {business.name}
                                    </div>
                                    <div className="text-gray-500">
                                      Created At :{" "}
                                      {formatDate(
                                        business.created_at,
                                        "MM-DD-YYYY HH:mm:ss"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="text-gray-900">
                                  {business.owners[0]
                                    ? business.owners[0].first_name
                                    : "No Owner"}
                                </div>
                                <div className="text-gray-500">
                                  {business.owners[0]
                                    ? business.owners[0].email
                                    : ""}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span className="inline-flex   ">
                                  {business.is_enterprise === 1 ? (
                                    <span className="rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800">
                                      Enterprise
                                    </span>
                                  ) : (
                                    <span className="rounded-full px-2 text-xs font-semibold leading-5 bg-orange-100 text-orange-800">
                                      Single Business
                                    </span>
                                  )}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {business.locations
                                  ? business.locations.length
                                  : ""}
                                {/* <StackedAvatar /> */}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                {business.id === 1 ? (
                                  "Admin"
                                ) : (
                                  <a
                                    href="#Delete"
                                    className="text-indigo-600 hover:text-indigo-900"
                                    onClick={() => deleteBusiness(business.id)}
                                  >
                                    Delete
                                    <span className="sr-only">
                                      , {business.name}
                                    </span>
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Pagination
          pageSize={50}
          total={totalRecords}
          onChange={capturePageClick}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default BusinessPanel;
