import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DashboardLayout } from "../../components/organisms/Layout";
import TableActionDropdown from "../../components/TableActionDropdown";
import QRCodes from "../../api/Qrcode";
import dayjs from "dayjs";
import Loading from "../../components/Loading";
import { DateFormat } from "../../utils";
import Posters from "../../api/Psoters";
import PaginationView from "../../components/molecules/Pagination";
import useQuery from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../store/authSlice";
import RBAC from "../../api/RBAC";

const Poster = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const [datas, setDatas] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const [qrList, setQrList] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [isPositionCreated, setPositionCreated] = useState(false);

  const loadPosters = async () => {
    try {
      setLoading(true);
      const page = query.get("page") || 1;
      const limit = query.get("limit") || pageLimit;
      const pageData = page ? `page=${page}` : "";
      const queryData = pageData + `&limit=${limit}`;
      const qrs = await Posters.getPosters(queryData);
      setLoading(false);
      setDatas(qrs.data.data);
      setMetaData(qrs.data.meta);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadQRList = async () => {
    await QRCodes.getAll()
      .then((response) => {
        setQrList(response.data.data);
      })
      .catch((error) => {
        console.log("loadQRList -> error", error);
      });
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const checkPositionCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Manage Poster";
    loadPosters();
    loadQRList();
    // checkPositionCreate();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-2xl">{t("poster.manage")}</h2>

          {/* {isPositionCreated && */}
          <Link to={"add"}>
            <button
              onClick={() => {
                navigate("/job-positions/add");
              }}
              type="submit"
              className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
            >
              <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
            </button>
          </Link>
          {/* } */}
        </div>

        <div className="overflow-x-auto overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 mt-6 text-sm xl:text-[14px]">
            <thead className="bg-gray-900 text-white">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Poster Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Banner Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Text Color
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Footer Color
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Footer Text
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 font-bold tracking-wider text-right"
                >
                  Date Created
                </th>
                {/*<th scope="col" className="flex flex-1 justify-center items-end font-bold">
                <div className="tbl-cell justify-end">{t("common.action")}</div>
              </th>*/}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {!loading &&
                datas.map((qrcode, i) => {
                  const _temp = JSON.parse(qrcode?.payload);
                  const _name = qrList.find((i) => i?.id === _temp?.qr_id);
                  return (
                    <tr
                      key={i}
                      className="cursor-pointer hover:bg-[#f6f6f6]"
                      onClick={() =>
                        navigate(`/business/posters/${qrcode.id}/edit`, {
                          state: { qrcode: qrcode },
                        })
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {_name?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {_temp?.template}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor: _temp?.colors?.text.toString(),
                          }}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor:
                              _temp?.colors?.footer_background.toString(),
                          }}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          class="border-2 rounded h-8 w-8 border-slate-500"
                          style={{
                            backgroundColor:
                              _temp?.colors?.footer_text.toString(),
                          }}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        {dayjs(qrcode?.created_at).format(DateFormat)}
                      </td>
                      {/*<td class="flex flex-1 items-center justify-center">
                      <div className="tbl-cell justify-end">
                        <TableActionDropdown
                            onClickEdit={() => {
                              navigate(`/business/posters/${qrcode.id}/edit`,{state:{qrcode:qrcode}})
                            }}
                            // onClickDelete={() => {
                              // setLocationToDelete(location.id);
                            // }}
                          />
                      </div>
                    </td>*/}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && <Loading count={8} />}
          <div className="flex w-full h-20 mt-2 justify-end items-center">
            {!loading && (
              <PaginationView
                current={metaData?.current_page}
                next={metaData?.to}
                pageSize={metaData?.per_page}
                total={metaData?.total}
                onChange={handlecPageChange}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Poster;
