import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  chatList,
  setChatListOpen,
  isChatListExpand,
  setChatListExpand,
  setSelectedPersons,
  selectedPersons,
  selectChats,
  conversationsLoading,
  chatToken,
  setRefreshToken,
  setConversations,
  conversations,
  selectedConversation,
  setSelectedConversation,
  setConversationLoading,
  chatApplicant,
  setChatApplicant,
  selectUser,
  selectSelectedLocation,
  setAddNewChat,
  setClientTwo,
  clientTwo,
  newMessage,
  setNewMessage,
  visiblePersonsArray,
  minimisedPersonsArray,
  setReadNotification,
  notificationApplicant,
  setNotificationApplicant,
} from "../../../store/authSlice";
import { useSelector, useDispatch } from "react-redux";
import ChatWindow from "./ChatWindow";
import { useChatEffects } from "./useChatEffects";
import ChatItem from "./ChatItem";
import { toast } from "react-toastify";
import { Client } from "@twilio/conversations";
import ChatApi from "../../../api/Chat";
import Application from "../../../api/Application";
import Notification from "../../../api/Notification";

export default function ChatList() {
  const dispatch = useDispatch();
  const openChatList = useSelector(chatList);
  const isChatListOpen = useSelector(isChatListExpand);
  const selectedPerson = useSelector(selectedPersons);
  const chats = useSelector(selectChats);
  const isConversationLoading = useSelector(conversationsLoading);
  const token = useSelector(chatToken);
  const conversation = useSelector(conversations);
  const [searchChat, setSearchChat] = useState("");
  const [filteredChats, setFilteredChats] = useState([]);
  const selectConversation = useSelector(selectedConversation);
  const chat_applicant = useSelector(chatApplicant);
  const notify_applicant = useSelector(notificationApplicant);
  const user = useSelector(selectUser);
  const locationId = user?.location_id;
  const selectedLocation = useSelector(selectSelectedLocation);
  const _clientToken = useSelector(clientTwo);
  const [latestMessageInfo, setLatestMessageInfo] = useState(null);
  const isNewMessage = useSelector(newMessage);
  const visiblePersons = useSelector(visiblePersonsArray);
  const minimizedPersons = useSelector(minimisedPersonsArray);

  useChatEffects();

  const HandleChatList = () => {
    dispatch(setChatListOpen(!openChatList));
  };

  const HandleChatExpandOrCollapse = () => {
    dispatch(setChatListExpand(!isChatListOpen));
  };

  const toggleSelectPerson = (person) => {
    if (!selectedPerson.includes(person)) {
      dispatch(setSelectedPersons([...selectedPerson, person]));
    } else {
      const updatedSelectedPersons = selectedPerson.filter(
        (p) => p.id !== person.id
      );
      dispatch(setSelectedPersons(updatedSelectedPersons));
      dispatch(setSelectedPersons([...selectedPerson, person]));
    }
    readNotification(person?.conversation_sid);
  };

  const togglePreSelectPerson = (person) => {
    if (!selectedPerson.includes(person)) {
      dispatch(setSelectedPersons([...selectedPerson, person]));
    } else {
      const updatedSelectedPersons = selectedPerson?.filter(
        (p) => p?.id !== person?.id
      );
      dispatch(setSelectedPersons(updatedSelectedPersons));
      dispatch(setSelectedPersons([...selectedPerson, person]));
    }
  };

  const loadConversations = async (client) => {
    dispatch(setConversationLoading(true));

    try {
      const conversationsObject = {};

      for (const chat of chats) {
        try {
          const result = await client.getConversationBySid(
            chat.conversation_sid
          );

          conversationsObject[chat.id] = {
            conversation_sid: chat.conversation_sid,
            conversations: result,
          };
        } catch (error) {
          if (error?.status === 404) {
            // console.log(
            //   `Conversation not found for sid: ${chat.conversation_sid}`
            // );
          } else {
            throw error;
          }
        }
      }

      dispatch(setConversations(conversationsObject));
      dispatch(setConversationLoading(false));
    } catch (error) {
      console.error(error);

      if (error?.status !== 403) {
        toast.error("Couldn't load conversations. Please Try Again.");
      }

      dispatch(setConversationLoading(false));
    }
  };

  useEffect(() => {
    const setupTwilio = async (token) => {
      const authToken = token;
      let client = new Client(authToken);
      dispatch(setClientTwo(client));

      const waitForConversations = new Promise(async (resolve) => {
        client.on("stateChanged", async (state) => {
          if (state === "initialized") {
            await loadConversations(client);
            resolve();
          }
        });
      });

      await waitForConversations;

      client.on("connectionStateChanged", (connectionState) => {
        if (connectionState === "denied") {
          dispatch(setRefreshToken(true));
        }
      });

      client.on("tokenAboutToExpire", () => {
        dispatch(setRefreshToken(true));
      });
    };

    if (token && chats.length > 0) {
      setupTwilio(token);
    }
  }, [token, chats]);

  useEffect(() => {
    searchChatName("");
  }, [chats]);

  useEffect(() => {
    if (isNewMessage) {
      dispatch(setNewMessage(false));
      loadConversations(_clientToken);
    }
  }, [isNewMessage]);

  const searchChatName = (txt) => {
    setSearchChat(txt);
    if (txt.trim() === "") {
      setFilteredChats(chats);
    } else {
      const newFilteredChats = chats.filter((chat) => {
        const firstNameMatch = chat.seeker.first_name
          .toLowerCase()
          .includes(txt.toLowerCase());
        const lastNameMatch = chat.seeker.last_name
          .toLowerCase()
          .includes(txt.toLowerCase());
        return firstNameMatch || lastNameMatch;
      });
      setFilteredChats(newFilteredChats);
    }
  };

  const addConversation = (id, sid) => {
    const conversationKeys = Object.keys(selectConversation);

    if (!conversationKeys.includes(id)) {
      dispatch(
        setSelectedConversation({
          ...selectConversation,
          [id]: {
            conversation_sid: sid,
            conversations: conversation[id].conversations,
          },
        })
      );
    }
  };

  const loadChatInfo = async () => {
    try {
      const response = await ChatApi.chatByLocation(
        selectedLocation?.id || locationId
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
    }
  };

  const loadApplicants = async (selectedApplicantsId) => {
    try {
      const response = await Application.getByLocationId(locationId, 1);
      const activeApplicants = response.data.data.filter(
        (a) => a.status === "applied"
      );
      const _activeApplicant = activeApplicants.find(
        (i) => i?.job_seeker_id === parseInt(selectedApplicantsId)
      );

      return _activeApplicant;
    } catch (error) {
      toast.error("Couldn't load applicants. Please try again.");
    }
  };

  const startConversation = async (applicationId) => {
    const checkApplicant = chats?.find(
      (item) => item?.job_seeker_id === applicationId
    );
    const checkConversation = Object.values(conversation).find(
      (item) => item?.conversations?.sid === checkApplicant?.conversation_sid
    );

    if (checkConversation) {
      addConversation(checkApplicant?.id, checkApplicant?.conversation_sid);
      toggleSelectPerson(checkApplicant);
      dispatch(setChatListOpen(false));
      dispatch(setChatApplicant(null));
    } else {
      dispatch(setChatListOpen(false));
      togglePreSelectPerson(checkApplicant);
      const response = await loadApplicants(applicationId);

      if (response) {
        try {
          await ChatApi.createChat({
            job_seeker_id: response.job_seeker_id,
            job_id: response.job_id,
          });

          const chatInfo = await loadChatInfo();

          const applicant = chatInfo?.find(
            (item) => item?.job_seeker_id === applicationId
          );

          const result = await _clientToken?.getConversationBySid(
            applicant?.conversation_sid
          );

          dispatch(
            setSelectedConversation({
              ...selectConversation,
              [applicant?.id]: {
                conversation_sid: result?.sid,
                conversations: result,
              },
            })
          );

          toggleSelectPerson(applicant);
          dispatch(setChatApplicant(null));
          toast.success("Conversation Started.");
        } catch (error) {
          const filterPersons = selectedPerson?.filter(
            (person) => person !== undefined
          );
          dispatch(setSelectedPersons(filterPersons));
          toast.error("Could not start a new conversation. Please try again.");
        } finally {
          dispatch(setAddNewChat(true));
        }
      }
    }
  };

  const startNotifyConversation = async (checkApplicant) => {
    const checkConversation = Object.values(conversation).find(
      (item) => item?.conversations?.sid === checkApplicant?.conversation_sid
    );

    if (checkConversation) {
      addConversation(checkApplicant?.id, checkApplicant?.conversation_sid);
      toggleSelectPerson(checkApplicant);
      dispatch(setChatListOpen(false));
      dispatch(setNotificationApplicant(null));
    } else {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  useEffect(() => {
    if (chat_applicant) {
      startConversation(chat_applicant);
      dispatch(setChatApplicant(null));
    }
  }, [chat_applicant]);

  useEffect(() => {
    if (notify_applicant) {
      startNotifyConversation(notify_applicant);
    }
  }, [notify_applicant]);

  const sortByLatestMessage = (chatArray) => {
    try {
      const sortedConversations = Object.entries(conversation)
        .map(([key, conversationObj]) => ({
          key,
          conversation: conversationObj.conversations,
        }))
        .sort((a, b) => {
          const aDate = a?.conversation?.lastMessage?.dateCreated || 0;
          const bDate = b?.conversation?.lastMessage?.dateCreated || 0;
          return bDate - aDate;
        });

      const sortedKeys = sortedConversations.map((item) => item.key);

      const sortedChatArray = sortedKeys
        .map((key) => chatArray.find((item) => item.id === parseInt(key)))
        .filter((item) => item !== undefined && item !== null);

      return sortedChatArray;
    } catch (e) {
      console.error("Error sorting conversations:", e);
      return chatArray;
    }
  };

  useEffect(() => {
    Object.values(conversation).map((conversationobj) => {
      let newConversation = conversationobj.conversations;
      newConversation.on("messageAdded", (m) => {
        if (m?.author[0] === "+") {
          setLatestMessageInfo({ message: m, conversation: newConversation });
        }
      });
    });
  }, [conversation]);

  useEffect(() => {
    if (latestMessageInfo) {
      const foundChat = chats.find(
        (chat) =>
          chat.conversation_sid === latestMessageInfo.message.conversation.sid
      );
      if (foundChat) {
        const isFoundInVisiblePersons = visiblePersons?.some(
          (person) => person.conversation_sid === foundChat.conversation_sid
        );

        const isFoundInMinimizedPersons = minimizedPersons?.some(
          (person) => person.conversation_sid === foundChat.conversation_sid
        );

        if (!isFoundInVisiblePersons && !isFoundInMinimizedPersons) {
          addConversation(foundChat.id, foundChat.conversation_sid);
          toggleSelectPerson(foundChat);
          dispatch(setChatListOpen(false));
        }
      }
    }
  }, [latestMessageInfo]);

  const readNotification = (conversationSid) => {
    const data = {
      event_type: "messaging",
      conversation_sid: conversationSid,
    };

    Notification.updateReadAt(data)
      .then((updateNotificationStatus) => {
        if (updateNotificationStatus?.data?.message === "success") {
          dispatch(setReadNotification(true));
        }
      })
      .catch((error) => {
        console.error("Notification update error:", error);
      });
  };

  return (
    <div>
      <Transition.Root show={openChatList}>
        <div className="relative z-20" onClose={HandleChatList}>
          <div className="fixed" />

          <div className="fixed overflow-hidden">
            <div className="absolute overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="pointer-events-auto mt-16 mr-6 mb-4">
                    <div
                      className={`flex h-full flex-col bg-white shadow-xl rounded-xl ${
                        isChatListOpen ? "w-72 lg:w-80" : "w-auto"
                      }`}
                    >
                      <div
                        className={`${isChatListOpen ? "p-6" : "pt-3 px-2"}`}
                      >
                        <div
                          className={`flex items-start justify-between ${
                            isChatListOpen ? "" : "flex-col"
                          }`}
                        >
                          {isChatListOpen ? (
                            <div className="text-base font-bold leading-6 text-gray-900">
                              HeyHire Chat
                            </div>
                          ) : null}

                          <div
                            className={`flex h-7 items-center ${
                              isChatListOpen ? "ml-3" : "ml-auto"
                            }`}
                          >
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 mr-3"
                              onClick={HandleChatExpandOrCollapse}
                            >
                              {isChatListOpen ? (
                                <MinusIcon className="h-5 w-5" />
                              ) : (
                                <PlusIcon className="h-5 w-5" />
                              )}
                            </button>

                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={HandleChatList}
                            >
                              <XMarkIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                          {!isChatListOpen ? (
                            <div className="text-base font-semibold leading-6 text-gray-900 ml-3 mt-2">
                              Chats
                            </div>
                          ) : null}
                        </div>
                        {isChatListOpen ? (
                          <div class="relative mt-4">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                class="w-4 h-4 text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                            </div>
                            <input
                              type="search"
                              id="default-search"
                              onChange={(text) =>
                                searchChatName(text.target.value)
                              }
                              value={searchChat}
                              class="block w-full p-2 pl-9 text-sm text-gray-900 border border-gray-300 rounded-full
                               bg-gray-50 focus:ring-gray-100 focus:border-gray-100"
                              placeholder="Search Chats"
                              required
                            />
                          </div>
                        ) : null}
                      </div>
                      <hr></hr>
                      {selectedLocation?.name === "Select A Location" ? (
                        <div
                          className={`py-6 text-center text-gray-500 px-2 ${
                            isChatListOpen ? "w-auto" : "w-24"
                          }`}
                        >
                          Please choose a location to access chat conversations.
                        </div>
                      ) : isConversationLoading ? (
                        <div className="text-center py-3">
                          <i className="fad fa-circle-notch fa-spin text-gray-400" />
                        </div>
                      ) : (
                        <>
                          {filteredChats?.length > 0 ? (
                            <ul className="flex-1 overflow-auto">
                              {sortByLatestMessage(filteredChats)?.map(
                                (chat) => (
                                  <li
                                    key={chat.id}
                                    className="divide-y divide-2 divide-gray-300"
                                  >
                                    <ChatItem
                                      chatInfo={chat}
                                      onClick={() => {
                                        addConversation(
                                          chat.id,
                                          chat?.conversation_sid
                                        );
                                        toggleSelectPerson(chat);
                                        HandleChatList();
                                      }}
                                      HandleChatList={HandleChatList}
                                      isChatListOpen={isChatListOpen}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <div
                              className={`py-6 text-center text-gray-500 px-2 ${
                                isChatListOpen ? "w-auto" : "w-24"
                              }`}
                            >
                              Chats will appear here once you have applicants
                              that have applied for your positions.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Transition.Root>
      {selectedPerson.length > 0 && <ChatWindow />}
    </div>
  );
}
