import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import User from "../../../api/User";
import Auth from "../../../api/auth";
import { toast } from "react-toastify";
import { DropActionDropdown } from "../../../components/TableActionDropdown";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import useFormatPhoneNumber from "../../../hooks/FormatPhoneNumber";
import { selectUser } from "../../../store/authSlice";
import useQuery from "../../../hooks/useQuery";
import PaginationView from "../../../components/molecules/Pagination";
import SuperUser from "../../../api/SuperUser";

const ManageBusinessUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatPhoneNumber = useFormatPhoneNumber;
  const query = useQuery();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    document.title = "HeyHire Portal-Users & Permissions";
  }, []);

  useEffect(() => {
    loadUsers();
  }, [query]);

  const loadUsers = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const limit = query.get("limit") || pageLimit;
    const queryData = sortData + pageData + `&limit=${limit}`;
    User.getAll(queryData)
      .then((response) => {
        const _users = response.data.data?.filter(
          (i) => i?.id !== currentUser?.id
        );
        setUsers(_users);
        setMetaData(response.data.meta);
      })
      .catch(() => {
        toast.error("Error trying to load users. Please try again.");
      })
      .then(() => setLoading(false));
  };

  const deleteUser = (userId) => {
    setLoading(true);
    User.deleteUser(userId)
      .then(() => {
        const dataCopy = users.slice();
        dataCopy.splice(
          users.findIndex((item) => item?.id === userId),
          1
        );
        setUsers(dataCopy);
        setLoading(false);
        toast.success("User Deleted.");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error trying to delete users. Please try again.");
      })
      .then(() => setLoading(false));
  };

  const suspendUser = (userId) => {
    setLoading(true);
    User.suspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = new Date();
          }
        });
        setUsers(dataCopy);
        toast.success("User Suspended.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to suspend users. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const unSuspendUser = (userId) => {
    setLoading(true);
    User.unSuspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = null;
          }
        });
        setUsers(dataCopy);
        toast.success("User UnSuspended.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to unsuspend users. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const requestResetPassword = (email) => {
    const body = {
      email: email,
    };
    Auth.ResetPasswordRequest(body)
      .then(() => {
        toast.success("Password reset email has been sent.");
      })
      .catch((error) => {
        toast.error("Something Went wrong. Please try again Later.");
      });
  };

  const resendWelcomeEmail = (userId) => {
    setLoading(true);
    SuperUser.resendWelcomeEmail(userId)
      .then(() => {
        toast.success("Email Sent.");
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error trying to resend welcome email. Please try again.");
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">
          {t("business-users.business-users")}
        </h2>

        <Button
          onClick={() => {
            navigate("add");
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 mt-3 pb-10">
          <thead className="bg-white">
            <tr>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.full-name")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.user-type")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.email")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.phone")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.company")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.last-login")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell">{t("common.status")}</div>
              </th>
              <th scope="col" className="font-bold">
                <div className="tbl-cell justify-end">{t("common.action")}</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              users.map((user) => {
                return (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td>
                      <div className="tbl-cell">
                        {user.first_name} {user.last_name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell capitalize">
                        {user.roles &&
                          user.roles.length > 0 &&
                          user.roles[0].name.replace("_", " ")}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">{user.email}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        {formatPhoneNumber(user.phone)}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">{user.company.name}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{user.last_login}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        {user?.suspended_at ? (
                          <div className="h-6 rounded border border-warmgray-200 bg-red-600 items-center justify-center">
                            <span className="text-white px-2 text-center font-bold">
                              Suspended
                            </span>
                          </div>
                        ) : (
                          <div className="h-6 rounded border border-warmgray-200 bg-green-600 items-center justify-center">
                            <span className="text-white px-4 text-center font-bold">
                              Active
                            </span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap">
                      <div className="tbl-cell justify-end">
                        {user.id == currentUser.id ? (
                          <DropActionDropdown
                            className="ml-5"
                            icon="far fa-ellipsis-h"
                            iconColor="text-primary-500"
                            menus={[
                              {
                                label: "Edit",
                                action: () =>
                                  navigate(
                                    `/admin/manage-business-users/edit/${user?.id}`
                                  ),
                              },
                            ]}
                          />
                        ) : (
                          <DropActionDropdown
                            className="ml-5"
                            icon="far fa-ellipsis-h"
                            iconColor="text-primary-500"
                            menus={[
                              {
                                label: "Edit",
                                action: () =>
                                  navigate(
                                    `/admin/manage-business-users/edit/${user?.id}`
                                  ),
                              },
                              {
                                label: "Delete",
                                action: () => deleteUser(user?.id),
                              },
                              {
                                label: "Suspend",
                                action: () => suspendUser(user?.id),
                                disabled: !!user?.suspended_at,
                              },
                              {
                                label: "Unsuspend",
                                action: () => unSuspendUser(user?.id),
                                disabled: !user?.suspended_at,
                              },
                              {
                                label: "Send Reset Password Link",
                                action: () => requestResetPassword(user?.email),
                              },
                              {
                                label: "Resend Welcome Email",
                                action: () => resendWelcomeEmail(user?.id),
                              },
                              {
                                label: t("general-settings.edit-password"),
                                action: () =>
                                  navigate(
                                    `/admin/manage-business-users/password-edit/${user?.id}`
                                  ),
                              },
                            ]}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex w-full h-20 mt-2 justify-end items-center">
          {!loading && (
            <PaginationView
              current={metaData?.current_page}
              next={metaData?.to}
              pageSize={metaData?.per_page}
              total={metaData?.total}
              onChange={handlecPageChange}
            />
          )}
        </div>
      </div>
      {loading && <Loading />}
    </DashboardLayout>
  );
};

export default ManageBusinessUsers;
