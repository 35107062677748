import { CheckIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProgressBar({ currentStep }) {
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;
  let totalSteps = 3;

  const generateSteps = () => {
    //   switch (selectedPlan?.planName) {
    //     case "go":
    //       totalSteps = 3;
    //       break;
    //     case "plus":
    //       totalSteps = 3;
    //       break;
    //     case "infinity":
    //       totalSteps = 3;
    //       break;
    //     default:
    //       console.error("Unrecognized plan:", selectedPlan);
    //       return [];
    //   }

    const steps = [];
    for (let i = 1; i <= totalSteps; i++) {
      const step = {
        name: `Step ${i}`,
        status:
          i < currentStep
            ? "complete"
            : i === currentStep
            ? "current"
            : "upcoming",
      };
      steps.push(step);
    }
    return steps;
  };

  const steps = generateSteps();

  return (
    <div className="flex justify-center my-8">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== totalSteps - 1
                ? `pr-8 sm:pr-20 md:pr-36 ${
                    totalSteps === 3 ? "pr-20 lg:pr-60" : "lg:pr-40"
                  }`
                : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-primary-500" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary-500">
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-300" />
                </div>
                <div
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-500 bg-white"
                  aria-current="step"
                >
                  <span
                    className={`h-2.5 w-2.5 rounded-full ${
                      currentStep === totalSteps
                        ? "bg-gray-300"
                        : "bg-primary-500"
                    }`}
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-300" />
                </div>
                <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}
