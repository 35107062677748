import React, { useEffect, useState } from "react";
import MultiSelect from "../../../../components/MultiSelect";
import Location from "../../../../api/Location";
import { toast } from "react-toastify";
import Input from "../../../../components/form/Input";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import Alert from "../../../../api/Alert";
import EditAlertModal from "../../../../components/modals/EditAlertModal";
import EventModal from "../../../../components/modals/EventModal";
import BroadcastModal from "../../../../components/modals/BroadcastModal";

const AlertsPanel = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFeature, setLoadingFeature] = useState(false);
  const [existingAlert, setExistingAlert] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [isNewAlert, setNewAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(null);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [alertFound, setAlertFound] = useState(false);

  const deleteAttachedAlert = async (item) => {
    setOpenDeleteConfirmationModal(true);
  };

  const loadFeatures = async (id) => {
    setLoadingFeature(true);
    try {
      const response = await Alert.getById(id);
      if (response?.data?.data) {
        setExistingAlert(response?.data?.data);
        setAlertFound(true);
      } else if (response?.data?.error === "Notification alert not found") {
        setExistingAlert([]);
        setAlertFound(false);
      }
      setName("");
      setMessage("");
    } catch (error) {
      console.error("Error loading features:", error);
      toast.error("Something went wrong");
    } finally {
      setLoadingFeature(false);
    }
  };

  useEffect(() => {
    if (optionSelected) {
      loadFeatures(optionSelected?.value);
      setNewAlert(false);
    }
  }, [optionSelected, isNewAlert]);

  const getLocationsList = async () => {
    setIsLoading(true);
    await Location.getAll().then((response) => {
      let fetchData = response.data.data;
      let locationData = [];

      for (let i = 0; i < fetchData.length; i++) {
        const el = fetchData[i];
        locationData.push({
          label: el.name,
          value: el.id,
        });
      }
      setData(locationData);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getLocationsList();
  }, []);

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const handlePostAlert = async () => {
    if (name === "" && message === "") {
      setNameError(true);
      setMessageError(true);
    } else if (name === "") {
      setNameError(true);
    } else if (message === "") {
      setMessageError(true);
    } else {
      setLoadingAlert(true);
      if (optionSelected) {
        try {
          const payload = {
            Name: name,
            Message: message,
            location_id: optionSelected?.value,
          };
          await Alert.add(payload);
          setNewAlert(true);
        } catch (error) {
          console.error("Error posting alert", error);
          toast.error("Error posting alert");
        }
      }
      setLoadingAlert(false);
    }
  };

  const handleRemoveAttachedAlert = async () => {
    if (optionSelected) {
      try {
        await Alert.delete(optionSelected?.value).then((response) => {
          toast.success(response?.data?.message);
          setNewAlert(true);
        });
      } catch (err) {
        console.error(err);
      }
    }
    setOpenDeleteConfirmationModal(false);
  };

  const handleEditAlert = async (item) => {
    setEditAlert(item);
    setShowModal(true);
  };

  return (
    <div>
      {showModal && (
        <EditAlertModal
          open={showModal}
          setOpen={setShowModal}
          editAlert={editAlert}
          setEditAlert={setEditAlert}
          setNewAlert={setNewAlert}
        />
      )}

      {showEventModal && (
        <EventModal open={showEventModal} setOpen={setShowEventModal} />
      )}

      {showBroadcastModal && (
        <BroadcastModal
          open={showBroadcastModal}
          setOpen={setShowBroadcastModal}
        />
      )}

      <ConfirmationModal
        openTrigger={openDeleteConfirmationModal}
        closeTrigger={() => setOpenDeleteConfirmationModal(false)}
        type="error"
        title="Confirm Action"
        confirmText="Delete"
        selectedId={optionSelected?.value}
        description="Are you sure you want to delete this alert?"
        confirm={handleRemoveAttachedAlert}
      />

      <div className="my-8">
        <div className="w-full flex flex-col md:flex-row items-center justify-end gap-2 mb-4">
          <button
            type="button"
            className="cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
            onClick={() => setShowBroadcastModal(true)}
          >
            Add Broadcast
          </button>

          {/* <button
            type="button"
            className="cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
            onClick={() => setShowEventModal(true)}
          >
            Add New Event
          </button> */}
        </div>
        <p className="text-xl mb-4 font-bold">Global Notifications</p>
        <div className="flex flex-col gap-4 w-full xl:w-3/4 2xl:w-1/2">
          <div className="flex flex-col justify-start md:w-[300px] xl:w-[360px]">
            <p className="text-md font-medium">Select Location</p>
            <div className="border border-warmgray-300 rounded-md">
              <MultiSelect
                key="sort"
                options={data}
                onChange={handleChange}
                value={optionSelected}
                menuPlacement={"bottom"}
                isLoading={isLoading}
              />
            </div>
          </div>

          {!alertFound && optionSelected && !loadingFeature && (
            <>
              <div className="flex flex-col justify-start md:w-[300px] xl:w-[360px]">
                <Input
                  name="name"
                  label="Alert Name"
                  type="text"
                  value={name}
                  error={nameError && "Alert Name is required"}
                  disabled={!optionSelected}
                  placeholder="Alert Name"
                  darmo={true}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="flex flex-col justify-start md:w-[300px] xl:w-[360px]">
                <Input
                  name="message"
                  label="Alert Message"
                  type="text"
                  value={message}
                  disabled={!optionSelected}
                  error={messageError && "Alert Message is required"}
                  placeholder="Alert Message"
                  darmo={true}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="mx-auto md:w-[300px] xl:w-[360px]">
                <button
                  type="button"
                  className="mt-4 mx-auto cursor-pointer font-bold text-sm px-6 py-2 w-fit rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                  onClick={() => handlePostAlert()}
                  disabled={!optionSelected}
                  // disabled={optionSelected ? false : true}
                >
                  Attach Alert to Location
                  {loadingAlert && (
                    <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {optionSelected && (
        <div className="flex flex-col w-full xl:w-3/4 2xl:w-1/2 overflow-auto border-t border-gray-600">
          {optionSelected && (
            <p className="mb-2 text-lg font-bold mt-2">
              Location Name: {optionSelected?.label}
            </p>
          )}
          {loadingFeature ? (
            <div className="flex justify-center w-full">Loading...</div>
          ) : (
            <>
              {existingAlert &&
                (alertFound ? (
                  <table className="min-w-full divide-y divide-gray-500 whitespace-nowrap">
                    <thead>
                      <tr>
                        <th className="w-[45%] py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">
                          Alert Name
                        </th>
                        <th className="w-[45%] py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">
                          Alert Message
                        </th>
                        <th className="w-[10%] py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-y border-warmgray-300">
                        <td className="text-left whitespace-nowrap py-2 pr-3 text-gray-900">
                          {existingAlert.name}
                        </td>
                        <td className="text-left">{existingAlert.message}</td>
                        <td className="text-right text-warmgray-600">
                          <button
                            className="btn btn-link mr-6"
                            aria-label="Edit"
                            onClick={() => handleEditAlert(existingAlert)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-link mr-3"
                            aria-label="Delete"
                            onClick={() => deleteAttachedAlert(existingAlert)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center">There is no existing alert.</p>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AlertsPanel;
