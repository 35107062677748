import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Payment from "../../api/Payment";
import PaginationView from "../../components/molecules/Pagination";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useCustomEventListener } from "react-custom-events";
import Cards from "../../api/Cards";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/modals/CheckoutForm";
import InvoiceTable from "../../components/atoms/Tables/InvoiceTable";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedLocation,
  selectUser,
  setChatListOpen,
  stripeCustomerId,
} from "../../store/authSlice";
import FormSelect from "../../components/atoms/FormSelect";

const stripePromise = loadStripe("pk_live_rWHf1KRxe5AUUwJCPsoHJEuN");

const Payments = () => {
  const { t } = useTranslation();
  const stripeCustID = useSelector(stripeCustomerId);
  const [showModal, setShowModal] = useState(false);
  const [multiLocationInfo, setMultiLocationInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const user = useSelector(selectUser);
  const [multiLocation, setMultiLocation] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [invoiceTableHeader, setInvoiceTableHeader] = useState([
    {
      name: "Invoice Id",
    },
    {
      name: "Amount",
    },
    {
      name: "Status",
    },
    {
      name: "Due Date",
    },
  ]);

  // useCustomEventListener("closeModal", () => {
  //   setShowModal(false);
  // });

  // useCustomEventListener("paymentAdded", () => {
  //   loadCardsByCustomerID(stripeCustID);
  // });

  // const loadCardsByCustomerID = async (id) => {
  //   const cards = await Cards.getCardByCustomerID(id);
  //   setCards(cards?.data?.['credit-cards']);
  // };

  // const updateCardsList = () => {
  //   loadCardsByCustomerID(stripeCustID);
  // };

  //   const loadLatestSubscriptionInfo = async () => {
  //     try {
  //      const info = await Payment.getLatestSubscriptionInfo();
  //      if (info?.data?.data.length === 1) {
  //        setSubscriptionInfo(info?.data?.data[0]);
  //      } else if (info?.data?.data.length > 1) {
  //        setMultiLocation(true);
  //        setMultiLocationInfo(info?.data?.data);
  //        if(selectedLocation?.name === "Select A Location"){
  //           setSubscriptionInfo(info?.data?.data[0]);
  //         } else {
  //           const matchingLocation = info?.data?.data?.find(item => item.location_name === selectedLocation.name);
  //           if (matchingLocation) {
  //             setSubscriptionInfo(matchingLocation);
  //           }
  //         }
  //      }
  //      setLoading(true);
  //    } catch (error) {
  //      setLoading(true);
  //      console.error('Error loading latest subscription info:', error);
  //      toast.error("Error loading latest subscription info");
  //    }
  //  };

  const loadLatestSubscriptionInfo = async () => {
    try {
      const info = await Payment.getStripeCustomerID(
        user?.company_id,
        user?.location_id
      );

      if (info) {
        const response = await Payment.getSubscriptionDetailsFromCustomerId(
          info?.data?.stripe_customer?.stripe_customer_id
        );
        // console.log(response?.data[0]);
        if (response?.data[0]) {
          setSubscriptionInfo(response?.data[0]);
        }
        if (response?.data?.length === 1) {
          setSubscriptionInfo(response?.data[0]);
        } else if (response?.data?.length > 1) {
          setMultiLocation(true);
          setMultiLocationInfo(response?.data);
          if (selectedLocation?.name === "Select A Location") {
            setSubscriptionInfo(response?.data[0]);
          } else {
            const matchingLocation = response?.data?.find(
              (item) => item.location_name === selectedLocation.name
            );
            if (matchingLocation) {
              setSubscriptionInfo(matchingLocation);
            }
          }
        }
      }
      //  if (info?.data?.data.length === 1) {
      //    setSubscriptionInfo(info?.data?.data[0]);
      //  } else if (info?.data?.data.length > 1) {
      //    setMultiLocation(true);
      //    setMultiLocationInfo(info?.data?.data);
      //    if(selectedLocation?.name === "Select A Location"){
      //       setSubscriptionInfo(info?.data?.data[0]);
      //     } else {
      //       const matchingLocation = info?.data?.data?.find(item => item.location_name === selectedLocation.name);
      //       if (matchingLocation) {
      //         setSubscriptionInfo(matchingLocation);
      //       }
      //     }
      //  }
      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.error("Error loading latest subscription info:", error);
      toast.error("Error loading latest subscription info");
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Billing Information";
    loadLatestSubscriptionInfo();
  }, [user]);

  const handlecPageChange = (currentPage) =>
    setSearchParams({ ...searchParams, page: currentPage });

  const [options, setOptions] = useState({});

  useEffect(() => {
    getSecret();
    dispatch(setChatListOpen(false));
  }, []);

  function getSecret() {
    Cards.getToken()
      .then((response) => {
        setOptions({
          clientSecret: response.data.data,
        });
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong");
        }
      });
  }

  const handleShow = (value) => {
    setShow(value);
  };

  const handleAddPayment = (value) => {
    setAddPayment(value);
  };

  return (
    <DashboardLayout darmo={true}>
      {multiLocation && selectedLocation?.name === "Select A Location" && (
        <div className="flex mb-4 md:w-1/2 lg:w-1/3">
          <FormSelect
            type="add-job"
            label="Select Location"
            placeholder="Select Location"
            classNames="font-bold"
            options={multiLocationInfo?.map((info) => ({
              value: info,
              label: info?.location_name,
            }))}
            onChange={(selectedOption) => {
              setSubscriptionInfo(selectedOption?.value);
              handleAddPayment(true);
            }}
            defaultValue={
              subscriptionInfo && {
                label: subscriptionInfo?.location_name,
                value: subscriptionInfo,
              }
            }
            value={
              subscriptionInfo && {
                label: subscriptionInfo?.location_name,
                value: subscriptionInfo,
              }
            }
            darmo={true}
          />
        </div>
      )}

      <hr></hr>
      {loading ? (
        <>
          <div className="border border-warmgray-300 p-4 rounded-md">
            <div className="sm:flex-auto pb-4">
              <h1 className="text-lg font-semibold leading-6 text-gray-900 border-b border-warmgray-300 pb-2">
                Subscription Information
              </h1>
            </div>
            <div className="mx-0 xl:mx-6 flex flex-col w-full">
              <div className="flex flex-col xl:flex-row gap-8 w-full">
                <div className="xl:w-1/2 mr-0 xl:mr-12">
                  <div className="border-b border-warmgray-300 pb-2">
                    <p className="text-primary-500 uppercase font-bold mb-4">
                      Your Subscription
                    </p>
                    <p>
                      <span className="text-lg xl:text-2xl mr-2">
                        {subscriptionInfo?.product?.name}
                      </span>
                      <span> - {subscriptionInfo?.product?.description}</span>
                    </p>
                  </div>
                </div>

                <div className="xl:w-1/2 mr-0 xl:mr-12">
                  <div className="border-b border-warmgray-300 pb-2">
                    <p className="text-primary-500 uppercase font-bold mb-4">
                      Your Next Bill
                    </p>
                    <p>
                      {subscriptionInfo?.invoices?.length > 0 ? (
                        <>
                          <span className="text-lg xl:text-2xl mr-2">
                            $ {subscriptionInfo?.invoices[0]?.amount_due}
                          </span>

                          <span>
                            on{" "}
                            {(() => {
                              const dueDate = new Date(
                                subscriptionInfo?.invoices[0]?.due_date
                              );
                              const nextDueDate = new Date(dueDate);

                              if (
                                subscriptionInfo?.product?.product_id ===
                                "prod_PZSeUBtaLkHpoK"
                              ) {
                                nextDueDate.setFullYear(
                                  nextDueDate.getFullYear() + 1
                                );
                              } else if (
                                subscriptionInfo?.product?.product_id ===
                                "prod_PZScydbUFAzzEZ"
                              ) {
                                nextDueDate.setMonth(
                                  nextDueDate.getMonth() + 1
                                );
                              }

                              const formattedNextDueDate = nextDueDate
                                .toISOString()
                                .split("T")[0];
                              return formattedNextDueDate;
                            })()}
                          </span>
                        </>
                      ) : (
                        <span className="">-</span>
                      )}
                    </p>
                  </div>

                  <div className="border-b border-warmgray-300 pb-2 mt-4">
                    <p className="text-primary-500 uppercase font-bold mb-4">
                      Your Last Bill
                    </p>
                    <p>
                      {subscriptionInfo?.invoices?.length > 0 ? (
                        <>
                          <span className="text-lg xl:text-2xl mr-2">
                            $ {subscriptionInfo?.invoices[0]?.amount_due}
                          </span>
                          <span className="">
                            on {subscriptionInfo?.invoices[0]?.due_date}
                          </span>
                        </>
                      ) : (
                        <span className="">-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <button
                  className="font-bold text-sm mr-2 px-6 py-2 w-fit rounded-md bg-red-500 text-white hover:bg-red-400 transition-all hover:opacity-50 disabled:opacity-50"
                  type="submit"
                  // onClick={() => handleSubmit()}
                >
                  Request Cancellation
                </button>
              </div>
            </div>
          </div>

          <div className="my-5 border border-warmgray-300 p-4 rounded-md">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold leading-6 text-gray-900 border-b border-warmgray-300 pb-2">
                  Payment Methods
                </h1>
              </div>

              {show && (
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className="block ml-auto rounded-md bg-primary-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700"
                    onClick={() => handleAddPayment(true)}
                  >
                    Add New Payment
                  </button>
                </div>
              )}
            </div>

            <div className="mt-4">
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  data={subscriptionInfo?.payment_methods}
                  // onChange={updateCardsList}
                  locationId={subscriptionInfo?.location_id}
                  locationName={subscriptionInfo?.location_name}
                  isCancel={true}
                  isNote={false}
                  isSkip={false}
                  isRegister={false}
                  handleShow={handleShow}
                  addPayment={addPayment}
                  handleAddPayment={handleAddPayment}
                />
              </Elements>
            </div>
          </div>

          {subscriptionInfo?.invoices?.length > 0 ? (
            <div className="p-4">
              <InvoiceTable
                title="Billing History"
                // content="Download the invoice for reference"
                data={subscriptionInfo?.invoices}
                showButton={false}
                showActionButton={false}
                header={invoiceTableHeader}
                onClickButton={() => {
                  setShowModal(true);
                }}
              />
            </div>
          ) : (
            <div className="sm:flex-auto p-4">
              <h1 className="text-lg font-semibold leading-6 text-gray-900 border-b border-warmgray-300 pb-2">
                Billing History
              </h1>
              <p className="mt-4">No invoice data available.</p>
            </div>
          )}
        </>
      ) : (
        <Loading size="2xl" header="Fetching Billing Info ...." />
      )}
    </DashboardLayout>
  );
};

export default Payments;
