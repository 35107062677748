import React, { useState, useEffect } from "react";
import { useCustomEventListener } from "react-custom-events";
import { toast } from "react-toastify";
import CannedMessageApi from "../../api/CannedMessage";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import TableActionDropdown from "../../components/TableActionDropdown";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/modals/DeleteModal";
import RBAC from "../../api/RBAC";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../store/authSlice";

const CannedMessages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [isPrecannedCreate, setPrecannedCreate] = useState(false);
  const [isPrecannedUpdate, setPrecannedUpdate] = useState(false);
  const [isPrecannedDelete, setPrecannedDelete] = useState(false);

  useCustomEventListener("closeModal", () => {
    setMessageToDelete(null);
  });

  useCustomEventListener("cannedMessageAdded", () => {
    loadData();
  });

  useEffect(() => {
    document.title = "HeyHire Portal-Message Templates";
    loadData();
    // checkPrecannedCreate();
    // checkPositionEdit();
    // checkPositionDelete();
  }, [selectedLocation]);

  const checkPrecannedCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "canned_messages",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPrecannedCreate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionEdit = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "canned_messages",
        operations: "update",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPrecannedUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionDelete = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "canned_messages",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPrecannedDelete(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadData = async () => {
    setLoading(true);
    await CannedMessageApi.getAll()
      .then((res) => setData(res.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg lg:text-2xl">
            {t("pc-messages.pre-canned-messages")}
          </h2>

          {/* {isPrecannedCreate && */}
          <button
            onClick={() => {
              navigate("/messages/canned-messages/add");
            }}
            className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
          >
            <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
          </button>
          {/* } */}
        </div>

        <div className="overflow-x-auto overflow-hidden mt-6">
          <table className="min-w-full">
            <thead className="bg-gray-900 text-white whitespace-nowrap">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  {t("common.message")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  {t("common.shortcut")}
                </th>
                {/* {(isPrecannedDelete || isPrecannedUpdate) &&  */}
                <th
                  scope="col"
                  className="px-6 py-2.5 font-bold tracking-wider text-right"
                >
                  {t("common.action")}
                </th>
                {/* } */}
              </tr>
            </thead>
            <tbody className="divide-white divide-y-8">
              {!loading &&
                data.map((message) => {
                  return (
                    <tr key={message.id} className="bg-[#f6f6f6]">
                      <td className="px-6 py-3 whitespace-nowrap md:whitespace-normal">
                        {message.message}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {message.shortcut}
                      </td>
                      {/* {(isPrecannedDelete || isPrecannedUpdate) && */}
                      <td className="px-6 py-3 whitespace-nowrap text-right cursor-pointer">
                        <TableActionDropdown
                          onClickEdit={() => {
                            navigate(`${message.id}/edit`);
                          }}
                          onClickDelete={() => setMessageToDelete(message.id)}
                          // isDelete={isPrecannedDelete}
                          // isUpdate={isPrecannedUpdate}
                          // isChanged={true}
                        />
                      </td>
                      {/* } */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && <Loading />}
        </div>

        {messageToDelete && (
          <DeleteModal
            title={t("pc-messages.delete-pre-canned-message")}
            name={t("pc-messages.pre-canned-message")}
            deleteRequest={() => CannedMessageApi.delete(messageToDelete)}
            onSuccess={() => {
              setMessageToDelete(null);
              loadData();
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default CannedMessages;
