import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Brand from "../../api/Brand";
import Region from "../../api/Region";
import AddressBook from "../../api/AddressBook";
import Company from "../../api/Company";
import Location from "../../api/Location";
import { toast } from "react-toastify";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DraftApi from "../../api/Draft";
import Draft from "../../components/Draft";
import { DashboardLayout } from "../../components/organisms/Layout";
import Category from "../../api/Category";
import Modal from "../../components/Modal";
// import AddAddressModal from "../../components/modals/AddAddressModal";
// import GoogleMaps from "../../components/atoms/GoogleMaps";
import PlacesAutocomplete from "../../components/form/PlacesAutocomplete";
import AddAddressContent from "../../components/AddAddressContent";
import { decodeIds } from "../../utils";
import BasicModal from "../../components/modals/BasicModal";
import { HiCreditCard, HiLocationMarker } from "react-icons/hi";
import AddCardModal from "../../components/modals/AddCardModal";
import AddNewAddressForm from "./AddNewAddressForm";

const AddLocation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const editMode = location.pathname.search("edit") !== -1;

  const [loc, setLoc] = useState(null);
  const [name, setName] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState();
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState("");
  const [newAddress, setNewAddress] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [urlSlug, setUrlSlug] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [decodedId, setDecodedId] = useState(decodeIds(params?.id)[0]);

  const loadBrands = () => {
    Brand.getAll().then((response) => {
      setBrands(response.data.data);
      if (!editMode)
        setBrand(
          response.data.data.length > 0 ? response.data.data[0].id : null
        );
    });
  };

  const loadRegions = () => {
    Region.getAll().then((response) => {
      setRegions(response.data.data);
      if (!editMode) {
        setRegion(
          response.data.data.length > 0 ? response.data.data[0].id : null
        );
      }
    });
  };

  const loadAddresses = (init = true) => {
    AddressBook.getAll().then((response) => {
      setAddresses(response.data.data);
      if (init) {
        if (!editMode) {
          setAddress(
            response.data.data.length > 0 ? response.data.data[0].id : null
          );
        }
      }
    });
  };

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
      if (!editMode) {
        setCategory(
          response.data.data.length > 0 ? response.data.data[0].id : null
        );
      }
    });
  };

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);
      if (!editMode) {
        setCompany(
          response.data.data.length > 0 ? response.data.data[0].id : null
        );
      }
    });
  };

  const addLocation = () => {
    setLoading(true);
    let _address = null;
    if (newAddress) {
      _address = newAddress;
      _address.company_id = company;
      setNewAddress(_address);
    }

    Location.add({
      name: name,
      website: website,
      company_id: company,
      address_id: address,
      brand_id: brand,
      region_id: region,
      category_id: category,
      url_slug: urlSlug,
    })
      .then(() => {
        toast.success(t("locations.location-added"));
        emitCustomEvent("dataChanged");
        emitCustomEvent("closeModal");
        navigate("/companies/locations");
        if (newAddress) {
          saveNewAddress(_address);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else if (error.response && error.response.status === 500) {
          toast.success(t("locations.location-added"));
          emitCustomEvent("dataChanged");
          emitCustomEvent("closeModal");
          navigate("/companies/locations");
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const saveDraft = () => {
    setDraftLoading(true);
    DraftApi.add({
      type: "add-location",
      title: name,
      payload: {
        name: name,
        website: website,
        company_id: company,
        address_id: address,
        brand_id: brand,
        region_id: region,
        url_slug: urlSlug,
      },
    })
      .then(() => {
        toast.success("Draft Saved");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("That didn't work. Please Try Again.");
        }
      })
      .then(() => {
        setDraftLoading(false);
      });
  };

  const loadDraft = () => {
    DraftApi.getAllByType("add-location").then((response) => {
      setDrafts(response.data.data);
    });
  };

  const setFromDraft = (draft) => {
    const payload = JSON.parse(draft.payload);
    setName(payload?.name);
    setWebsite(payload?.website);
    setCompany(payload?.company_id);
    setAddress(payload?.address_id);
    setBrand(payload?.brand_id);
    setRegion(payload?.region_id);
    setUrlSlug(payload?.url_slug);
  };

  const filteredBrands = () => {
    if (!company) return [];
    return brands.filter((brand) => brand.company_id === company);
  };

  const filteredRegions = () => {
    if (!company) return [];
    return regions.filter((region) => region.company_id === company);
  };

  const filteredAddresses = () => {
    if (!company) return [];
    return addresses.filter((address) => address.company_id === company);
  };

  const filterCompanies = () => {
    const _arrangeCompanies = companies.sort(
      (a, b) => b.is_enterprise - a.is_enterprise
    );
    return _arrangeCompanies;
  };

  const loadLocation = () => {
    setLoading(true);

    Location.getById(decodedId)
      .then((response) => {
        const location = response.data.data;
        setLoc(response.data.data);
        setName(location?.name);
        setCompany(location?.company?.id);
        setBrand(location?.brand?.id);
        setRegion(location?.region?.id);
        setCategory(location?.category_id);
        setAddress(location?.address?.id);
        setWebsite(location?.website);
        setUrlSlug(location?.url_slug);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const updateLocation = () => {
    setLoading(true);
    let _address = null;
    if (newAddress) {
      _address = newAddress;
      _address.company_id = company;
      setNewAddress(_address);
    }
    Location.update(decodedId, {
      name: name,
      website: website,
      company_id: company,
      address_id: address,
      brand_id: brand,
      region_id: region,
      category_id: category,
      url_slug: urlSlug,
    })
      .then(() => {
        toast.success(t("locations.location-updated"));
        emitCustomEvent("dataChanged");
        emitCustomEvent("closeModal");
        navigate("/companies/locations");
        if (newAddress) {
          saveNewAddress(_address);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit-Location";
    } else {
      document.title = "HeyHire Portal-Add-New-Location";
    }
    loadBrands();
    loadRegions();
    loadAddresses();
    loadCompanies();
    // loadDraft();
    loadCategories();

    if (editMode) {
      loadLocation();
    }
  }, []);

  useEffect(() => {
    if (company) {
      setAddress(
        filteredAddresses().length > 0 ? filteredAddresses()[0].id : null
      );
      setBrand(filteredBrands().length > 0 ? filteredBrands()[0].id : null);
      setRegion(filteredRegions().length > 0 ? filteredRegions()[0].id : null);
    }
  }, [company]);

  useEffect(() => {
    if (address === 999999) {
      setShowAddAddressModal(true);
    }
  }, [address]);

  // CUSTOM EVENTS
  useCustomEventListener("loadDraft", setFromDraft);

  useCustomEventListener("draftDeleted", loadDraft);

  useCustomEventListener("closeModal", () => {
    setShowAddAddressModal(false);
    setAddress(address);
  });

  useCustomEventListener("addressAdded", (_newAddress) => {
    setShowAddAddressModal(false);
    loadAddresses(false);
    setAddress(_newAddress.id);
  });

  const triggerCloseModal = (closeModal) => {
    if (closeModal) {
      setShowAddAddressModal(false);
      loadAddresses();
      toast.success("Address Added! Check dropdown.");
    }
  };

  useCustomEventListener("placeSelected", (data) => {
    const vName = data?.terms[0]?.value;
    const param = {
      name: vName,
      address: data?.addressLine,
      city: data?.terms[data.terms.length - 3]?.value,
      state: data?.terms[data.terms.length - 2]?.value,
      zip: data?.zipCode,
      lat: data?.lat,
      lng: data?.lng,
      company_id: company,
    };
    // set name
    setName(vName);
    // save new address
    setNewAddress(param);
  });

  const saveNewAddress = async (param) => {
    // check if address exists
    try {
      const exists = addresses.filter((a) => a.name == param.name);
      if (exists.length < 1) {
        const resp = await AddressBook.add(param);
        loadAddresses(true);
        setAddress(resp.data.data.id);
      } else {
        setAddress(exists[0].id);
      }
    } catch (error) {
      toast.error("failed to save address");
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full mt-6">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/companies/locations"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>
          {editMode
            ? t("locations.edit-location")
            : t("locations.add-a-location")}
        </h2>
        <hr className="mt-4" />

        {drafts.map((draft) => (
          <Draft className={"mt-2"} draft={draft} />
        ))}

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {/* <Input
              name="name"
              label={t("common.name")}
              type="text"
              value={name}
              placeholder={t("common.name")}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
              onChange={(e) => setName(e.target.value)}
            /> */}

            <Select
              name="company"
              label={t("locations.assign-a-business")}
              placeholder={t("locations.assign-a-business")}
              value={company}
              error={
                validationErrors && validationErrors.company_id
                  ? validationErrors.company_id[0]
                  : null
              }
              onChange={(e) => setCompany(parseInt(e.target.value))}
            >
              {filterCompanies().map((company) => (
                <option key={company?.id} value={company?.id}>
                  {company.name}
                </option>
              ))}
            </Select>

            <PlacesAutocomplete
              darmo={true}
              label={t("common.name")}
              placeholder={t("common.name")}
              pValue={name}
              isAddressSearch={false}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
            />

            <Select
              name="address"
              label={t("locations.assign-address")}
              placeholder={t("locations.assign-address")}
              value={address}
              error={
                validationErrors && validationErrors.address_id
                  ? validationErrors.address_id[0]
                  : null
              }
              onChange={(e) => setAddress(parseInt(e.target.value))}
              disabled={!company}
            >
              {filteredAddresses().map((a) => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
              <option
                onClick={() => setShowAddAddressModal(true)}
                value={999999}
                key={999999}
              >
                Add a New Address
              </option>
            </Select>

            <Select
              name="brand"
              label={t("locations.assign-a-brand")}
              value={brand}
              placeholder={t("common.please-select-one")}
              error={
                validationErrors && validationErrors.brand_id
                  ? validationErrors.brand_id[0]
                  : null
              }
              onChange={(e) => setBrand(parseInt(e.target.value))}
            >
              {filteredBrands().map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </Select>

            <Select
              name="category"
              label={t("locations.business-category")}
              value={category}
              placeholder={t("common.please-select-one")}
              error={
                validationErrors && validationErrors.category_id
                  ? validationErrors.category_id[0]
                  : null
              }
              onChange={(e) =>
                setCategory(categories[e.target.selectedIndex].id)
              }
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>

            <Input
              darmo={true}
              name="website"
              label={t("common.website")}
              type="text"
              value={website}
              slug="https://"
              placeholder={t("common.website")}
              error={
                validationErrors && validationErrors.website
                  ? validationErrors.website[0]
                  : null
              }
              onChange={(e) => setWebsite(e.target.value)}
            />

            <Input
              darmo={true}
              name="url_slug"
              label={t("common.url-slug")}
              type="text"
              value={urlSlug}
              placeholder={t("common.url-slug")}
              error={
                validationErrors && validationErrors.urlSlug
                  ? validationErrors.urlSlug[0]
                  : null
              }
              onChange={(e) => setUrlSlug(e.target.value)}
            />

            <Select
              name="region"
              label={t("locations.assign-a-region")}
              value={region}
              placeholder={t("common.please-select-one")}
              error={
                validationErrors && validationErrors.region_id
                  ? validationErrors.region_id[0]
                  : null
              }
              onChange={(e) => setRegion(parseInt(e.target.value))}
            >
              {filteredRegions().map((region) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
              ))}
            </Select>
          </div>

          <hr className="mt-8" />

          <div className="mt-4 flex w-full md:w-1/5">
            <button
              className="w-full rounded-xl p-2 m-1 text-white text-[14px] bg-primary-500 hover:bg-primary-700 hover:opacity-50 disabled:bg-violet-200"
              onClick={(e) => {
                e.preventDefault();
                if (editMode) {
                  updateLocation();
                } else {
                  addLocation();
                }
              }}
              disabled={loading}
            >
              {t("common.save")}
              {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              ) : (
                ""
              )}
            </button>
          </div>

          {/* <div className="text-center mt-5">
            <button onClick={saveDraft}>
              {t("click-here-to-save-as-draft")}{" "}
              {draftLoading && (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              )}
            </button>
          </div> */}
        </div>

        {showAddAddressModal && (
          <BasicModal
            title="Add New Address"
            openTrigger={showAddAddressModal}
            showActionButton={false}
            showCancelButton={false}
            closeTrigger={() => setShowAddAddressModal(false)}
            icon={
              <HiLocationMarker
                className="h-6 w-6 text-primary-700"
                aria-hidden="true"
              />
            }
            content=<AddNewAddressForm
              company={company}
              triggerClose={triggerCloseModal}
            />
          ></BasicModal>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AddLocation;
