import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropActionDropdown } from "../../components/TableActionDropdown";
import { useCustomEventListener } from "react-custom-events";
import FormJobSeeker from "../../components/molecules/FormJobSeeker";
import Button from "../../components/form/Button";
import useQuery from "../../hooks/useQuery";
import PaginationView from "../../components/molecules/Pagination";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import { ModalDeleteWrapper } from "../../components/organisms/ModalDeleteWrapper";
import JobSeeker from "../../api/JobSeeker";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFormatPhoneNumber from "../../hooks/FormatPhoneNumber";
import { defaultUserImage } from "../../assets/images";
import { encodeIds } from "../../utils";

const JobSeekers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatPhoneNumber = useFormatPhoneNumber;
  const query = useQuery();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    document.title = "HeyHire Portal-Job Seekers";
  }, []);

  useEffect(() => {
    loadJobSeekers();
  }, [query]);

  const loadJobSeekers = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData = sortData + pageData + `&limit=${limit}`;
    JobSeeker.getAll(queryData)
      .then((response) => {
        setJobSeekers(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong.");
        }
      })
      .then(() => setLoading(false));
  };

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    loadJobSeekers();
  });

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">{t("job-seekers.job-seekers")}</h2>

        {/*<Button
          className="mr-2.5"
          onClick={() => {
            setShowModal(true);
            // navigate("/job-seekers/add");
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>*/}
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 mt-3">
          <thead className="bg-white">
            <tr>
              <th scope="col">
                <div className="tbl-cell font-bold">
                  {t("job-seekers.applicant")}
                </div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.phone")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.email")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">
                  {`${t("common.city")} / ${t("common.state")}`}
                </div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">
                  {t("common.number-of-applications")}
                </div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.action")}</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              jobSeekers.map((jobSeeker) => {
                return (
                  <tr
                    key={jobSeeker.id}
                    className="cursor-pointer"
                    // onClick={() => navigate("view", { state: jobSeeker })}
                  >
                    <td>
                      <div className="tbl-cell flex">
                        <img
                          className="rounded-full mr-2"
                          width={24}
                          src={jobSeeker?.photo?.thumb_url || defaultUserImage}
                          alt="Profile"
                        />
                        {jobSeeker.first_name} {jobSeeker.last_name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        {formatPhoneNumber(jobSeeker.phone)}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">{jobSeeker.email}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        <span className="flex">{`${jobSeeker.city} / ${jobSeeker.state}`}</span>
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        {jobSeeker?.num_applications}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell justify-end">
                        <DropActionDropdown
                          className="ml-5"
                          icon="far fa-ellipsis-h"
                          iconColor="text-primary-500"
                          menus={[
                            {
                              label: "Edit",
                              action: () =>
                                navigate(
                                  `/job-seekers/edit/${encodeIds(jobSeeker.id)}`
                                ),
                            },
                            {
                              label: "Delete",
                              action: () => setShowModalDelete(true),
                            },
                            { label: "Resend Welcome Email", action: () => {} },
                          ]}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex w-full h-20 mt-2 justify-end items-center">
          {!loading && (
            <PaginationView
              current={metaData?.current_page}
              next={metaData?.to}
              pageSize={metaData?.per_page}
              total={metaData?.total}
              onChange={handlecPageChange}
            />
          )}
        </div>
        {loading && <Loading />}
      </div>
      <FormModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title={t("job-seekers.add-a-job-seeker")}
      >
        <FormJobSeeker />
      </FormModal>

      <ModalDeleteWrapper
        visible={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        title={t("common.delete") + " " + t("job-seekers.job-seekers")}
      />
    </DashboardLayout>
  );
};

export default JobSeekers;
