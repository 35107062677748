import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import User from "../../../api/auth.js";
import Input from "../../../components/form/Input";
import AuthLayout from "../../../components/organisms/Layout/Authlayout";
import CustomNotification from "../../../utils/CustomNotification";
import { darkmode } from "../../../store/authSlice";
import { heyhireBlackIcon, heyhireIcon } from "../../../assets/images.js";

const SuccessView = ({ resend }) => {
  const { t } = useTranslation();

  return (
    <p className="text-center font-semibold leading-[24px] lg:px-12 sm:px-8 lg:mb-[62px] text-14">
      {t("login.you-do-not-received-email")}&nbsp;
      <span
        className="text-primary-500 hover:text-primary-200 cursor-pointer"
        onClick={resend}
      >
        {t("login.resend")}
      </span>
      .
    </p>
  );
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const mode = useSelector(darkmode);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailNotRegistered, setEmailNotRegistered] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    document.title = "HeyHire Portal-Forgot Password";
    return () => {
      setEmail("");
      setLoading(false);
      setValidationErrors(null);
    };
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setInvalidEmail(false);
    setEmailNotRegistered(false);
    if (name === "email") {
      setEmail(value);
    }
  };

  const validEmail = (email) => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const checkEmail = email.match(re);
    return checkEmail;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validEmail(email)) {
      const body = {
        email: email,
      };
      User.ResetPasswordRequest(body)
        .then(() => {
          setLoading(false);
          localStorage.setItem("passwordResetEmail", email);
          setShowNotification({
            show: true,
            type: "success",
            message: "Password reset email has been sent.",
          });
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.data?.message ===
            'Attempt to read property "id" on null'
          ) {
            setEmailNotRegistered(true);
          } else {
            setShowNotification({
              show: true,
              type: "error",
              message: "Something Went wrong. Please try again Later.",
            });
          }
        });
    } else {
      setLoading(false);
      setInvalidEmail(true);
    }
  };

  return (
    <>
      <AuthLayout
        showLink={!completed}
        title={
          completed
            ? t("login.thanks-reset-password")
            : t("login.reset-password")
        }
      >
        <div className="flex min-h-full flex-1 flex-col justify-center  sm:px-6 lg:px-8">
          <div className={`sm:mx-auto sm:w-full sm:max-w-md rounded p-2 mx-2`}>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-[160px] lg:w-[250px] lg:mt-8"
                src={mode ? heyhireBlackIcon : heyhireIcon}
                alt="logo"
              />
              <h2
                className={`my-6 text-center text-2xl font-bold leading-9 tracking-tight ${
                  !mode ? "text-white" : "text-gray-900"
                }`}
              >
                {t("login.reset-password")}
              </h2>
            </div>
            {!completed ? (
              <>
                <p
                  className={`text-center font-semibold leading-[24px] lg:px-[37px] sm:px-8 ${
                    !mode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {t("login.enter-email-reset")}
                </p>

                <div className="text-left snap-start w-full lg:px-8 sm:px-8">
                  <div className="my-6 mx-1">
                    <Input
                      name="email"
                      label={t("common.email")}
                      type="email"
                      value={email}
                      placeholder={t("login.email-input-placeholder")}
                      error={
                        validationErrors && validationErrors.email
                          ? validationErrors.email[0]
                          : null
                      }
                      onChange={handleInputChange}
                      darmo={mode}
                    />
                    {invalidEmail &&
                      (email ? (
                        <span className="text-red-400 text-xs font-bold">
                          Invalid Email Address
                        </span>
                      ) : (
                        <span className="text-red-400 text-xs font-bold">
                          Email field is required
                        </span>
                      ))}
                    {emailNotRegistered && (
                      <span className="text-red-400 text-xs font-bold">
                        Email Not Registered
                      </span>
                    )}
                  </div>
                  <div className="mx-1">
                    <button
                      label={t("common.continue")}
                      loading={loading}
                      onClick={handleLogin}
                      type="submit"
                      className="flex w-full justify-center rounded-xl bg-primary-500 px-6 py-2 text-sm font-bold leading-6 text-white shadow-sm hover:bg-primary-400 transition-all hover:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Continue
                      {loading ? (
                        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>

                <div className="text-sm mx-auto text-center my-3 lg:mb-8">
                  <Link
                    className={`font-medium ${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500"
                        : "text-secondary hover:text-white"
                    }`}
                    to={"/"}
                  >
                    {t("login.return-signin")}
                  </Link>
                </div>

                <p
                  className={`text-center text-sm ${
                    mode ? "text-black" : "text-white"
                  } lg:mb-4`}
                >
                  Don't have an account?{"   "}
                  <Link
                    to={"/plans"}
                    className={`font-semibold leading-6  ${
                      mode
                        ? "text-indigo-600 hover:text-primary-500"
                        : "text-secondary hover:text-white"
                    }`}
                  >
                    Sign up
                  </Link>
                </p>
              </>
            ) : (
              <SuccessView resend={() => setCompleted(false)} />
            )}
          </div>
        </div>
      </AuthLayout>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
    </>
  );
};

export default ForgotPassword;
