import React, { useState } from "react";
import HButton from "../../components/atoms/HButton";
import User from "../../api/auth";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormInput from "../../components/atoms/FormInput";
import useFormatPhoneNumber from "../../hooks/FormatPhoneNumber";
import TableActionDropdown from "../../components/TableActionDropdown";
import { useSelector } from "react-redux";
import { selectSelectedLocation, selectUser } from "../../store/authSlice";
import { defaultUserImage } from "../../assets/images";
import Input from "../../components/form/Input";

const AccountView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resetPassword = async () => {
    if (currentPassword === "") {
      setValidationErrors({
        ...validationErrors,
        currentPassword: "Please Enter current password!",
      });
      return;
    }
    if (newPassword === "") {
      setValidationErrors({
        ...validationErrors,
        newPassword: "Please enter new password!",
      });
      return;
    }
    if (!checkConfirmPassword()) {
      return;
    }
    setLoading(true);
    setValidationErrors(null);
    const requestBody = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    User.ResetPassword(requestBody)
      .then(() => {
        toast.success("Password changed successfully");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 422) {
          if (
            error.response.data.errors.password &&
            error.response.data.errors.password[0]
          ) {
            setValidationErrors({
              ...validationErrors,
              newPassword: error.response.data.errors.password[0],
            });
          } else {
            setValidationErrors({
              ...validationErrors,
              currentPassword: "Current Password is Invalid!",
            });
          }
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const checkConfirmPassword = () => {
    if (confirmPassword === newPassword) {
      return true;
    } else {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "password do not match!",
      });
      return false;
    }
  };

  const roles = ["Admin", "Super Admin", "Business", "Job Seeker"];
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="mt-1">
          <Form
            //   onFinish={onSubmit}
            layout="vertical"
          >
            <Row gutter={[24, { xs: 16, md: 28 }]}>
              <Col span={24}>
                <div className="flex flex-row justify-between items-center">
                  <h5 className="font-bold">Personal Information</h5>
                  <div className="tbl-cell justify-end">
                    <TableActionDropdown
                      onClickEdit={() =>
                        navigate(`/business/manage-users/edit/${user.id}`)
                      }
                    />
                  </div>
                </div>
                <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="first_name"
                  label={t("common.first-name")}
                  placeholder={t("common.first-name")}
                  value={user?.first_name}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="last_name"
                  label={t("common.last-name")}
                  placeholder={t("common.last-name")}
                  value={user?.last_name}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="company"
                  label={"Company Name"}
                  placeholder={"Company Name"}
                  value={user?.company?.name}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="phone"
                  label={"Mobile Number"}
                  placeholder={"Mobile Number"}
                  value={useFormatPhoneNumber(user?.phone)}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="email"
                  label={t("common.email")}
                  placeholder={t("common.email")}
                  value={user?.email}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="timezone"
                  label={"Timezone"}
                  placeholder={"Central"}
                  disabled={true}
                  darmo={true}
                />
              </Col>
            </Row>
            {/*  */}
            <Row gutter={[24, { xs: 16, md: 28 }]}>
              <Col span={24}>
                <h5 className="font-bold mt-8">Company Information</h5>
                <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="business_phone"
                  label={"Business Contact Number"}
                  placeholder={"Business Phone"}
                  value={useFormatPhoneNumber(
                    user?.location?.business_phone ||
                      selectedLocation?.business_phone
                  )}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="address"
                  label={t("common.address")}
                  placeholder={t("common.address-example")}
                  value={selectedLocation?.address?.address}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="city"
                  label={t("common.city")}
                  placeholder={t("common.city")}
                  value={selectedLocation?.address?.city}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="state"
                  label={t("common.state")}
                  placeholder={t("common.state")}
                  value={selectedLocation?.address?.state}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="zip"
                  label={t("common.zip") + " Code"}
                  placeholder={t("common.zip")}
                  value={selectedLocation?.address?.zip}
                  disabled={true}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  name="timezone"
                  label={"Timezone"}
                  placeholder={"Central"}
                  disabled={true}
                  darmo={true}
                />
              </Col>
            </Row>
            {/*  */}
            <Row gutter={[24, { xs: 16, md: 28 }]}>
              <Col span={24}>
                <h5 className="font-bold mt-8">Reset Password</h5>
                <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  type="password"
                  name="current_password"
                  label={"Current Password"}
                  placeholder="*******"
                  value={currentPassword}
                  error={
                    validationErrors && validationErrors.currentPassword
                      ? validationErrors.currentPassword
                      : null
                  }
                  onChange={(e) => {
                    setValidationErrors({
                      ...validationErrors,
                      currentPassword: null,
                    });
                    setCurrentPassword(e.target.value);
                  }}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  type="password"
                  name="new_password"
                  label={"New Password"}
                  placeholder="*******"
                  value={newPassword}
                  error={
                    validationErrors && validationErrors.newPassword
                      ? validationErrors.newPassword
                      : null
                  }
                  onChange={(e) => {
                    setValidationErrors({
                      ...validationErrors,
                      newPassword: null,
                    });
                    setNewPassword(e.target.value);
                  }}
                  darmo={true}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Input
                  type="password"
                  name="c_new_password"
                  label={"Confirm New Password"}
                  placeholder="*******"
                  value={confirmPassword}
                  error={
                    validationErrors && validationErrors.confirmPassword
                      ? validationErrors.confirmPassword
                      : null
                  }
                  onChange={(e) => {
                    setValidationErrors({
                      ...validationErrors,
                      confirmPassword: null,
                    });
                    setConfirmPassword(e.target.value);
                  }}
                  darmo={true}
                />
              </Col>
            </Row>
            <div className="flex w-full mt-5 justify-center">
              <button
                className="cursor-pointer px-6 border mr-1 my-2 flex items-center justify-center bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50
                text-center text-sm font-bold whitespace-nowrap text-ellipsis rounded-xl py-2 disabled:bg-primary-500 disabled:opacity-50"
                onClick={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
                disabled={
                  loading ||
                  currentPassword === "" ||
                  newPassword === "" ||
                  confirmPassword === ""
                }
              >
                {t("general-settings.change-password")}
                {loading ? (
                  <i className="fad fa-circle-notch fa-spin ml-2" />
                ) : (
                  ""
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div className="mt-8 flex justify-center mb-8">
        <button
          className="cursor-pointer px-6 border mr-1 my-2 flex items-center justify-center bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50
                text-center text-sm font-bold whitespace-nowrap text-ellipsis rounded-md py-2"
          type="submit"
        >
          {t("common.save")}
        </button>
      </div>
    </div>
  );
};

export default AccountView;
