import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import { useTranslation } from "react-i18next";
import Certification from "../../../api/Certification";
import { toast } from "react-toastify";
import TableActionDropdown from "../../../components/TableActionDropdown";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useNavigate } from "react-router-dom";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../../../components/modals/DeleteModal";

const Certifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [certifications, setCertifications] = useState([]);
  const [certificationToDelete, setCertificationToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadCertifications = () => {
    setLoading(true);

    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch(() => {
        toast.error("Something went wrong.");
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    document.title = 'HeyHire Portal-Certifications';
    loadCertifications();
  }, []);

  useCustomEventListener("closeModal", () => {
    setCertificationToDelete(null);
  });

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">
          {t("certifications.certifications")}
        </h2>

        <Button
          className="mr-2.5"
          onClick={() => {
            navigate("add");
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>
      </div>

      <div className="overflow-x-auto overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 mt-3">
          <thead className="bg-white">
            <tr>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.name")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.category")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold justify-end">
                  {t("common.action")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              certifications.map((certification) => {
                return (
                  <tr key={certification.id} className="cursor-pointer">
                    <td>
                      <div className="tbl-cell">{certification.name}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">
                        {certification.category.name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell justify-end">
                        <TableActionDropdown
                          onClickEdit={null}
                          onClickDelete={() =>
                            setCertificationToDelete(certification.id)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {loading && <Loading />}
      </div>

      {certificationToDelete && (
        <DeleteModal
          title="Delete Certification"
          name="Certification"
          deleteRequest={() => Certification.deleteById(certificationToDelete)}
          onSuccess={() => {
            setCertificationToDelete(null);
            loadCertifications();
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default Certifications;
