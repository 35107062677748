import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "../components/organisms/Layout";
import { login, selectRoles, selectUser } from "../store/authSlice";
import { Col, Form, Row, Space } from "antd";
import ReactTags from "react-tag-autocomplete";
import TextArea from "../components/form/TextArea";
import FormSelect from "../components/atoms/FormSelect";
import dayjs from "dayjs";
import Certification from "../api/Certification";
import JobPosition from "../api/JobPosition";
import PredefinedJob from "../api/PredefinedJob";
import Input from "../components/form/Input";

const hiringStatusList = [
  { value: "0", label: "Accepting Applications" },
  { value: "1", label: "Immediately Hiring" },
];

const JobPositionRegister2 = () => {
  const { t } = useTranslation();
  const [prejobs, setPreJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  // const [startDateType, setStartDateType] = useState("date");
  const [hiringStatus, setHiringStatus] = useState("");
  const [title, setTitle] = useState("");
  const [availability, setAvailability] = useState("");
  const [experience, setExperience] = useState("");
  const [start_date, setStartDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [description, setDescription] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [selectJobPosition, setSelectJobPosition] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2);
    setMinDate(tomorrow);
    loadPredefinedJobs();
    loadCertifications();
  }, []);

  const setFields = (job) => {
    setTitle(job?.title);
    setStartDate(dayjs(job?.start_date).format("YYYY-MM-DD"));
    const options = [
      { value: "full-time", label: "Full Time" },
      { value: "part-time", label: "Part Time" },
    ];
    const availabilityValue = options?.find(
      (option) => option.value === job?.availability
    );
    setAvailability(availabilityValue);
    setDescription(job?.description);
    setExperience(job?.experience);
    if (job?.certifications) {
      setSelectedCertifications(job?.certifications);
    }
  };

  const loadCertifications = () => {
    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const addCertification = (certification) => {
    Certification.add({
      category_id: user?.location?.category_id,
      name: certification.name,
    }).then((response) => {
      setSelectedCertifications([
        ...selectedCertifications,
        response.data.data,
      ]);
    });
  };

  const removeCertification = (index) => {
    const copy = selectedCertifications.slice(0);
    copy.splice(index, 1);
    setSelectedCertifications(copy);
  };

  const CreateJob = async () => {
    try {
      setValidationErrors(null);
      const availabilityValue = availability ? availability?.value : null;

      const _data = {
        title: title,
        availability: availabilityValue,
        description: description,
        experience: experience,
        // start_date: start_date,
        hiring_status: hiringStatus,
        certifications: selectedCertifications
          .map((certification) => certification.id)
          .toString(),
        location_id: user?.location?.id,
      };

      const JP = await JobPosition.add(_data);

      toast.success("Job Position Created!");
      // onFinish();
      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setValidationErrors(error.response.data.errors);
      } else {
        console.error(error);
        toast.error("Api Error!");
      }
    }
  };

  const loadPredefinedJobs = async () => {
    PredefinedJob.getAll()
      .then((response) => {
        setPreJobs(response.data.data);
      })
      .catch((error) => {
        console.error("loadPredefinedJobs -> error", error);
      });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-1 flex-col justify-center h-full">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                <div className="flex flex-col h-full items-center sm:rounded-lg sm:px-12 lg:px-6">
                  <p className="text-xl font-bold">Post your first opening!</p>

                  <p className="text-sm mt-2 font-normal tracking-wide">
                    Not interested in posting your first opening now?{" "}
                    <span
                      className="sm:inline-block mt-2 sm:mt-0 flex justify-center font-bold text-primary-500 text-[13px] underline cursor-pointer"
                      onClick={() => navigate("/dashboard")}
                    >
                      Skip this Step
                      <i className="fas fa-arrow-right ml-2 my-auto"></i>
                    </span>
                  </p>

                  <Form layout="vertical" className="w-full">
                    <Space size={18} direction="vertical" className="w-full">
                      <div className="flex font-bold mt-6">
                        <Input
                          className="mr-2"
                          darmo={true}
                          type="checkbox"
                          defaultChecked={selectJobPosition}
                          value={selectJobPosition}
                          onChange={() =>
                            setSelectJobPosition(!selectJobPosition)
                          }
                        />
                        <span>Select Position from Pre-defined Jobs list.</span>
                      </div>
                      {selectJobPosition && prejobs?.length > 0 ? (
                        <FormSelect
                          label="Pre-Defined Jobs"
                          placeholder="Select from Pre-defined jobs"
                          value={selectedJob?.title}
                          options={prejobs?.map((job) => ({
                            value: job?.id,
                            label: job?.title,
                          }))}
                          onChange={(value) => {
                            const _job = prejobs?.filter(
                              (item) => item.title === value.label
                            );
                            setSelectedJob(_job[0]);
                            setFields(_job[0]);
                          }}
                          darmo={true}
                        />
                      ) : selectJobPosition && prejobs?.length === 0 ? (
                        <div className="font-bold text-[12px] text-gray-800">
                          Sorry! No pre-defined jobs found for this Category.
                        </div>
                      ) : null}
                      <Input
                        name="title"
                        label={t("pre-defined-jobs.job-title")}
                        type="text"
                        value={title}
                        placeholder={t("pre-defined-jobs.job-title")}
                        onChange={(e) => setTitle(e.target.value)}
                        error={
                          validationErrors && validationErrors.title
                            ? validationErrors.title[0]
                            : null
                        }
                        darmo={true}
                      />
                      <Row gutter={[15, 18]}>
                        <Col xs={24} md={12}>
                          <div>
                            {/* {startDateType === "date" ? ( */}
                            {/* <FormDateInput
                              name="start_date"
                              label={t("pre-defined-jobs.start-date")}
                              type="date"
                              min={minDate.toISOString().split("T")[0]}
                              value={start_date}
                              placeholder={t("pre-defined-jobs.start-date")}
                              error={
                                validationErrors && validationErrors.start_date
                                  ? validationErrors.start_date[0]
                                  : null
                              }
                              onChange={(e) => setStartDate(e.target.value)}
                              darmo={true}
                            /> 
                            ) : (*/}
                            <FormSelect
                              type={"add-job"}
                              label={t("pre-defined-jobs.hiring-status")}
                              name="hiring_status"
                              onChange={(value) => {
                                setHiringStatus(value.value);
                              }}
                              error={
                                validationErrors &&
                                validationErrors.hiring_status
                                  ? validationErrors.hiring_status[0]
                                  : null
                              }
                              options={hiringStatusList}
                              darmo={true}
                              defaultValue={hiringStatus}
                              // placeholder={hiringStatusList[parseInt(hiringStatus)-1]?.label}
                              placeholder={
                                hiringStatusList[
                                  hiringStatusList.findIndex(
                                    (obj) => obj.value === hiringStatus
                                  )
                                ]?.label
                              }
                            />

                            {/*)}
                             <span
                      className={
                        "mt-1 block text-indigo-600 hover:text-indigo-500 cursor-pointer"
                      }
                      onClick={() =>
                        setStartDateType(
                          startDateType === "date" ? "range" : "date"
                        )
                      }
                    >
                      Select{" "}
                      {startDateType === "date"
                        ? "hiring status"
                        : "specific date"}{" "}
                      instead
                    </span> */}
                          </div>
                        </Col>
                        <Col xs={24} md={12}>
                          <FormSelect
                            classNames="mb-2"
                            label="Availability"
                            placeholder="Select Availability"
                            options={[
                              { value: "full-time", label: "Full Time" },
                              { value: "part-time", label: "Part Time" },
                            ]}
                            value={availability}
                            onChange={(value) => setAvailability(value)}
                            error={
                              validationErrors && validationErrors.availability
                                ? validationErrors.availability[0]
                                : null
                            }
                            darmo={true}
                          />
                        </Col>
                      </Row>
                      <TextArea
                        name="description"
                        label="Description"
                        placeholder="Describe job position here."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        error={
                          validationErrors && validationErrors.description
                            ? validationErrors.description[0]
                            : null
                        }
                        darmo={true}
                      />
                      <TextArea
                        name="Requirements"
                        label="Requirements"
                        placeholder="Add Requirements for the job here."
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        darmo={true}
                      />
                      <div className="grid grid-cols-1 gap-4">
                        <div className="block">
                          <span
                            className={
                              "block mb-2 title font-bold text-warmgray-900"
                            }
                          >
                            Required Certificate
                          </span>
                          <ReactTags
                            tags={selectedCertifications}
                            suggestions={certifications}
                            onDelete={removeCertification}
                            onAddition={addCertification}
                            newTagText={"Add New Certification:"}
                            placeholderText={
                              "Search Certifications or add your own"
                            }
                            allowNew={true}
                            allowBackspace={false}
                            classNames={{
                              // root: `p-3 border-2 rounded-md border-gray-300 focus-within:border-primary-300 shadow-sm ${mode ? "bg-white" : "bg-slate-800"}`,
                              root: `p-3 border-2 rounded-md border-gray-300 focus-within:border-primary-300 shadow-sm bg-white`,
                              searchWrapper: "",
                              // searchInput: `focus:outline-0 ${mode ? "bg-white text-black" : "bg-slate-800 text-white"} custom-search-input`,
                              searchInput: `focus:outline-0 bg-white text-black custom-search-input`,
                              rootFocused: "focus:bg-blue-500",
                              suggestions:
                                "p-3 min-w-[250px] z-30 bg-white flex shadow-lg cursor-pointer",
                              suggestionActive: "bg-red-500",
                            }}
                            tagComponent={({
                              tag,
                              removeButtonText,
                              onDelete,
                            }) => {
                              return (
                                <button
                                  className={
                                    "bg-primary-400 hover:bg-primary-500 text-white text-sm rounded-full py-1 px-2 mr-1 mb-2"
                                  }
                                  onClick={onDelete}
                                >
                                  {tag.name}{" "}
                                  <i className={"fas fa-times-circle ml-2"} />
                                </button>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Space>
                  </Form>
                </div>
              </div>
              <div className="flex border-t-2 py-4 border-primary-500 justify-center">
                <div className="flex w-full justify-between">
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="button"
                    onClick={() => navigate("/add-sub-user")}
                  >
                    Previous
                  </button>
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="submit"
                    onClick={() => CreateJob()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default JobPositionRegister2;
