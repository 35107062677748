import React from "react";

const FormDateInput = ({
  label,
  name,
  className,
  onChange,
  type,
  value,
  placeholder,
  disabled,
  error,
  darmo,
  actionEnd,
  min,
  dateType,
}) => {
  return (
    <div className={className}>
      <label
        className={`block mb-1
         ${name === "shift_date" ? "font-medium" : "font-bold"} title ${darmo ? "text-gray-900" : "text-white"}`}
      >
        {label}
      </label>

      <div
        className={
          "flex flex-row w-full focus:outline-none focus-within:border-violet-400" +
          (error ? " border-red-500" : " border-gray-700") +
          (disabled ? " bg-gray-50" : "")
        }
      >
        <div
          className={`flex flex-row items-center w-full ${
            dateType === "add-job" ? "" : "py-1"
          }`}
        >
          <div className="relative w-full">
            <input
              className={`${
                darmo
                  ? "text-gray-900 placeholder:text-gray-400 focus:ring-indigo-600 bg-white"
                  : "text-white placeholder:text-gray-300 focus:ring-white bg-slate-800 calendar-dark"
              } block cursor-pointer w-full rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
              name={name}
              type={type}
              value={value}
              min={min}
              placeholder={placeholder}
              onChange={(event) => onChange(event)}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      {error ? (
        <span className="text-red-400 text-xs font-bold">{error}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default FormDateInput;
