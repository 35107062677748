
import CountUp from "react-countup";

export default function Stats({header = "header", data = []}) {
  return (
    <div>
      <h3 className="text-sm font-semibold leading-6 text-gray-900">
        {header}
      </h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {data.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow-lg sm:p-6"
          >
            <div className="float-right pl-10">
              {item.icon}
            </div>
            <dt className="truncate text-sm font-medium text-gray-500">
              <div>{item.name}</div>
            </dt>
            <dd
              className={`mt-1 text-3xl font-semibold tracking-tight ${item.color}`}
            >
              <CountUp start={0} end={item.count} />{" "}
              <div className="mr-5 text-[15px]">{item.subtext}</div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
