import { Collapse } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./DashboardTable.scss";
import { arrowDownWhiteIcon } from "../../../assets/images";

const { Panel } = Collapse;

const DashboardTable = ({
  title,
  titles = null,
  headers,
  children,
  clickAction = null,
  mode,
  collapse = false,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTAb] = useState(titles ? titles[0] : "Two");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const tableHeader = () => (
    <div
      className={
        "w-full flex justify-between pr-3 " +
        (!titles && "text-white bg-midnight rounded-2xl py-2 px-3")
      }
    >
      <div className="flex w-full">
        {titles ? (
          titles.map((t, i) => {
            return (
              <span
                key={i}
                className={
                  "p-3 px-4 mr-1 font-bold cursor-pointer" +
                  (t !== selectedTab && "bg-primary-500 text-white")
                }
                onClick={() => {
                  setSelectedTAb(t);
                  clickAction(t);
                }}
              >
                {t}
              </span>
            );
          })
        ) : (
          <span className="text-[15px] font-bold title self-center">
            {title}
          </span>
        )}
        {collapse && (
          <div className="ml-auto my-auto">
            <i
              alt="icon"
              className={`text-white w-4 h-4 cursor-pointer far ${
                isCollapsed ? "fa-chevron-down" : "fa-chevron-up"
              }`}
              onClick={handleCollapseToggle}
            />
          </div>
        )}
      </div>
    </div>
  );

  const tableContent = () => (
    <div
      className={`overflow-x-auto lg:h-[90%] min-h-[50px] ${
        mode ? "bg-white text-black" : "text-white bg-warmgray-900"
      }`}
    >
      <table className="min-w-full">
        <thead
          className={`${mode ? "bg-white" : "bg-white/10  text-gray-300"}`}
        >
          <tr className="flex">
            {headers.map((head, index) => {
              let className = "text-center";
              if (index === 0) {
                className = "text-left";
              } else if (index === headers.length - 1) {
                className = "text-right";
              }
              return (
                <th
                  scope="col"
                  key={head}
                  className={`flex-1 ${className} px-2 py-2 font-extrabold text-[14px] xl:px-4 ${
                    mode ? "text-black" : "text-white bg-warmgray-900"
                  }`}
                >
                  {head}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm md:text-sm font-medium divide-y-8 divide-white">
          {children}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      <div className="h-full">
        {tableHeader()}
        {!collapse || !isCollapsed ? tableContent() : null}
      </div>
      {/* <div className="block md:hidden">
        <Collapse
          expandIconPosition="right"
          className="dashboard-table-collapse"
        >
          <Panel header={tableHeader()} key="1">
            {tableContent()}
          </Panel>
        </Collapse>
      </div> */}
    </>
  );
};

export default DashboardTable;
