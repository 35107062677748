import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import DashboardStat from "../components/molecules/DashboardStat";
import DashboardTable from "../components/molecules/DashboardTable";
import "../components/molecules/DashboardTable/DashboardTable.scss";

import { DashboardLayout } from "../components/organisms/Layout";
import DashboardApi from "../api/Dashboard";
import Category from "../api/Category";
import User from "../api/User";
import useFormatPhoneNumber from "../hooks/FormatPhoneNumber";
import { useNavigate } from "react-router-dom";
import { DateFormat, encodeIds, formatPhone } from "../utils";
import { defaultUserImage } from "../assets/images";
import { darkmode } from "../store/authSlice";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { t } = useTranslation();
  const formatPhoneNumber = useFormatPhoneNumber;
  const navigate = useNavigate();

  const [recentLogins, setRecentLogins] = useState([]);
  const [recentLocations, setRecentLocations] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [recentSeekers, setRecentSeekers] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [categories, setCategories] = useState();
  const mode = useSelector(darkmode);

  const loadRecentLogins = async () => {
    const response = await DashboardApi.getRecentLogins();
    setRecentLogins(response.data.data);
  };

  const loadUsers = () => {
    User.getAll().then((response) => {
      setTotalUsers(response.data.data);
    });
  };

  const loadCategories = () => {
    Category.getAll().then((response) => setCategories(response.data.data));
  };

  const loadRecentLocations = async () => {
    const response = await DashboardApi.getRecentLocations();
    setRecentLocations(response.data.data);
  };

  const loadRecentSeekers = async () => {
    const response = await DashboardApi.getRecentSeekers();
    setRecentSeekers(response.data.data);
  };

  const loadRecentApplications = () => {
    DashboardApi.getRecentApplications().then((response) =>
      setRecentApplications(response.data.data)
    );
  };

  const getUserRole = (user) => {
    if (user?.roles && user?.roles?.length > 0) return user?.roles[0]?.name;
    else return "-";
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    loadRecentLogins();
    loadRecentLocations();
    loadRecentSeekers();
    loadRecentApplications();
    loadUsers();
    loadCategories();

    navigate("/admin/dashboard");
  }, [navigate]);

  return (
    <DashboardLayout darmo={true}>
      <div className="pb-8">
        <div>
          <h1
            className={`text-lg font-bold tracking-tight pb-5 text-[#2C3E50] mt-4 md:mt-2`}
          >
            {t("dashboard.your-dashboard")}{" "}
          </h1>
        </div>
        <Row gutter={[16, 24]}>
          {/* First row of tables */}
          <Col xs={24} lg={12}>
            <div className="lg:overflow-hidden h-full ">
              <DashboardTable
                title={t("dashboard.recent-logins")}
                headers={[
                  t("common.full-name"),
                  t("common.last-login"),
                  t("common.location"),
                ]}
                mode={true}
              >
                {recentLogins.slice(0, 5).map((recentLogin, i) => (
                  <tr key={i}>
                    <td colSpan="3" className="p-0">
                      <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                        <div className="flex flex-row">
                          <div className="flex-1 flex my-auto">
                            <img
                              className="rounded-full mr-2 my-auto"
                              width={24}
                              src={
                                recentLogin?.photo?.thumb_url ||
                                defaultUserImage
                              }
                              alt="Profile"
                            />
                            <span className="mt-0.5">{`${recentLogin?.first_name} ${recentLogin?.last_name}`}</span>
                          </div>
                          <div className="flex-1 text-center my-auto">
                            {recentLogin?.last_login ||
                              "This user has not logged in yet"}
                          </div>
                          <div className="flex-1 text-right my-auto">
                            {recentLogin?.location?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </DashboardTable>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="lg:overflow-hidden h-full">
              <DashboardTable
                title={t("dashboard.latest-business")}
                headers={[
                  t("common.company-name"),
                  t("common.category"),
                  t("common.owner"),
                ]}
                mode={true}
              >
                {recentLocations.slice(0, 5).map((recentLocation, i) => {
                  const _category = categories?.find(
                    (c) => c?.id === recentLocation?.category_id
                  );
                  return (
                    <tr key={i}>
                      <td colSpan="3" className="p-0">
                        <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                          <div className="flex flex-row">
                            <div className="flex-1 flex my-auto">
                              {recentLocation?.company?.name}
                            </div>
                            <div className="flex-1 text-center my-auto">
                              {_category?.name}
                            </div>
                            <div className="flex-1 text-right my-auto">
                              {recentLocation?.user.length > 0
                                ? `${recentLocation?.user[0]["first_name"]} ${recentLocation?.user[0]["last_name"]}`
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </DashboardTable>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 24]} className="mt-4">
          {/* Second row of tables */}
          <Col xs={24} lg={12}>
            <div className="lg:overflow-hidden h-full">
              <DashboardTable
                title={t("dashboard.latest-job-seekers")}
                headers={[
                  t("common.full-name"),
                  t("common.phone"),
                  t("common.city"),
                  t("common.state"),
                ]}
                mode={true}
              >
                {recentSeekers.slice(0, 5).map((recentSeeker, i) => (
                  <tr key={i}>
                    <td colSpan="4" className="p-0">
                      <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                        <div className="flex flex-row">
                          <div className="flex-1 flex my-auto">
                            <img
                              className="rounded-full mr-2 my-auto"
                              width={24}
                              src={
                                recentSeeker?.photo?.thumb_url ||
                                defaultUserImage
                              }
                              alt="Profile"
                            />
                            {recentSeeker?.first_name} {recentSeeker?.last_name}
                          </div>
                          <div className="flex-1 text-center my-auto">
                            {formatPhone(recentSeeker?.phone)}
                          </div>
                          <div className="flex-1 text-center my-auto">
                            {recentSeeker?.city}
                          </div>
                          <div className="flex-1 text-right my-auto">
                            {recentSeeker?.state}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {recentSeekers.length === 0 && (
                  <tr>
                    <td
                      className="px-3 py-3 whitespace-nowrap text-center"
                      colSpan={12}
                    >
                      <p>No Data Available</p>
                    </td>
                  </tr>
                )}
              </DashboardTable>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="lg:overflow-hidden h-full">
              <DashboardTable
                title={t("dashboard.latest-application-submissions")}
                headers={
                  recentApplications?.length > 0
                    ? [
                        t("common.applicants-name"),
                        t("common.applied-for"),
                        t("common.location"),
                      ]
                    : ["No Applicants Yet"]
                }
                mode={true}
              >
                {recentApplications &&
                  recentApplications.slice(0, 5).map((recentApplication, i) => (
                    <tr
                      key={i}
                      className={`text-black hover:text-primary-400 hover:cursor-pointer`}
                      onClick={() =>
                        navigate(
                          `/business/applicant/${encodeIds(
                            recentApplication.job_id
                          )}/${encodeIds(recentApplication.job_seeker_id)}`
                        )
                      }
                    >
                      <td colSpan="3" className="p-0">
                        <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                          <div className="flex flex-row">
                            <div className="flex-1 flex my-auto">
                              <img
                                className="rounded-full mr-2 my-auto"
                                width={24}
                                src={
                                  recentApplication?.seeker?.photo?.thumb_url ||
                                  defaultUserImage
                                }
                                alt="Profile"
                              />
                              <span className="mt-0.5">
                                {recentApplication?.seeker?.first_name}{" "}
                                {recentApplication?.seeker?.last_name}
                              </span>
                            </div>
                            <div className="flex-1 text-center my-auto">
                              {recentApplication?.position?.title}
                            </div>
                            <div className="flex-1 text-right my-auto">
                              {recentApplication?.location?.name}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </DashboardTable>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
