import { Form } from "antd";
import "./FormPhoneInput.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from "react";

const FormPhoneInput = ({
  id,
  name,
  label,
  type = "text",
  placeholder,
  useForm = true,
  darmo,
  ...rest
}) => {
  return (
    <>
      <label
        className={`title text-md font-medium ${
          darmo ? "text-warmgray-900" : "text-white"
        }`}
      >
        {label}
      </label>
      <Form.Item id={id} name={name} noStyle shouldUpdate>
        <PhoneInput
          defaultCountry="us"
          country="us"
          national
          countryCallingCodeEditable={false}
          id={id}
          name={name}
          {...rest}
          className={`border-gray-100 hover:border-primary-500 rounded-md shadow-sm focus:shadow-none sm:text-sm ${
            darmo ? "bg-white" : "bg-slate-800 text-white"
          }`}
          placeholder={placeholder}
        />
      </Form.Item>
    </>
  );
};

export default FormPhoneInput;
