import { useEffect, useState, useRef } from "react";
import {
  XMarkIcon,
  MinusIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  visiblePersonsArray,
  minimisedPersonsArray,
  setVisiblePersons,
  setMinimizedPersons,
  setSelectedPersons,
  preCannedMessages,
  selectedPersons,
  selectedConversation,
  setNewMessage,
  selectUser,
  selectSelectedLocation,
} from "../../../store/authSlice";
import { defaultUserImage, defaultUserImage2 } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { encodeIds } from "../../../utils";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import BlinkingEffect from "./BlinkingEffect";
import ProfileRing from "./ProfileRing";
import DropdownMenuItem from "../../DropdownMenuItem";
import { MdSend } from "react-icons/md";
import notificationSound from "../../../assets/audio/notifications-sound.mp3";

export default function ChatWindow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const visiblePersons = useSelector(visiblePersonsArray);
  const minimizedPersons = useSelector(minimisedPersonsArray);
  const [inputMessages, setInputMessages] = useState({});
  const chatContainerRefs = useRef({});
  const windowWidth = window.innerWidth;
  const [messages, setMessages] = useState([]);
  const [loadingConversations, setLoadingConversations] = useState({});
  const [emojiPickerStatus, setEmojiPickerStatus] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const precannedMessages = useSelector(preCannedMessages);
  const [activePerson, setActivePerson] = useState(null);
  const [activePersonPrecanned, setActivePersonPrecanned] = useState(null);
  const selectedPerson = useSelector(selectedPersons);
  const [latestMessageInfo, setLatestMessageInfo] = useState(null);
  const emojiPickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const conversation = useSelector(selectedConversation);
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setEmojiPickerStatus(false);
        setActivePerson(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
        setActivePersonPrecanned(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (Object.keys(conversation).length > 0) {
      if (conversation[selectedPerson[selectedPerson?.length - 1]?.id]) {
        loadMessages(
          conversation[selectedPerson[selectedPerson?.length - 1]?.id]
            ?.conversations
        );
      }
      Object.values(conversation).map((conversationobj) => {
        let newConversation = conversationobj?.conversations;
        newConversation?.on("messageAdded", (m) => {
          setLatestMessageInfo({ message: m, conversation: newConversation });
        });
      });
    }
  }, [conversation]);

  const loadMessages = async (thisConversation) => {
    let existingMessages = messages;
    const currentPerson = selectedPerson?.find(
      (person) => person?.conversation_sid === thisConversation?.sid
    );
    try {
      if (loadingConversations[currentPerson.id]) {
        return;
      }

      setLoadingConversations((prevLoadingConversations) => ({
        ...prevLoadingConversations,
        [currentPerson?.id]: true,
      }));

      const messagePaginator = await thisConversation?.getMessages();
      if (
        existingMessages.findIndex(
          (message) => message?.id === currentPerson?.conversation_sid
        ) < 0
      ) {
        existingMessages = [
          ...existingMessages,
          {
            id: currentPerson?.conversation_sid,
            messages: messagePaginator?.items,
          },
        ];
      } else {
        const messageNo = existingMessages?.findIndex(
          (chat) => chat?.id === currentPerson?.conversation_sid
        );
        existingMessages[messageNo].messages = messagePaginator?.items;
      }
      scrollToBottom(currentPerson?.id);
    } catch (error) {
      toast.error("Couldn't fetch messages.");
    } finally {
      setLoadingConversations((prevLoadingConversations) => ({
        ...prevLoadingConversations,
        [currentPerson?.id]: false,
      }));
    }
    setMessages([...existingMessages]);
  };

  useEffect(() => {
    if (latestMessageInfo) {
      handleBlinkingEffect(
        latestMessageInfo?.message,
        latestMessageInfo?.conversation
      );
    }
  }, [latestMessageInfo]);

  const handleBlinkingEffect = (m, newConversation) => {
    if (m?.author[0] === "+") {
      const updatedVisiblePersons = visiblePersons?.map((person) => {
        if (person?.conversation_sid === newConversation?.sid) {
          return {
            ...person,
            hasNewMessage: true,
          };
        }
        return person;
      });
      dispatch(setVisiblePersons(updatedVisiblePersons));

      const updatedMinimizedPersons = minimizedPersons?.map((person) => {
        if (person?.conversation_sid === newConversation?.sid) {
          return {
            ...person,
            hasNewMessage: true,
          };
        }
        return person;
      });
      dispatch(setMinimizedPersons(updatedMinimizedPersons));

      const audio = new Audio(notificationSound);
      audio.autoplay = true;

      loadMessages(newConversation);
    }
  };

  const scrollToBottom = (conversationId, delay = 0) => {
    setTimeout(() => {
      const chatContainerRef = chatContainerRefs.current[conversationId];
      if (chatContainerRef) {
        chatContainerRef.scrollTop = chatContainerRef.scrollHeight;
      }
    }, delay);
  };

  const formatTimestamp = (timestamp) => {
    const formattedDate = dayjs(timestamp).format("MMMM D, YYYY [at] h:mm A");
    return formattedDate;
  };

  const handleSendMessage = async (person) => {
    if (inputMessages[person.id]?.trim() === "") {
      return;
    }

    const currentConversation = conversation[person.id]?.conversations;
    const msg = await currentConversation?.getMessages();

    let messageToSend = inputMessages[person.id];

    if (msg?.items?.length === 0) {
      const introMessage = `HeyHire Intro Message: ${
        (selectedLocation?.name !== "Select A Location" &&
          selectedLocation?.name) ||
        user?.company?.name
      } wants to chat with you. Message:`;

      messageToSend = `${introMessage} ${messageToSend}\n— Reply directly to this message to continue the conversation. Standard data rates may apply.`;
    }

    await currentConversation?.sendMessage(messageToSend);

    setInputMessages({ ...inputMessages, [person.id]: "" });
    loadMessages(currentConversation);
    scrollToBottom(person.id);
    dispatch(setNewMessage(true));

    document.getElementById(`textarea-${person.id}`).style.height = "";
  };

  const handleDeleteVisiblePerson = (person, event) => {
    event.stopPropagation();
    const updatedVisiblePersons = visiblePersons.filter(
      (p) => p.id !== person.id
    );
    dispatch(setVisiblePersons(updatedVisiblePersons));

    const updatedSelectedPersons = selectedPerson.filter(
      (p) => p.id !== person.id
    );
    dispatch(setSelectedPersons(updatedSelectedPersons));
  };

  const handleDeleteMinimizedPerson = (person, event) => {
    event.stopPropagation();
    const updatedMinimizedPersons = minimizedPersons.filter(
      (p) => p.id !== person.id
    );
    dispatch(setMinimizedPersons(updatedMinimizedPersons));

    const updatedSelectedPersons = selectedPerson.filter(
      (p) => p.id !== person.id
    );
    dispatch(setSelectedPersons(updatedSelectedPersons));
  };

  const handleMinimizePerson = (person) => {
    const updatedPerson = { ...person, minimize: true };

    const updatedMinimizedPersons = [...minimizedPersons, updatedPerson];
    const updatedVisiblePersons = visiblePersons.filter(
      (p) => p.id !== person.id
    );

    if (updatedMinimizedPersons.length > 5) {
      updatedMinimizedPersons.shift();
    }

    dispatch(setVisiblePersons(updatedVisiblePersons));
    dispatch(setMinimizedPersons(updatedMinimizedPersons));
  };

  const handleMaximizePerson = (person) => {
    const updatedPerson = { ...person, minimize: false };

    const updatedMinimizedPersons = minimizedPersons.filter(
      (p) => p.id !== person.id
    );
    const updatedVisiblePersons = [...visiblePersons, updatedPerson];

    if (windowWidth >= 1450) {
      if (updatedVisiblePersons.length > 3) {
        const removedPerson = updatedVisiblePersons.shift();
        updatedMinimizedPersons.push({ ...removedPerson, minimize: true });
      }
    } else if (windowWidth >= 1024) {
      if (updatedVisiblePersons.length > 2) {
        const removedPerson = updatedVisiblePersons.shift();
        updatedMinimizedPersons.push({ ...removedPerson, minimize: true });
      }
    } else {
      if (updatedVisiblePersons.length > 1) {
        const removedPerson = updatedVisiblePersons.shift();
        updatedMinimizedPersons.push({ ...removedPerson, minimize: true });
      }
    }

    dispatch(setVisiblePersons(updatedVisiblePersons));
    dispatch(setMinimizedPersons(updatedMinimizedPersons));
    scrollToBottom(person.id);
  };

  useEffect(() => {
    if (selectedPerson && selectedPerson.length > 0) {
      let updatedVisiblePersons = [];
      let updatedMinimizedPersons = [];

      for (const person of selectedPerson) {
        if (person?.minimize) {
          if (!updatedMinimizedPersons.some((p) => p?.id === person?.id)) {
            updatedMinimizedPersons.push(person);
          }
          updatedVisiblePersons = updatedVisiblePersons.filter(
            (p) => p?.id !== person?.id
          );
        } else {
          if (!updatedVisiblePersons.some((p) => p?.id === person?.id)) {
            updatedVisiblePersons.push({ ...person, minimize: false });
          }
          updatedMinimizedPersons = updatedMinimizedPersons.filter(
            (p) => p?.id !== person?.id
          );
        }
      }

      if (windowWidth >= 1450) {
        while (updatedVisiblePersons.length > 3) {
          const personToMinimize = {
            ...updatedVisiblePersons.shift(),
            minimize: true,
          };
          updatedMinimizedPersons.push(personToMinimize);
        }
      } else if (windowWidth >= 1024) {
        while (updatedVisiblePersons.length > 2) {
          const personToMinimize = {
            ...updatedVisiblePersons.shift(),
            minimize: true,
          };
          updatedMinimizedPersons.push(personToMinimize);
        }
      } else {
        while (updatedVisiblePersons.length > 1) {
          const personToMinimize = {
            ...updatedVisiblePersons.shift(),
            minimize: true,
          };
          updatedMinimizedPersons.push(personToMinimize);
        }
      }

      if (updatedMinimizedPersons.length > 5) {
        updatedMinimizedPersons.shift();
      }

      dispatch(setVisiblePersons(updatedVisiblePersons));
      dispatch(setMinimizedPersons(updatedMinimizedPersons));
    }
  }, [selectedPerson]);

  const navigateProfile = (person) => {
    navigate(
      `/business/applicant/${encodeIds(person?.position?.id)}/${encodeIds(
        person?.seeker?.id
      )}`
    );
  };

  const captureEmoji = (e, id) => {
    const currentText = inputMessages[id] || "";
    const emojiToAdd = e.native || "";

    setInputMessages({
      ...inputMessages,
      [id]: currentText + emojiToAdd,
    });
  };

  const activateEmojiPicker = (personId) => {
    setActivePerson(personId);
    setEmojiPickerStatus(!emojiPickerStatus);
  };

  const handleChange = (event, person) => {
    setInputMessages({
      ...inputMessages,
      [person.id]: event.target.value,
    });

    const textarea = document.getElementById(`textarea-${person.id}`);

    if ((event.target.value || "").trim() !== "") {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.height = "";
    }
  };

  const handleKeyDown = (event, person) => {
    const enterKeyCode = 13;

    if (event.keyCode === enterKeyCode && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(person);
    }
  };

  const handlePrecanned = (personId, message) => {
    setInputMessages({
      ...inputMessages,
      [personId]: message,
    });

    setTimeout(() => {
      const textarea = document.getElementById(`textarea-${personId}`);
      if (textarea) {
        textarea.style.height = "";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }, 0);
  };

  const activatePrecannedPicker = (personId) => {
    setActivePersonPrecanned(personId);
    setDropdownVisible(!dropdownVisible);
  };

  const formatAvailability = (availability) => {
    if (!availability) {
      return "";
    }

    return (
      availability.charAt(0).toUpperCase() +
      availability.slice(1).replace("-", " ")
    );
  };

  return (
    <div className="fixed bottom-0 right-16 md:right-16 lg:right-20 xl:right-20 z-10">
      <div
        className="flex flex-wrap gap-4 md:gap-4 lg:gap-5 xl:gap-6"
        style={{
          direction: "rtl",
        }}
      >
        {visiblePersons?.map((person, index) => (
          <div
            className="shadow-xl bg-white rounded-md w-full md:w-[23rem] h-[28rem] flex items-start justify-start flex-col chat-window-mobile"
            key={index}
          >
            {person?.id ? (
              <BlinkingEffect
                key={index}
                isActive={person.hasNewMessage}
                person={person}
              >
                <div className="flex items-start w-full flex-row-reverse p-2">
                  <div className="flex flex-row-reverse">
                    <span className="relative inline-block flex-shrink-0">
                      <img
                        className="h-9 w-9 rounded-full cursor-pointer"
                        src={
                          person?.seeker?.photo?.original_url ||
                          defaultUserImage2
                        }
                        onClick={() => navigateProfile(person)}
                        alt=""
                      />
                    </span>
                    <div
                      className="ml-2 my-auto flex flex-col"
                      style={{
                        direction: "ltr",
                      }}
                    >
                      <p
                        className="text-sm font-medium text-white cursor-pointer overflow-hidden w-20 md:w-52 truncate"
                        onClick={() => navigateProfile(person)}
                      >
                        {person?.seeker?.first_name +
                          " " +
                          person?.seeker?.last_name}
                      </p>
                      <p className="text-xs text-gray-100 font-bold mr-auto">
                        <a
                          href="#"
                          className="text-gray-100 hover:text-gray-100 underline inline-flex"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/business/view-job/${encodeIds(
                                person?.position?.id
                              )}`
                            );
                          }}
                        >
                          <span className="overflow-hidden whitespace-nowrap block text-ellipsis max-w-[100px] md:max-w-[160px]">
                            {person?.position?.title}
                          </span>
                          <span className="whitespace-nowrap">
                            {" - " +
                              formatAvailability(
                                person?.position?.availability
                              )}
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="flex h-7 items-center flex-row-reverse ml-auto my-auto">
                    <button
                      type="button"
                      className="relative rounded-md text-white hover:text-gray-500 mr-2"
                      onClick={() => handleMinimizePerson(person)}
                    >
                      <MinusIcon className="h-5 w-5" />
                    </button>
                    <button
                      type="button"
                      className="relative rounded-md text-white  hover:text-gray-500"
                      onClick={(event) =>
                        handleDeleteVisiblePerson(person, event)
                      }
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </BlinkingEffect>
            ) : (
              <div class="border border-blue-300 shadow rounded-md p-2 w-full mx-auto">
                <div className="flex items-start w-full flex-row-reverse">
                  <div className="flex flex-row-reverse w-full animate-pulse">
                    <div class="rounded-full bg-slate-200 h-9 w-9 mr-3"></div>
                    <div class="flex-1 space-y-3 py-1">
                      <div class="h-2 bg-slate-200 rounded w-full flex-1"></div>
                      <div class="space-y-3">
                        <div class="grid grid-cols-3 gap-4">
                          <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                          <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              ref={(ref) => (chatContainerRefs.current[person.id] = ref)}
              className="p-3 h-[87%] overflow-auto w-full"
              style={{ direction: "ltr" }}
            >
              {person?.id ? (
                <>
                  {loadingConversations[person.id] ? (
                    <div className="text-center">
                      <i className="fas fa-spinner fa-spin text-primary-500" />
                    </div>
                  ) : (
                    messages
                      .find((message) => message.id === person.conversation_sid)
                      ?.messages?.map((message, msgIndex) => (
                        <div key={msgIndex}>
                          {message?.author[0] !== "+" ? (
                            <div className={`text-right mb-2`}>
                              <div
                                className={`text-black bg-primary-100 inline-block p-2 rounded-md max-w-[80%] text-left`}
                                data-tooltip-id={`my-tooltip-${msgIndex}`}
                                data-tooltip-content={formatTimestamp(
                                  message.dateUpdated
                                )}
                                data-tooltip-place="left"
                              >
                                {message.body}
                              </div>
                            </div>
                          ) : (
                            <div className={`text-left mb-2`}>
                              <div className="flex items-center">
                                <span className="relative inline-block flex-shrink-0">
                                  <img
                                    className="h-6 w-6 rounded-full mr-2"
                                    src={
                                      person?.seeker?.photo?.original_url ||
                                      defaultUserImage
                                    }
                                    alt=""
                                  />
                                </span>
                                <div
                                  className={`text-gray-900 bg-gray-300 inline-block p-2 rounded-md max-w-[80%]`}
                                  data-tooltip-id={`my-tooltip-${msgIndex}`}
                                  data-tooltip-content={formatTimestamp(
                                    message.dateUpdated
                                  )}
                                  data-tooltip-place="left"
                                >
                                  {message.body}
                                </div>
                              </div>
                            </div>
                          )}

                          <Tooltip id={`my-tooltip-${msgIndex}`} />
                        </div>
                      ))
                  )}
                </>
              ) : (
                <div className="flex flex-col items-center justify-center text-center">
                  <p className="my-3 text-gray-400">
                    Creating conversation, please wait...
                  </p>
                  <i className="fad fa-circle-notch fa-spin text-lg ml-2" />
                </div>
              )}
            </div>
            {person?.id && (
              <div
                className="flex flex-row-reverse items-end mt-auto w-full border-t-2 border-gray-700 h-[13%] p-2"
                style={{ direction: "ltr" }}
              >
                <button
                  type="button"
                  className="px-3 rounded-r-md bg-primary-500 text-white h-10"
                  onClick={() => handleSendMessage(person)}
                >
                  <MdSend size={18} className="text-white" />
                </button>

                <div className="flex flex-row-reverse items-end w-full">
                  <div className="relative w-full top-[6px]">
                    <textarea
                      id={`textarea-${person.id}`}
                      placeholder="Write a message..."
                      className="w-full h-10 rounded-l-md pl-3 resize-none overflow-hidden text-md z-10 overflow-y-auto border-gray-300 focus:border-transparent pr-14"
                      rows={1}
                      value={inputMessages[person.id] || ""}
                      onChange={(e) => handleChange(e, person)}
                      onKeyDown={(e) => handleKeyDown(e, person)}
                      style={{
                        maxHeight: "calc(2rem * 4)",
                      }}
                    />

                    <div className="absolute flex flex-row items-center space-x-2 right-2 bottom-3">
                      <FaceSmileIcon
                        id={`facesmile-icon-${person.id}`}
                        onClick={() => activateEmojiPicker(person.id)}
                        className="h-6 w-6 text-gray-400 cursor-pointer hover:text-green-500 hover:font-bold"
                        aria-hidden="true"
                      />
                      <div
                        id={`precan-icon-${person.id}`}
                        className="my-auto cursor-pointer"
                        onClick={() => activatePrecannedPicker(person.id)}
                        data-tooltip-id={`precan-tooltip-${person.id}`}
                        data-tooltip-content="Precanned Messages"
                        data-tooltip-place="top"
                      >
                        <i className="fas fa-chevron-down text-gray-400 hover:text-green-500 mr-1" />
                      </div>
                    </div>

                    {dropdownVisible &&
                      activePersonPrecanned === person.id &&
                      precannedMessages &&
                      precannedMessages.length > 0 && (
                        <div
                          className="absolute py-1 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] overflow-auto border-2 shadow-md z-20 bg-white"
                          style={{ bottom: "40px", right: "20px" }}
                          ref={dropdownRef}
                        >
                          {precannedMessages?.map((message, index) => (
                            <div
                              className="font-bold text-sm"
                              key={message?.id}
                            >
                              <DropdownMenuItem
                                label={message?.shortcut}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePrecanned(person?.id, message?.message);
                                  setDropdownVisible(false);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                    {emojiPickerStatus && activePerson === person.id && (
                      <div
                        id="emojiPicker"
                        className="absolute z-20"
                        style={{ bottom: "40px" }}
                        ref={emojiPickerRef}
                      >
                        <Picker
                          previewPosition="none"
                          data={data}
                          onEmojiSelect={(emoji) =>
                            captureEmoji(emoji, person.id)
                          }
                          emojiSize={17}
                          perLine={7}
                          theme="light"
                        />
                      </div>
                    )}

                    <Tooltip id={`precan-tooltip-${person.id}`} />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        <div
          className="fixed bottom-2 md:bottom-2 lg:bottom-4 right-2 md:right-2 lg:right-4"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "flex-end",
          }}
        >
          {minimizedPersons.map((person, index) => (
            <div
              key={index}
              className="relative inline-block flex-shrink-0 mt-2 cursor-pointer"
            >
              <ProfileRing
                key={index}
                isActive={person.hasNewMessage}
                person={person}
              >
                <img
                  className="h-12 w-12 rounded-full"
                  src={person?.seeker?.photo?.original_url || defaultUserImage}
                  onClick={() => handleMaximizePerson(person)}
                  alt=""
                />
              </ProfileRing>

              <XMarkIcon
                className="absolute top-0 right-0 h-4 w-4 cursor-pointer bg-white rounded-full shadow-lg border border-gray-600"
                onClick={(event) => handleDeleteMinimizedPerson(person, event)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
