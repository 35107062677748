import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useSelector } from "react-redux";

import { selectRoles } from "../../store/authSlice";
import AddLocationModal from "../../components/molecules/FormLocation";
import TableActionDropdown from "../../components/TableActionDropdown";
import LocationApi from "../../api/Location";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import DeleteModal from "../../components/modals/DeleteModal";
import Region from "../../api/Region";
import PaginationView from "../../components/molecules/Pagination";
import BreadCrumbs from "../../components/organisms/BreadCrumb";
import { encodeIds } from "../../utils";
import SearchBar from "../../components/atoms/SearchBar";
import useQuery from "../../hooks/useQuery";

const Locations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const roles = useSelector(selectRoles);

  const [showModal, setShowModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchLocation, setSearchLocation] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 50);
  const isSuperUser = false;

  useEffect(() => {
    document.title = "HeyHire Portal-Locations";
    loadLocations();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setLocationToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadLocations();
  });

  const searchLocationName = (txt) => {
    setSearchLocation(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredLocations(locations);
    } else {
      let _locations = locations.filter((j) =>
        j.name.toLowerCase().includes(text)
      );
      setFilteredLocations(_locations);
    }
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const addLocation = (values) => {
    setLoading(true);

    Location.add(values)
      .then(() => {
        toast.success(t("locations.location-added"));
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadLocations = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const limit = query.get("limit") || pageLimit;
    const pageData = page ? `page=${page}` : "";
    const queryData = pageData + `&limit=${limit}`;

    LocationApi.getAll(queryData)
      .then((response) => {
        setLocations(response.data.data);
        setFilteredLocations(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const onLocationDeleted = () => {
    setLocationToDelete(null);
    loadLocations();
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between mt-6">
        <h2 className="font-bold text-2xl">{t("locations.locations")}</h2>

        {roles[0] === "super-user" && (
          <button
            className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
            onClick={() => {
              navigate("add");
            }}
          >
            <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
          </button>
        )}
      </div>
      <SearchBar
        searchValue={searchLocation}
        placeholder="Search Location By Name"
        searchFunction={(value) => searchLocationName(value)}
      />
      <div className="overflow-x-auto h-full mt-3">
        <table className="min-w-full divide-y divide-gray-200 whitespace-nowrap">
          <thead className="bg-gray-900 text-white">
            <tr>
              <th scope="col" className="p-2 text-left font-bold">
                <div className="tbl-cell">Name</div>
              </th>
              {isSuperUser ? (
                <th scope="col" className="text-left font-bold">
                  <div className="tbl-cell mr-2">Company Name</div>
                </th>
              ) : (
                <th scope="col" className="text-left font-bold">
                  <div className="tbl-cell mr-2">Address</div>
                </th>
              )}
              <th scope="col" className="text-left font-bold">
                <div className="tbl-cell mr-2">Category</div>
              </th>
              {/* <th scope="col" className="font-bold">
                <div className="tbl-cell">Owner</div>
              </th> */}
              <th scope="col" className="text-left font-bold">
                <div className="tbl-cell">Region</div>
              </th>
              <th scope="col" className="text-center font-bold">
                <div className="tbl-cell mr-2">{t("common.action")}</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              filteredLocations.map((location) => {
                return (
                  <tr
                    key={location.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td
                    // onClick={() => {
                    //   navigate(`/companies/locations/${location.id}/edit`)
                    // }}
                    >
                      <div className="tbl-cell p-2">{location.name}</div>
                    </td>
                    <td>
                      <div className="tbl-cell mr-2">
                        {isSuperUser
                          ? location?.company?.name
                          : location?.address?.address}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell mr-2">
                        {location.category && location.category.name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell mr-2">
                        {location.region && location.region.name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell text-center mr-2">
                        <TableActionDropdown
                          onClickEdit={() =>
                            navigate(
                              `/companies/locations/${encodeIds(
                                location.id
                              )}/edit`
                            )
                          }
                          onClickDelete={() => {
                            setLocationToDelete(location.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {loading && <Loading />}
      <div className="flex w-full h-20 mt-2 justify-end items-center">
        {!loading && (
          <PaginationView
            current={metaData?.current_page}
            next={metaData?.to}
            pageSize={metaData?.per_page}
            total={metaData?.total}
            onChange={handlecPageChange}
          />
        )}
      </div>

      <FormModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title={t("locations.add-a-location")}
        subTitle="Vivamus venenatis eros sit amet lectus gravida, volutpat dictum ex pellentesque. "
        // onSubmit={addLocation}
      >
        <AddLocationModal />
      </FormModal>

      {locationToDelete && (
        <DeleteModal
          title="Delete Location"
          name="Location"
          deleteRequest={() => LocationApi.delete(locationToDelete)}
          onSuccess={onLocationDeleted}
        />
      )}
    </DashboardLayout>
  );
};

export default Locations;
