import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Switch } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import "./AdminNavBar.scss";
import HeyHireLogo from "../../../assets/images/hey-hire-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setBackgroundcolor, darkmode } from "../../../store/authSlice";
import {
  logout,
  setSelectedLocation,
  selectPermissions,
  selectRoles,
  selectSelectedLocation,
  selectUser,
} from "../../../store/authSlice";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Location from "../../../api/Location";
import User from "../../../api/User";
import { Dropdown } from "antd";
import DropdownMenuItem from "../../DropdownMenuItem";
import { defaultUserImage, heyhireIcon } from "../../../assets/images";
import CustomNotification from "../../../utils/CustomNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminNavBar({ isEnterprise = true }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [displayAddress, setDisplayAddress] = useState(true);
  const [active, setActive] = useState(false);
  const isSuper = !pathname.includes("/business");
  const domainFLag = process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const [locations, setLocations] = useState([]);
  const showAddress = pathname === "/business/dashboard";
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const location = useLocation();

  const HandleBg = () => {
    dispatch(setBackgroundcolor(!mode));
  };

  const superUserItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Businesses",
      icon: "far fa-briefcase",
      permissions: ["company.view"],
      subItems: [
        {
          label: t("sidebar.companies"),
          link: "/companies",
          permissions: ["company.view"],
        },
        {
          label: t("sidebar.regions"),
          link: "/companies/regions",
          permissions: ["region.view"],
        },
        {
          label: t("sidebar.locations"),
          link: "/companies/locations",
          permissions: ["location.view"],
        },
        {
          label: t("sidebar.brands"),
          link: "/companies/brands",
          permissions: ["brand.view"],
        },
        { label: t("sidebar.address-book"), link: "/admin/address-book" },
        {
          label: t("sidebar.Settings"),
          link: "/settings",
          permissions: ["company.view"],
        },
      ],
    },
    {
      label: "Billing",
      icon: "far fa-money-check",
      permissions: ["job_seeker.view"],
      subItems: [
        { label: "Activity", link: "/payments/activity" },
        { label: "Discounts", link: "/payments/discounts" },
        { label: "Free Accounts", link: "/payments/free-accounts" },
        { label: "Trial Accounts", link: "/payments/trial-accounts" },
      ],
    },
    {
      label: "Users",
      icon: "far fa-user",
      subItems: [
        {
          label: t("sidebar.manage-business-users"),
          link: "/admin/manage-business-users",
        },
        {
          label: t("sidebar.job-seekers"),
          link: "/job-seekers",
          permissions: ["job_seeker.view"],
        },
      ],
    },
    {
      label: "Miscellaneous",
      icon: "far fa-cogs",
      subItems: [
        {
          label: t("sidebar.predefined-jobs"),
          link: "/predefined-jobs",
          permissions: ["predefined_job.view"],
        },
        {
          label: t("sidebar.pre-canned-messages"),
          link: "/messages/canned-messages",
        },
        {
          label: t("sidebar.general-settings"),
          link: "/admin/general-settings",
        },
        { label: t("sidebar.certifications"), link: "/admin/certifications" },
      ],
    },
    {
      label: "Control Panel",
      icon: "far fa-wrench",
      link: "/control-panel",
    },
  ];

  const showLocationDropdown =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";

  const showExitLocation =
    (isEnterprise && roles[0] === "owner") || roles[0] === "regional_manager";

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocationDropdown) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select a location" }));
              }
            }
          } else {
            dispatch(setSelectedLocation(resLocations[0]));
          }
        }
      }
    });
  };

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      setShowNotification({
        show: true,
        type: "success",
        message: "Location Changed!",
      });
    } catch (error) {
      setShowNotification({
        show: true,
        type: "error",
        message: "Error Occurred during Location switching. Try Again!",
      });
    }
  };

  const UserInfo = () => {
    return (
      <>
        <div className={"flex justify-center items-center"}>
          <div className="flex flex-col justify-between text-black">
            <span className={"block font-semibold mb-0 text-center"}>
              Switch Business
            </span>
          </div>
        </div>
        {showAddress &&
          displayAddress &&
          roles[0] === "super-user" &&
          selectedLocation?.id && (
            <div className="my-4  rounded-md p-4">
              <p className="w-48 font-semibold text-black text-sm">
                {selectedLocation?.address?.address +
                  ", " +
                  selectedLocation?.address?.city +
                  ", " +
                  selectedLocation?.address?.state || " "}
              </p>
            </div>
          )}
      </>
    );
  };

  const menu = (
    <div
      className="z-10 origin-top-right w-[260px] h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] 
              rounded-lg shadow-lg bg-white focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div
        className="py-1 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] overflow-auto"
        role="none"
      >
        <p className="text-sm font-bold pl-5">Location:</p>
        {roles[0] !== "turnkey_admin" &&
          locations.map((location, index) => (
            <div className="font-bold text-sm">
              <DropdownMenuItem
                key={location?.id}
                onClick={() => {
                  dispatch(setSelectedLocation(location));
                  localStorage.setItem(
                    "selectedLocation",
                    JSON.stringify(location)
                  );
                  changeLocation(location?.id);
                  if (roles[0] !== "super-user") {
                    setDisplayAddress(false);
                  }
                  navigate(`/business/dashboard?location=${location?.id}`);
                }}
                label={location?.name}
                icon="far fa-map-marker-alt"
              />
            </div>
          ))}
      </div>
    </div>
  );

  const BusinessLocation = ({ className }) => {
    if (roles[0] !== "super-user" && selectedLocation?.id) return <></>;
    return (
      <div className={className}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="topRight"
          overlayClassName={""}
        >
          <a
            href="#href"
            className="ant-dropdown-link hover:text-primary-500"
            onClick={(e) => e.preventDefault()}
          >
            <div className="flex cursor-pointer hover:ring2 hover:ring-violet-200">
              <div
                className={
                  "p-3 w-full rounded flex justify-center text-white cursor-pointer"
                }
              >
                <button className="rounded-md bg-primary-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {locations && locations.length > 0 && selectedLocation && (
                    <span>{selectedLocation?.name}</span>
                  )}
                </button>
              </div>
            </div>
          </a>
        </Dropdown>
      </div>
    );
  };

  const handleClickMenu = (path) => {
    navigate(path);
  };
  return (
    <>
      <Disclosure
        as="nav"
        className={`${mode ? "bg-gray-900" : "bg-warmgray-900"} 
        shadow relative`}
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-5">
              <div className="relative flex h-16 justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button
                    className="inline-flex items-center justify-center rounded-md p-2 text-white hover:text-secondary
                  focus:outline-none"
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={heyhireIcon}
                      alt="Your Company"
                    />
                    <p className="text-[9px] pl-1 font-extrabold pt-2 text-white">
                      Admin
                    </p>
                  </div>

                  <div className="hidden sm:ml-6 sm:flex md:space-x-5 text-[13px] font-medium text-white">
                    {superUserItems.map((item, _x) => (
                      <Menu
                        as="div"
                        key={_x}
                        className="relative text-left mt-5"
                      >
                        <div>
                          <Menu.Button
                            onClick={(e) => handleClickMenu(item.link)}
                            className="inline-flex text-[10px] md:text-[12px] items-center px-1 pt-1 hover:text-secondary"
                          >
                            {item.label}
                          </Menu.Button>
                        </div>
                        {item.subItems && item.subItems.length && (
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                            className="bg-white z-50"
                          >
                            <Menu.Items
                              key={_x}
                              className="origin-top-right absolute right-0 mt-2 w-40
                         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="py-1">
                                {item.subItems.map((subItem, _j) => (
                                  <Menu.Item key={_j}>
                                    {({ active }) => (
                                      // <a
                                      //   href={subItem.link}
                                      //   className={`${
                                      //     active
                                      //       ? " text-primary-700 hover:bg-primary-500 hover:text-white "
                                      //       : " text-gray-900"
                                      //   } block px-4 py-2 text-sm`}
                                      // >
                                      //   {subItem.label}
                                      // </a>
                                      <div
                                        className={`${
                                          active
                                            ? " text-primary-700 hover:bg-primary-500 hover:text-white "
                                            : " text-gray-900"
                                        } block px-4 py-2 text-sm`}
                                      >
                                        <Link
                                          to={subItem.link}
                                          className="block"
                                        >
                                          {subItem.label}
                                        </Link>
                                      </div>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        )}
                      </Menu>
                    ))}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="relative ml-3">
                    <div className="admin-switch">
                      <Menu.Button className="flex ">
                        {selectedLocation?.brand?.photo?.thumb_url ? (
                          <div
                            className="relative rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500
                           focus:ring-offset-2  justify-center p-[5px] min-w-[30px] min-h-[30px]"
                          >
                            <img
                              width={30}
                              height={30}
                              src={
                                selectedLocation?.brand?.photo?.thumb_url ||
                                defaultUserImage
                              }
                              alt={user?.company?.name}
                              className="object-fill rounded-full"
                            />

                            <ArrowsUpDownIcon
                              className="absolute h-4 w-4 mr-1 bottom-7 left-7 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-row text-[12px] font-bold text-white">
                            <div>
                              <ArrowsUpDownIcon
                                className="block h-4 w-4 mr-1"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="">Switch Business</div>
                          </div>
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className={`absolute right-0 z-10 mt-2 w-70 origin-top-right rounded-md ${
                          mode ? "bg-white" : "bg-white opacity-100"
                        } py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <Menu.Item>
                          <div className="flex flex-col mb-5">
                            <div className="flex flex-col flex-1">
                              <div className="flex-1 flex flex-col pt-12 pb-4 px-6 scroll-custom min-w-[260px] max-w-[260px]">
                                <UserInfo />
                              </div>
                              {showLocationDropdown && (
                                <BusinessLocation
                                  className={"relative mt-2 w-full"}
                                />
                              )}
                            </div>
                          </div>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {/* <Menu as="div" className="relative ml-3">
                    <Switch
                      checked={mode}
                      onChange={HandleBg}
                      className={classNames(
                        mode ? "bg-primary-400" : "bg-gray-200",
                        "relative flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      )}
                    >
                      <span
                        className={classNames(
                          mode
                            ? "translate-x-0 bg-white"
                            : "translate-x-5 bg-midnight",
                          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full  shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      >
                        <span
                          className={classNames(
                            mode
                              ? "opacity-100 duration-200 ease-in"
                              : "opacity-0 duration-100 ease-out",
                            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
                          </svg>
                        </span>
                        <span
                          className={classNames(
                            mode
                              ? "opacity-0 duration-100 ease-out"
                              : "opacity-100 duration-200 ease-in",
                            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 384 512"
                          >
                            <path
                              d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                  </Menu> */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button
                        className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 
                    focus:ring-offset-2"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={defaultUserImage}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className={`absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md text-gray-900 text-[12px] font-medium bg-white py-1 
                    shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <Menu.Item>
                          <DropdownMenuItem
                            onClick={() => navigate("/business/my-account")}
                            label="Profile"
                            icon="far fa-user"
                          />
                        </Menu.Item>
                        <Menu.Item>
                          <DropdownMenuItem
                            label="Switch Accounts"
                            icon="far fa-repeat"
                            onClick={() =>
                              navigate(
                                isSuper ? "/business/dashboard" : "/dashboard"
                              )
                            }
                          />
                        </Menu.Item>
                        <Menu.Item>
                          <DropdownMenuItem
                            onClick={() => dispatch(logout())}
                            label="Logout"
                            icon="far fa-sign-out"
                          />
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-4 pt-2 text-white">
                <Disclosure.Button
                  as={Link}
                  to="/dashboard"
                  className={`block py-2 pl-3 pr-4 text-base font-medium ${
                    location.pathname === "/admin/dashboard" ? "border-l-4" : ""
                  }`}
                >
                  Dashboard
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to="/companies"
                  className={`block py-2 pl-3 pr-4 text-base font-medium ${
                    location.pathname === "/companies"
                      ? "border-l-4"
                      : "border-l-4 border-transparent hover:border-gray-300 hover:text-secondary"
                  }`}
                >
                  Business
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to="/users"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:text-secondary"
                >
                  Users
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to="/control-panel"
                  className={`block py-2 pl-3 pr-4 text-base font-medium ${
                    location.pathname === "/control-panel"
                      ? "border-l-4"
                      : "border-l-4 border-transparent hover:border-gray-300 hover:text-secondary"
                  }`}
                >
                  Control panel
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
    </>
  );
}
