import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import { useSelector } from "react-redux";
import { darkmode } from "../../../../store/authSlice";

const HiringPlan = ({ onSubmit }) => {
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const params = useParams();
  const location = useLocation();
  const pathname = location?.pathname;
  const isPreviousStep = location.state && location.state.isPrevious;
  const [isEnabledPlus, setEnabledPlus] = useState(true);
  const [isEnabledInfinity, setEnabledInfinity] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const storedPlan = localStorage.getItem("selectedPlan");
    return storedPlan ? JSON.parse(storedPlan) : null;
  });

  const handleSelectedPlan = (planName) => {
    let plan_id;

    if (planName === "go") {
      plan_id = 1;
    } else if (planName === "plus") {
      plan_id = isEnabledPlus ? 3 : 2;
    } else if (planName === "infinity") {
      plan_id = isEnabledInfinity ? 5 : 4;
    }

    const planJson = JSON.stringify({ planName, plan_id });
    localStorage.setItem("selectedPlan", planJson);
    localStorage.removeItem("registerStep0");
    localStorage.removeItem("registerStep1");
    localStorage.removeItem("registerStep2");
    setSelectedPlan(planName);
    navigate("/register?step=1");
  };

  // useEffect(() => {
  //   if (pathname === "/plans" && !isPreviousStep) {
  //     const storedPlan = localStorage.getItem("selectedPlan");
  //     return storedPlan ? navigate("/register?step=1") : null;
  //   } else if (params?.plan_type === "go") {
  //     localStorage.setItem("selectedPlan", JSON.stringify("go"));
  //     setSelectedPlan("go");
  //     navigate("/register?step=1");
  //   } else if (params?.plan_type === "plus") {
  //     localStorage.setItem("selectedPlan", JSON.stringify("plus"));
  //     setSelectedPlan("plus");
  //     navigate("/register?step=1");
  //   } else if (params?.plan_type === "infinity") {
  //     localStorage.setItem("selectedPlan", JSON.stringify("infinity"));
  //     setSelectedPlan("infinity");
  //     navigate("/register?step=1");
  //   }
  // }, []);

  const heyhireGoList = [
    "Unlimited applicants",
    "Unlimited hires",
    "Custom landing page",
    "Banner maker",
    "App map placement",
  ];

  const heyhireLightList = [
    "Unlimited Templates",
    "2 Users",
    "Integrations (from library)",
    "Calender integration",
    "Customer success manager",
    "Complimentary banner design (annnual only)",
  ];

  const heyhireUnlimitedList = [
    "Unlimited Users",
    "Integrations (custom)",
    "Complimentary banner print + fulfilment (annual only)",
    "White glove onboarding",
  ];

  return (
    <AuthLayout restrictSwitch={false}>
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div
              className={`sm:mx-auto sm:w-full flex flex-col justify-center h-full ${
                mode ? "text-black" : "text-white"
              }`}
            >
              <div className="sm:mx-auto w-full">
                <div className="flex flex-col md:flex-row gap-4 lg:gap-6 justify-center sm:rounded-lg lg:px-6">
                  <div
                    className={`w-full md:w-[340px] mt-4 rounded-md ${
                      mode ? "bg-[#F6F6F6]" : "bg-slate-800"
                    }`}
                  >
                    <div className="m-4 lg:m-6 min-w-[180px] lg:min-w-[230px]">
                      <div className="flex">
                        <div className="flex flex-col">
                          <p className="h-8"></p>
                          <p className="font-arial text-[14px] lg:text-xl xl:text-2xl mb-3">
                            Go
                          </p>
                          <p className="h-auto md:h-24">
                            Maximize your job post visibility within the local
                            community, attracting more qualified candidates
                            eager to work close to home.
                          </p>
                          <div className="h-36"></div>
                          <p className="text-4xl lg:text-4xl my-1 lg:my-2 flex">
                            <span className="font-arial">$49</span>
                            <span className="text-2xl font-arial mt-auto ml-2">
                              /m
                            </span>
                          </p>
                          <div className="rounded-full bg-primary-500 py-1.5 px-4 w-fit text-white my-2">
                            1 position
                          </div>
                        </div>
                      </div>

                      <div className="h-auto md:h-80 flex flex-col w-full mt-1 overflow-y-auto">
                        {heyhireGoList?.map((item) => (
                          <>
                            <hr className="w-full border-1 border-black" />
                            <div className="w-full my-2.5">
                              <span className="font-arial text-sm lg:text-[14px]">
                                {item}
                              </span>
                            </div>
                          </>
                        ))}
                      </div>

                      <div className="mt-2">
                        <button
                          className="rounded-full cursor-pointer bg-midnight text-white py-4 w-full hover:bg-white hover:text-black hover:border-black border"
                          onClick={() => handleSelectedPlan("go")}
                        >
                          Choose plan
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-full md:w-[340px] mt-4 border-2 md:border-0 rounded-md md:rounded-none md:shadow-none shadow-primary-300 ${
                      mode ? "bg-white" : "bg-slate-800"
                    }`}
                  >
                    <div className="m-4 lg:m-6 min-w-[180px] lg:min-w-[230px]">
                      <div className="flex">
                        <div className="flex flex-col">
                          <p className="text-[#BC74A9] h-8">Most popular</p>
                          <p className="font-arial text-[14px] lg:text-xl xl:text-2xl mb-3">
                            Plus
                          </p>
                          <p className="h-auto md:h-24">
                            Maximize your job post visibility within the local
                            community, attracting more qualified candidates
                            eager to work close to home.
                          </p>

                          <div className="flex flex-col h-36">
                            <div className="bg-[#F6F6F6] rounded-full flex p-3 gap-4">
                              <button
                                onClick={() => setEnabledPlus(false)}
                                className={`${
                                  !isEnabledPlus
                                    ? "bg-midnight text-white"
                                    : "text-black"
                                } w-1/2 rounded-full p-3 font-semibold`}
                              >
                                Monthly
                              </button>
                              <button
                                onClick={() => setEnabledPlus(true)}
                                className={`${
                                  isEnabledPlus
                                    ? "bg-midnight text-white"
                                    : "text-black"
                                } w-1/2 rounded-full p-3 font-semibold`}
                              >
                                Annual
                              </button>
                            </div>
                            {isEnabledPlus && (
                              <p className="mt-2 mx-auto">
                                Save 33% on Annual Plan
                              </p>
                            )}
                          </div>

                          <p className="text-4xl lg:text-4xl my-1 lg:my-2 flex">
                            <span className="font-arial">
                              {isEnabledPlus ? "$99" : "$129"}
                            </span>
                            <span className="text-2xl font-arial mt-auto ml-2">
                              /m
                            </span>
                          </p>

                          <div className="rounded-full bg-primary-500 py-1.5 px-4 w-fit text-white my-2">
                            3 positions
                          </div>
                        </div>
                      </div>

                      <div className="h-auto md:h-80 flex flex-col w-full mt-1 overflow-y-auto">
                        {heyhireLightList?.map((item) => (
                          <>
                            <hr className="w-full border-1 border-black" />
                            <div className="w-full my-2.5">
                              {item === "Unlimited Templates" && (
                                <p className="text-[#BC74A9] mb-4 italic">
                                  Everything in Go, plus:
                                </p>
                              )}
                              <span className="font-arial text-sm lg:text-[14px]">
                                {item}
                              </span>
                            </div>
                          </>
                        ))}
                      </div>

                      <div className="mt-2">
                        <button
                          className="rounded-full cursor-pointer bg-midnight hover:bg-white hover:text-black text-white py-4 w-full border hover:border-black"
                          onClick={() => handleSelectedPlan("plus")}
                        >
                          Choose plan
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-full md:w-[340px] mt-4 border-2 md:border-0 rounded-md md:rounded-none md:shadow-none ${
                      mode ? "bg-white" : "bg-slate-800"
                    }`}
                  >
                    <div className="m-4 lg:m-6 min-w-[180px] lg:min-w-[230px]">
                      <div className="flex gap-1">
                        <div className="flex flex-col">
                          <p className="h-8"></p>
                          <p className="font-arial text-[14px] lg:text-xl xl:text-2xl mb-3">
                            Infinity
                          </p>
                          <p className="h-auto md:h-24">
                            Maximize your job post visibility within the local
                            community, attracting more qualified candidates
                            eager to work close to home.
                          </p>

                          <div className="flex flex-col h-36">
                            <div className="bg-[#F6F6F6] rounded-full flex p-3 gap-4">
                              <button
                                onClick={() => setEnabledInfinity(false)}
                                className={`${
                                  !isEnabledInfinity
                                    ? "bg-midnight text-white"
                                    : "text-black"
                                } w-1/2 rounded-full p-3 font-semibold`}
                              >
                                Monthly
                              </button>
                              <button
                                onClick={() => setEnabledInfinity(true)}
                                className={`${
                                  isEnabledInfinity
                                    ? "bg-midnight text-white"
                                    : "text-black"
                                } w-1/2 rounded-full p-3 font-semibold`}
                              >
                                Annual
                              </button>
                            </div>
                            {isEnabledInfinity && (
                              <p className="mt-2 mx-auto">
                                Save 33% on Annual Plan
                              </p>
                            )}
                          </div>

                          <p className="text-4xl lg:text-4xl my-1 lg:my-2 flex">
                            <span className="font-arial">
                              {isEnabledInfinity ? "$149" : "$199"}
                            </span>
                            <span className="text-2xl font-arial mt-auto ml-2">
                              /m
                            </span>
                          </p>

                          <div className="rounded-full bg-primary-500 py-1.5 px-4 w-fit text-white my-2">
                            Unlimited positions
                          </div>
                        </div>
                      </div>

                      <div className="h-auto md:h-80 flex flex-col w-full mt-1 overflow-y-auto">
                        {heyhireUnlimitedList?.map((item) => (
                          <>
                            <hr className="w-full border-1 border-black" />
                            <div className="w-full my-2.5">
                              {item === "Unlimited Users" && (
                                <p className="text-[#BC74A9] mb-4 italic">
                                  Everything in Plus, plus:
                                </p>
                              )}
                              <span className="font-arial text-sm lg:text-[14px]">
                                {item}
                              </span>
                            </div>
                          </>
                        ))}
                      </div>

                      <div className="mt-2">
                        <button
                          className="rounded-full cursor-pointer bg-midnight text-white py-4 w-full hover:bg-white hover:text-black hover:border-black border"
                          onClick={() => handleSelectedPlan("infinity")}
                        >
                          Choose plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <div
                  className={`title text-sm text-center ${
                    mode ? "text-black" : "text-primary-100"
                  }`}
                >
                  By registering, you are agreeing to the
                  <Link
                    to="https://www.heyhire.com/terms-conditions"
                    target="_blank"
                    className={`${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold ml-1`}
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and
                  <Link
                    to="https://www.heyhire.com/privacy"
                    target="_blank"
                    className={`ml-1 ${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold`}
                  >
                    Privacy Policy
                  </Link>
                </div>

                <div
                  className={`text-sm text-center mt-2 mb-8 ${
                    mode ? "text-black" : "text-white"
                  }`}
                >
                  Already registered?
                  <Link
                    to="/login"
                    className={`${
                      mode
                        ? "text-indigo-600 hover:text-indigo-500 "
                        : "text-secondary hover:text-white"
                    } font-semibold ml-1`}
                  >
                    Click here
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default HiringPlan;
