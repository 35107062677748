import { Pagination } from "antd";
import React from "react";
const PaginationView = ({
  current = 1,
  next = 1,
  pageSize = 2,
  total = 1,
  onChange,
  className
}) => {
  return (
    <div className={className}>
      <Pagination
        current={current}
        pageSize={pageSize}
        total={total}
        showTotal={(total) => <div className="font-bold">Total : {total}</div>}
        onChange={onChange}
      />
    </div>
  );
};

export default PaginationView;
