import { Form, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import HButton from "../../atoms/HButton";
import "./FormModal.scss";

function FormModal({
  children,
  visible,
  onClose,
  headerBg = "bg-primary-500",
  title,
  subTitle,
  onSubmit,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onClose}
      closable={false}
      footer={null}
    >
      <div className="align-bottom bg-white text-left overflow-hidden shadow-xl">
        <button
          className="absolute right-0 m-3 p-3 text-white text-opacity-80 hover:text-opacity-100 text-xl"
          onClick={onClose}
        >
          <i className="far fa-times" />
        </button>

        <div className={"w-full text-center py-10 " + headerBg}>
          <span className="block text-xl text-white font-normal">{title}</span>
          <span className="block text-white text-sm text-opacity-80 mt-3 px-10">
            {subTitle}
          </span>
        </div>
        <div className="w-full">
          <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
            <Form onFinish={onSubmit} layout="vertical">
              {children}

              <hr className="mt-8" />

              <div className="mt-8 flex">
                <HButton
                  variant="outlined"
                  className="h-[40px] flex-1 text-[12px]"
                >
                  {t("common.cancel")}
                </HButton>
                <HButton
                  className="h-[40px] flex-1 ml-3 text-[12px]"
                  type="submit"
                >
                  {t("common.save")}
                </HButton>
              </div>

              <div className="text-center mt-5">
                <button>{t("click-here-to-save-as-draft")}</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FormModal;
