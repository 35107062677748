import { Button, Input } from "antd";
import React from "react";
import { useState } from "react";
import PrimaryButton from "../../components/atoms/Buttons/PrimaryButton";
import AuthLayout from "../../components/organisms/Layout/Authlayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FloatingInputText from "../../components/form/FloatingInputText";
import {useEffect} from "react";
import PositionForm from "./PositionForm";


const AccountConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [turnKeyData, setTurnKeyData] = useState([]);
  const [logoURL, setLogoURL] = useState([]); 
  const [locationName,setLocationName] = useState([]);
  const [address, setAddress] = useState([]);

  const navigate = useNavigate();
  const { state } = useLocation();


  useEffect(() => {
      document.title = "HeyHire Portal-Easy-Confirmation";

      if (state === null) {
        // navigate("/login");
      } else {
        const { turnKeyData } = state;
        setTurnKeyData(turnKeyData);

        let payload = JSON.parse(turnKeyData.payload);
        setLogoURL(payload["brandLogoUrl"]);
        setLocationName(payload["turnKeyLocationName"]);
        setAddress(payload['turnKeyState'] + ' , ' + payload['turnKeyZip']);
        // setLogoURL(turn)
        //console.log("new page :" + getLogo["brandLogoUrl"]);
      }
  }, []);

  const handleClick = () => {
    navigate("/loading-profile");
  };

  return (
    <AuthLayout
      showLink
      title="Verify Positions & Location"
      customLogo={logoURL}
      widthFull={true}
    >
    <div className="flex flex-col justify-center items-center px-4 mt-16 w-full">
      <div className="flex flex-row justify-center text-center font-bold align-middle text-gray-500 animate-fade-in-from-top-.5s">
        <div className="pr-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#088F8F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="animate-bounce"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
        </div>
        Add or remove positions in seconds
      </div>
      <PositionForm />

      <div className="container flex flex-col justify-center items-center mt-2">
        <div class="w-full max-w-lg pt-5 pb-4" autocomplete="off">
          <div>
            <h1 className="text-lg font-bold tracking-tight text-[#2C3E50] ">
              Location Details
            </h1>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <div class="relative animate-fade-in-from-top-.5s">
                <FloatingInputText
                  type="text"
                  id="Establishment"
                  label="Name of The Establishment"
                  placeholder="please verify . . ."
                  className="my-3"
                  value={locationName}
                />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0">
              <div class="relative animate-fade-in-from-top-.5s">
                <FloatingInputText
                  type="text"
                  id="Address"
                  label="Address"
                  placeholder="Address"
                  className="my-3"
                  value={address}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-4 w-full md:w-fit">
          <button
            className="h-[45px] w-full md:max-w-lg rounded-full px-16 pt-[10px] pb-[9px] text-white text-lg font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-500 disabled:opacity-70 shadow-md"
            onClick={handleClick}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
    </AuthLayout>
  );
};

export default AccountConfirmation;
