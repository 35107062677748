import React, { useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useTranslation } from "react-i18next";
import PredefinedJob from "../../api/PredefinedJob";
import { toast } from "react-toastify";
import TableActionDropdown from "../../components/TableActionDropdown";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";

const PreDefinedJobs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Position Templates";
    loadPredefinedJobs();
  }, []);

  const loadPredefinedJobs = () => {
    setLoading(true);
    PredefinedJob.getAll()
      .then((response) => setJobs(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const deleteJob = (id) => {
    PredefinedJob.delete(id)
      .then(() => {
        const dataCopy = jobs.slice();
        dataCopy.splice(
          jobs.findIndex((item) => item?.id === id),
          1
        );
        setJobs(dataCopy);
        toast.success(t("Job Deleted Successfully"));
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">
          {t("pre-defined-jobs.pre-defined-jobs")}
        </h2>

        <Button
          className="mr-2.5"
          onClick={() => {
            navigate("/predefined-jobs/add");
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>
      </div>

      <div className="w-full">
        <div className="overflow-x-auto h-full w-full">
          <table className="min-w-full divide-y divide-gray-200 mt-3 pb-10">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">
                    {t("pre-defined-jobs.position")}
                  </div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("pre-defined-jobs.type")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("common.category")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("common.status")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider text-right">
                  <div className="tbl-cell justify-end">
                    {t("common.action")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {!loading &&
                jobs.map((job) => {
                  return (
                    <tr key={job.id} className="cursor-pointer">
                      <td onClick={() => navigate(`edit/${job.id}`)}>
                        <div className="tbl-cell">{job.title}</div>
                      </td>
                      <td>
                        <div className="tbl-cell">{job.availability}</div>
                      </td>
                      <td>
                        <div className="tbl-cell">{job?.category?.name}</div>
                      </td>
                      <td>
                        <div className="tbl-cell">Active</div>
                      </td>
                      <td className="text-right">
                        <div className="tbl-cell text-right justify-end">
                          <TableActionDropdown
                            onClickEdit={() =>
                              navigate(`/predefined-jobs/edit/${job.id}`)
                            }
                            onClickDelete={() => deleteJob(job?.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {loading && <Loading />}
      </div>
    </DashboardLayout>
  );
};

export default PreDefinedJobs;
