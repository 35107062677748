import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "../components/organisms/Layout";
import {
  login,
  newSubUser,
  selectRoles,
  selectUser,
  setNewSubUser,
} from "../store/authSlice";
import User from "../api/User";
import { Form, Space } from "antd";
import AddUserModal from "./auth/Register/Step5/AddUserModal";

const JobPositionRegister = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const user = useSelector(selectUser);
  const isBusinessOwner = user?.company?.is_enterprise === 0;
  const roles = useSelector(selectRoles);
  const newUser = useSelector(newSubUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    loadUsers();
    dispatch(setNewSubUser(false));
  }, [newUser]);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await User.getAll();
      const _users = response?.data?.data?.filter((i) => i?.id !== user?.id);
      let userData = _users;
      if (isBusinessOwner) {
        userData = _users.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
      } else {
        userData = filterUsers(userData);
      }
      setUsers(userData);
      setLoading(false);
    } catch (error) {
      if (error.response.status !== 403) {
        toast.error("Error trying to load users. Please try again.");
      }
      setLoading(false);
    }
  };

  const filterUsers = (userList) => {
    let tempList = [];
    switch (roles[0]) {
      case "regional_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
        break;
      case "location_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name === "employee" &&
            e?.roles[0]?.name !== "super-user"
        );
        break;
      case "owner":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name !== "regional_manager" &&
            e.roles[0]?.name !== "owner" &&
            e?.roles[0]?.name !== "super-user"
        );
        break;
      default:
        tempList = userList;
        break;
    }
    return tempList;
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-1 flex-col justify-center h-full">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                <div className="flex flex-col h-full items-center sm:rounded-lg sm:px-12 lg:px-6">
                  <p className="text-xl font-bold tracking-wide">
                    Add Your Hiring Team
                  </p>
                  {loading && (
                    <i className="fad fa-circle-notch fa-spin text-lg ml-2 mt-4" />
                  )}

                  {users?.length === 0 && !loading && (
                    <p className="text-sm mt-2 font-normal tracking-wide">
                      Not interested in adding teammates right now?{" "}
                      <span
                        className="sm:inline-block mt-2 sm:mt-0 flex justify-center font-bold text-primary-500 text-[13px] underline cursor-pointer"
                        onClick={() => navigate("/add-job")}
                      >
                        Skip this Step
                        <i className="fas fa-arrow-right ml-2 my-auto"></i>
                      </span>
                    </p>
                  )}

                  <Form layout="vertical" className="w-full">
                    {users?.length > 0 && !loading && (
                      <div className="mt-4 p-4 bg-white rounded border-2 h-96 shadow-sm overflow-hidden overflow-x-auto">
                        <Space direction="vertical" className="w-full">
                          {users.length === 0 ? (
                            <div className="flex justify-center items-center h-full">
                              <p className="text-gray-500">
                                No users added yet.
                              </p>
                            </div>
                          ) : (
                            <Space direction="vertical" className="w-full">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-white">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="pr-4 py-3 text-left font-bold tracking-wider"
                                    >
                                      {t("common.full-name")}
                                    </th>

                                    <th
                                      scope="col"
                                      className="pr-4 py-3 text-left font-bold tracking-wider"
                                    >
                                      {t("common.email")}
                                    </th>

                                    {/* <th
                            scope="col"
                            className="px-6 py-3 text-right font-bold tracking-wider"
                          >
                            {t("common.action")}
                          </th> */}
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-300">
                                  {!loading &&
                                    users.map((user) => {
                                      return (
                                        <tr
                                          key={user.id}
                                          className="hover:bg-gray-50"
                                        >
                                          <td className="pr-4 py-2 whitespace-nowrap">
                                            {user.first_name} {user.last_name}
                                          </td>

                                          <td className="p4-4 py-2 whitespace-nowrap">
                                            {user.email}
                                          </td>

                                          {/* {data?.user?.id == user?.id ? (
                                  <td className="px-6 py-4 whitespace-nowrap text-right">
                                    <DropActionDropdown
                                      className="ml-5"
                                      icon="far fa-ellipsis-h"
                                      iconColor="text-primary-500"
                                      menus={[
                                        {
                                          label: "Edit",
                                          // action: () =>
                                          // navigate(
                                          //   `${path}/edit/${user?.id}`
                                          // ),
                                        },
                                      ]}
                                    />
                                  </td>
                                ) : (
                                  <td className="px-6 py-4 whitespace-nowrap text-right">
                                    <DropActionDropdown
                                      className="ml-5"
                                      icon="far fa-ellipsis-h"
                                      iconColor="text-primary-500"
                                      menus={
                                        data?.roles[0]?.name === "owner" &&
                                        user?.roles[0]?.name === "owner"
                                          ? [
                                              {
                                                label: "Edit",
                                                // action: () =>
                                                //   navigate(
                                                //     `${path}/edit/${user?.id}`
                                                //   ),
                                              },
                                            ]
                                          : [
                                              {
                                                label: "Edit",
                                                // action: () =>
                                                //   navigate(
                                                //     `${path}/edit/${user?.id}`
                                                //   ),
                                              },
                                              {
                                                label: "Delete",
                                                // action: () =>
                                                //   deleteUser(user?.id),
                                              },
                                            ]
                                      }
                                    />
                                  </td>
                                )} */}
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </Space>
                          )}
                        </Space>
                      </div>
                    )}
                    <div
                      className={`flex ${
                        users?.length > 0 ? "mt-4" : "mt-12"
                      } w-full justify-center`}
                    >
                      <button
                        onClick={() => setIsModalVisible(true)}
                        disabled={loading}
                        className={`${
                          users?.length === 0 && "mx-12"
                        } flex w-full justify-center rounded-md bg-primary-500 px-6 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50`}
                      >
                        Add teammate
                      </button>
                    </div>
                  </Form>

                  {isModalVisible && (
                    <AddUserModal
                      open={isModalVisible}
                      setOpen={setIsModalVisible}
                      data={user}
                    />
                  )}
                </div>
              </div>
              <div className="flex border-t-2 py-4 border-primary-500 justify-center">
                <div className="flex w-full">
                  <button
                    className="ml-auto font-bold text-sm mr-2 px-6 py-2 w-32 rounded-md bg-primary-500 text-white disabled:opacity-50"
                    type="submit"
                    onClick={() => navigate("/add-job")}
                  >
                    {users?.length > 0 ? "Next" : "Skip"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default JobPositionRegister;
