import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import PredefinedJob from "../../api/PredefinedJob";
import FormSuccessWithButton from "../../components/atoms/Notification/FormSuccessWithButton";
import FloatingInputText from "../../components/form/FloatingInputText";
import MultiSelector from "../../components/atoms/Selector/MultiSelector";
import FormTextarea from "../../components/atoms/FormTextarea/FormTextarea";
import GradientPrimaryButton from "../../components/atoms/Buttons/GradientPrimaryButton";
import JobPosition from "../../api/JobPosition";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import User from "../../api/User";
import { useSelector } from "react-redux";
import { darkmode } from "../../store/authSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PositionForm = () => {

const [positionList, setPositionList] = useState([]);
const [positionRefresh, setPositionRefresh] = useState(false); 
const [showAddPositionButton, setShowAddPositionButton] = useState(false);
const [positionAddedNotification, setPositionAddedNotification] =  useState(false);
const [selectedPositions, setSelectedPositions] = useState(true);
const [isQuickAddPositionVisible, setIsQuickAddPositionVisible] = useState(false);
const [turnKeyPredefinedTitle, setTurnKeyPredefinedTitle] = useState('');
const [turnKeyPredefinedDescription, setTurnKeyPredefinedDescription] = useState('');
const [turnKeyPredefinedExperience, setTurnKeyPredefinedExperience] = useState(''); 

const [showAddNewPositionTab,setShowAddNewPositionTab] = useState(false);
const [predefinedValidation, setPredefinedValidation] = useState(false);
const [businessExistingPosition, setBusinessExistingPosition] = useState([]);
const [triggerDeleteConfirmation, setTriggerDeleteConfirmation] = useState(false);
const [positionId, setPositionId] = useState(false);
const [locationId, setLocationId] = useState([]);
const mode = useSelector(darkmode);

useEffect(() => {
  getLocationPosition();
  getPositionList();
  getUserDetails();
}, []);


useEffect(() => {
  if (validateInput() === true) {
    setPredefinedValidation(true);
  } else {
    setPredefinedValidation(false);
  }
}, [
  turnKeyPredefinedTitle,
  turnKeyPredefinedDescription,
  turnKeyPredefinedExperience,
  showAddNewPositionTab,
]);

  const showQuickAddPositionForm = () => {
    setShowAddPositionButton(false);
    setPositionAddedNotification(false);
   setIsQuickAddPositionVisible(!isQuickAddPositionVisible);
  };

    const enterNewPosition = () => {
      setPositionAddedNotification(false);
      setShowAddPositionButton(true);
      setIsQuickAddPositionVisible(false);
      setTurnKeyPredefinedTitle("");
      setTurnKeyPredefinedDescription("");
      setTurnKeyPredefinedExperience("");
    };


const getLocationPosition = async() => {
      const existingPositions =  await JobPosition.getAll();
      setBusinessExistingPosition(existingPositions.data.data);
}    


const getUserDetails = async() => {
 const user = await User.getAll();
setLocationId(user.data.data[0].location.id);
}

const getPositionList = async () => {
  let positionData = [];
  const allPreDefinedPositions = await PredefinedJob.getAllTitle();

  const c = allPreDefinedPositions.data.data;
  for (let i = 0; i < c.length; i++) {
    const el = c[i];
    positionData.push({
      label: el.title,
      value: el.id,
    });
  }
  setPositionList(positionData);
  setPositionRefresh(true);
};

const positionSelectionHandler = (event, flag) => {
  let fetchPositionDetailsFromId = "";
  let length = event.length;

  setSelectedPositions(false);

  if (length === 0) {
    setTurnKeyPredefinedTitle("");
    setTurnKeyPredefinedDescription("");
    setTurnKeyPredefinedExperience("");
    return;
  } else {
    fetchPositionDetailsFromId = event[length - 1].value;
  }

  PredefinedJob.getById(fetchPositionDetailsFromId)
    .then((response) => {
      setTurnKeyPredefinedTitle(response.data.data.title);
      setTurnKeyPredefinedDescription(response.data.data.description);
      setTurnKeyPredefinedExperience(response.data.data.experience);
    })
    .catch((error) => {
      console.log("cannot fetch the predefined job with id:" + event);
    });
};

const collectTurnKeyData = (key, value) => {
  if (key === "turnKeyPredefinedTitle") {
    setTurnKeyPredefinedTitle(value);
  }
};

const getDate = () => {
    return new Date().toLocaleString();
}

const capturePositionDescription = (description) => {
 setTurnKeyPredefinedDescription(description);
};

const capturePositionExperience = (experience) => {
    setTurnKeyPredefinedExperience(experience);
};

const validateInput = () => {
    if(turnKeyPredefinedTitle !== '' && turnKeyPredefinedDescription !== '' && turnKeyPredefinedExperience !== '') {
        return true;
    }else {
        return false;
    }
}

     
const handleSavePredefinedPosition = () => {
    if (
    turnKeyPredefinedTitle !== "" &&
    turnKeyPredefinedExperience !== ""
    ) {
    addNewPredefinedPositions();
    }
};
const addNewPredefinedPositions = () => {
  const newPredefinedPositionData = {
    location_id: locationId,
    category_id: 0,
    start_date: getDate(),
    hiring_status: 1,
    title: turnKeyPredefinedTitle,
    description: turnKeyPredefinedDescription,
    experience: turnKeyPredefinedExperience,
    availability: "full-time",
  };

  JobPosition.add(newPredefinedPositionData)
    .then((response) => {
      setTurnKeyPredefinedTitle("");
      setTurnKeyPredefinedDescription("");
      setTurnKeyPredefinedExperience("");
      setPositionRefresh(!positionRefresh);
      setPositionAddedNotification(true);
      getLocationPosition();
    })
    .catch((error) => {
      console.log("cannot fetch the predefined job with id:" + error);
    });
};

const deletePosition = async(id) => {
    setPositionId(id);
    openDeleteConfirm(id)     
}

const openDeleteConfirm = () => {
    setTriggerDeleteConfirmation(true);
}

const closeDeleteConfirmation = () => {
    setTriggerDeleteConfirmation(false);
}

const confirmDelete = async(id) => {
  const deletePosition = await JobPosition.delete(id);
  getLocationPosition();
  setTriggerDeleteConfirmation(false);
};

  return (
    <div className="p-4 border-2 mt-10 w-full md:max-w-lg">
      <div className="">
        <ConfirmationModal
          title="Delete Position"
          type="error"
          description="Are you sure you want to delete this position? You can also add it from the quick menu in the future."
          confirmText="Delete"
          cancelText="Cancel"
          confirm={confirmDelete}
          selectedId={positionId}
          openTrigger={triggerDeleteConfirmation}
          closeTrigger={closeDeleteConfirmation}
        />
        <div className="flex flex-row">
          <div>
            <h1 className="text-lg font-bold tracking-tight text-[#2C3E50]">
              Add New Position
            </h1>
          </div>
          <div className="pl-2">
            <button
              type="button"
              className="rounded-full bg-primary-500 p-1 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setShowAddNewPositionTab(!showAddNewPositionTab)}
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>{" "}
          </div>
        </div>
        <div
          className={` ${
            showAddNewPositionTab ? "" : "hidden"
          } animate-fade-in-from-top-.5s`}
        >
          <hr class="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700" />
          <div
            className={`-mx-3 md:flex mb-6 pl-3 pt-3 space-y-4 sm:space-y-0 sm:space-x-4`}
          >
            <button
              type="button"
              className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={showQuickAddPositionForm}
            >
              Add predefined position from our list
            </button>
            <button
              type="button"
              className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={enterNewPosition}
            >
              Enter New Custom Position
            </button>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div
              className={`md:w-1/2 h-full px-3 mb-6 md:mb-0 ${
                isQuickAddPositionVisible ? "" : "hidden"
              } animate-fade-in-from-top-.5s`}
            >
              <MultiSelector
                selectedValues={false}
                isMultiSelect={true}
                refresh={positionRefresh}
                placeholder="Select position title"
                dropDownData={positionList}
                disabled={""}
                callBackHandler={positionSelectionHandler}
              />

              <label
                className={`relative top-1 bottom-5 text-primary-500 font-semibold`}
              >
                <span className={`font-bold ${mode ? "text-black" : "text-white"}`}>Instructions</span>:
                <span className="pl-2 text-xs leading-5 text-gray-500">
                  Select the predefined position from the above drop down and
                  the details will be populated below. You can select multiple
                  positions.
                </span>
              </label>
            </div>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className={`
                    ${showAddPositionButton ? "" : "hidden"}
                    relative bottom-5 text-primary-500 font-semibold
                    transition-all animate-fade-in-from-top-1s`}
              >
                <span className={`font-bold ${mode ? "text-black" : "text-white"}`}>Instructions</span>:
                <span className="pl-2 text-xs leading-5 text-gray-500">
                  Fill in the position title, description and experience. Press
                  'add position' button to add to the quick menu drop down.
                </span>
              </label>
              <FormSuccessWithButton
                status={positionAddedNotification}
                title="Position Added!"
                content="It show show up in the list below"
              />
              <FloatingInputText
                disabled={!showAddPositionButton}
                className="my-3"
                id="PositionTitle"
                name="name"
                value={turnKeyPredefinedTitle}
                label="Position Title"
                placeholder="Enter title"
                type="text"
                onChange={(e) =>
                  collectTurnKeyData("turnKeyPredefinedTitle", e.target.value)
                }
              />
            </div>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block font-semibold text-gray-400 mb-2"
                htmlFor="grid-city"
              >
                Position Description
              </label>
              <FormTextarea
                disabled={!showAddPositionButton}
                placeholder="Brief description about the position. . ."
                callBack={capturePositionDescription}
                value={turnKeyPredefinedDescription}
                darmo={mode}
              />
            </div>
            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block font-semibold text-gray-400 mb-2"
                htmlFor="grid-city"
              >
                Requirements
              </label>
              <FormTextarea
                disabled={!showAddPositionButton}
                placeholder="Requirements . . ."
                callBack={capturePositionExperience}
                value={turnKeyPredefinedExperience}
                darmo={mode}
              />
            </div>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div
              className={`
                ${showAddPositionButton || predefinedValidation ? "" : "hidden"}
                md:w-1/2 px-3 mb-6 md:mb-0 `}
            >
              <GradientPrimaryButton
                grayScale={!predefinedValidation}
                disabled={!predefinedValidation}
                name="Add Position"
                onClick={handleSavePredefinedPosition}
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

      <div className="">
        <h1 className="text-lg font-bold tracking-tight text-[#2C3E50] ">
          Positions Added To Business
        </h1>
      </div>
      {businessExistingPosition && (
        <ul role="list" className="divide-y divide-gray-100">
          {businessExistingPosition.map((position) => (
            <li
              key={position.id}
              id={`position-id-${position.id}`}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-md font-semibold leading-6 text-gray-900">
                    {position.title}
                  </p>
                  <p
                    className={classNames(
                      "text-green-700 bg-green-50 ring-green-600/20",
                      "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                    )}
                  >
                    {position.hiring_status ? "Hiring" : "Not-Hiring"}
                  </p>
                </div>
                <div className="mt-1 items-center gap-x-2 text-[13px] leading-5 text-gray-500">
                  <div className="pb-2">
                    <span className="font-semibold text-gray-600">Type:</span>
                    <span className="pl-2">{position.availability}</span>
                  </div>
                  <div className="pb-2">
                    <span className="font-semibold text-gray-600">
                      Description:
                    </span>
                    <span className="pl-2">{position.description}</span>
                  </div>
                  <div>
                    <span className="font-semibold text-gray-600">
                      Requirements:
                    </span>
                    <span className="pl-2">{position.experience} </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <a
                  href="#remove"
                  key={position.id}
                  className="hidden rounded-md bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 border-2 border-red-600 shadow-sm hover:bg-red-600 hover:text-red-50 sm:block"
                  onClick={(event) => deletePosition(position.id)}
                >
                  Remove Position
                  <span className="sr-only">, {position.title}</span>
                </a>
                <Menu
                  as="div"
                  className="relative flex-none bottom-52 block sm:hidden"
                >
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-red-50 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#remove"
                            className="bg-red-50 text-red-600 justify-center border-red-600 hover:text-red-800 block px-3 py-1 text-sm leading-2"
                            onClick={(event) => deletePosition(position.id)}
                          >
                            Remove
                            <span className="sr-only">, {position.title}</span>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default PositionForm;