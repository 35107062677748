import { HomeIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useParams } from "react-router-dom";
import { HomeSvg } from "../../../assets/images";
import RoutesList from "../../../routes";
import { useSelector } from "react-redux";

const pages = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];

export default function BreadCrumb({ darmo }) {
  const location = useLocation();
  const pathName = location.pathname;
  const params = useParams();
  const crumbs = RoutesList.filter(({ path }) => pathName.includes(path)).map(
    ({ path, ...rest }) => ({
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            path
          )
        : path,
      ...rest,
    })
  );

  if (crumbs.length <= 0) {
    return null;
  }

  return (
    <nav
      className="flex mx-auto max-w-7xl sm:px-6 lg:px-5"
      aria-label="Breadcrumb"
    >
      <ol role="list" className="flex items-center space-x-1">
        <li>
          <div>
            <Link
              to="/dashboard"
              className={`hover:text-gray-500 ${
                darmo ? "text-gray-600" : "text-white"
              }`}
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {crumbs.map((crumb) => (
          <li key={crumb.name}>
            <div className="flex items-center">
              <svg
                className={`h-5 w-5 flex-shrink-0 ${
                  darmo ? "text-gray-600" : "text-white"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                to={crumb.path}
                className={`ml-1 text-sm font-medium ${
                  darmo ? "text-gray-600" : "text-white"
                } hover:text-gray-500`}
                aria-current={crumb.current ? "page" : undefined}
              >
                {crumb.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
