import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardTable from "../../../components/molecules/DashboardTable";
import JobOpeningCard from "../../../components/molecules/JobOpeningCard";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectSelectedLocation,
  darkmode,
  chatList,
  isChatListExpand,
} from "../../../store/authSlice";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils";
import DashboardApi from "../../../api/Dashboard";
import User from "../../../api/User";
import {
  addIcon,
  applicantBlueIcon,
  defaultUserImage,
  openPositionsIcon,
  profileNewBlackIcon,
  applicantIcon,
  hiredBlackIcon,
  openPositionsBlackIcon,
} from "../../../assets/images";
import { encodeIds } from "../../../utils";
import Illustrator from "../../../components/atoms/Illustrator/Illustrator";
import BusinessAnalyticsDashboard from "../../../api/BusinessAnalyticsDashboad";
import CountUp from "react-countup";
import "../ViewJob/ViewJob.scss";
import ShiftJobCard from "../../../components/molecules/ShiftJobCard/ShiftJobCard";
import EventJobCard from "../../../components/molecules/EventJobCard/EventJobCard";
import RBAC from "../../../api/RBAC";

const CurrentJobOpenings = ({ mode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [jobPositions, setJobPositions] = useState([]);
  const [isPositionCreated, setPositionCreated] = useState(false);
  const [isDraftsView, setDraftsView] = useState(false);
  const [isPositionArchived, setPositionArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const openChatList = useSelector(chatList);
  const isChatListOpen = useSelector(isChatListExpand);

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  const checkPositionCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkDraftsView = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position_drafts",
        operations: "view",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setDraftsView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionArchive = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "archive",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionArchived(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    const timer = setTimeout(() => {
      loadJobPositions();
      // checkPositionCreate();
      // checkDraftsView();
      // checkPositionArchive();
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  return (
    <div className="pb-5 mx-auto">
      {jobPositions.length > 0 ? (
        <div>
          <div
            className={`flex flex-col lg:flex-row md:flex-row sm:flex-row mt-8 ${
              openChatList ? "" : "xl:w-full"
            } ${isChatListOpen ? "xl:w-3/4" : "xl:w-5/6"}`}
          >
            <div className="flex flex-col">
              <h1
                className={`text-lg font-extrabold tracking-tight ${
                  mode ? "text-[#2C3E50]" : "text-white"
                }`}
              >
                Current Job Openings{" "}
              </h1>
              <span
                className={`text-base font-extrabold mt-1 ${
                  mode ? "text-primary-500" : "text-white"
                } leading-none`}
              >
                ({jobPositions.length}) Open Positions
              </span>
            </div>

            <div className="mt-4 lg:mt-0 flex mb-2 ml-auto">
              {/* {isDraftsView &&   */}
              {/* <button
                onClick={() => navigate("/job-positions/draft")}
                className={
                  "text-sm mr-2 font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                }
              >
                Saved Drafts
              </button> */}
              {/* }   */}
              {/* {isPositionCreated && */}
              <button
                onClick={() => navigate("/job-positions/add")}
                className={
                  "text-sm font-bold px-4 mx-auto rounded-full bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 h-9"
                }
              >
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="logo"
                    className="mr-2 my-auto w-4 h-4"
                  />
                  Add Position
                </div>
              </button>
              {/* } */}
            </div>
          </div>

          <div
            className={`my-4 bg-[#F6F6F6] py-3 px-3 rounded-2xl flex flex-col gap-3 ${
              openChatList ? "" : "xl:w-full"
            } ${isChatListOpen ? "xl:w-3/4" : "xl:w-5/6"}`}
          >
            {loading ? (
              <Loading size={"5xl"} count={6} />
            ) : (
              jobPositions.map((jb, i) => (
                <JobOpeningCard
                  key={i}
                  jobPosition={jb}
                  loadJobs={loadJobPositions}
                  // isArchive={isPositionArchived}
                  darmo={true}
                />
              ))
            )}
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-1 flex-col justify-center items-center mt-20 ${
            mode ? "text-black" : "text-white"
          }`}
        >
          <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
            No Job Posted Yet!
          </h5>
          {/* {isPositionCreated && */}
          <>
            <span className="my-2 text-base font-medium">
              Add a new position to get started.
            </span>
            <div className="flex mt-2">
              {/* {isDraftsView &&   */}
              {/* <button
                onClick={() => navigate("/job-positions/draft")}
                className={
                  "text-sm mr-2 font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                }
              >
                Saved Drafts
              </button> */}
              {/* } */}
              <button
                onClick={() => navigate("/job-positions/add")}
                className={
                  "font-bold text-sm px-6 mx-auto rounded-xl bg-primary-500 text-white hover:bg-primary-400 py-2 transition-all hover:opacity-50"
                }
              >
                Add Position
              </button>
            </div>
          </>
          {/* } */}
        </div>
      )}
    </div>
  );
};

const CurrentShiftsOpenings = ({ mode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [jobPositions, setJobPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const openChatList = useSelector(chatList);
  const isChatListOpen = useSelector(isChatListExpand);

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    const timer = setTimeout(() => {
      loadJobPositions();
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  return (
    <div className="lg:mt-4 pb-4 mx-auto">
      {jobPositions.length > 0 ? (
        <div className="m-auto">
          <div
            className={`flex flex-col lg:flex-row md:flex-row sm:flex-row ${
              openChatList ? "" : "xl:w-full"
            } ${isChatListOpen ? "xl:w-2/3" : "xl:w-5/6"}`}
          >
            <div className="flex flex-col">
              <h1
                className={`text-lg font-extrabold tracking-tight ${
                  mode ? "text-[#2C3E50]" : "text-white"
                }`}
              >
                Current Shift Openings{" "}
              </h1>
              <span
                className={`text-base font-extrabold mt-1 ${
                  mode ? "text-orange-400" : "text-white"
                } leading-none`}
              >
                ({jobPositions.length}) Open Shifts
              </span>
            </div>

            <div className="mt-4 lg:mt-0 flex mb-2 ml-auto">
              {/* <button
                onClick={() => navigate("/job-positions/draft")}
                className={
                  "text-sm mr-2 font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                }
              >
                Saved Drafts
              </button> */}
              <button
                onClick={() => navigate("/shifts")}
                className={
                  "text-sm font-bold px-6 mx-auto rounded-xl bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50 h-9"
                }
              >
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="logo"
                    className="mr-2 my-auto w-4 h-4"
                  />
                  Add Shift
                </div>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 mt-4">
            {/* {loading ? (
              <Loading size={"5xl"} count={6} />
            ) : ( */}
            {jobPositions?.map((jb, i) => (
              <ShiftJobCard
                key={i}
                jobPosition={jb}
                loadJobs={loadJobPositions}
                darmo={true}
              />
            ))}
            {/* )} */}
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-1 flex-col justify-center items-center mt-20 ${
            mode ? "text-black" : "text-white"
          }`}
        >
          <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
            No Shift Posted Yet!
          </h5>
          <span className="my-2 text-base font-medium">
            Add a new shift to get started.
          </span>
          <button
            onClick={() => navigate("/job-positions/add")}
            className={
              "mt-2 font-bold text-sm px-6 mx-auto rounded-xl bg-primary-500 text-white hover:bg-primary-400 py-2 transition-all hover:opacity-50"
            }
          >
            Add Shift
          </button>
        </div>
      )}
    </div>
  );
};

const CurrentEventsOpenings = ({ mode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [jobPositions, setJobPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const openChatList = useSelector(chatList);
  const isChatListOpen = useSelector(isChatListExpand);

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    const timer = setTimeout(() => {
      loadJobPositions();
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  return (
    <div className="lg:mt-4 pb-4 mx-auto">
      {jobPositions.length > 0 ? (
        <div className="m-auto">
          <div
            className={`flex flex-col lg:flex-row md:flex-row sm:flex-row ${
              openChatList ? "" : "xl:w-full"
            } ${isChatListOpen ? "xl:w-2/3" : "xl:w-5/6"}`}
          >
            <div className="flex flex-col">
              <h1
                className={`text-lg font-extrabold tracking-tight ${
                  mode ? "text-[#2C3E50]" : "text-white"
                }`}
              >
                Current Event (Shifts) Openings{" "}
              </h1>
              <span
                className={`text-base font-extrabold mt-1 ${
                  mode ? "text-primary-500" : "text-white"
                } leading-none`}
              >
                ({jobPositions.length}) Open Events
              </span>
            </div>
          </div>

          {/* <div className={`my-4 ${openChatList ? "" : "xl:w-full"} ${isChatListOpen ? "xl:w-2/3" : "xl:w-5/6"}`}> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 mt-4">
            {/* {loading ? (
              <Loading size={"5xl"} count={6} />
            ) : ( */}
            {jobPositions?.map((jb, i) => (
              <EventJobCard
                className="cursor-pointer"
                key={i}
                jobPosition={jb}
                loadJobs={loadJobPositions}
                darmo={true}
              />
            ))}
            {/* )} */}
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-1 flex-col justify-center items-center mt-20 ${
            mode ? "text-black" : "text-white"
          }`}
        >
          <h5 className={`leading-6 ${mode ? "text-black" : "text-white"}`}>
            No Event Posted Yet!
          </h5>
          <span className="my-2 text-base font-medium">
            Add a new event to get started.
          </span>
        </div>
      )}
    </div>
  );
};

const BusinessDashboard = () => {
  const { t } = useTranslation();
  const [recentApplications, setRecentApplications] = useState([]);
  const [subUsers, setSubUsers] = useState([]);
  const [analytics, setAnalytics] = useState(false);
  const [analyticsLoading, setAnalyticsLoading] = useState(true);
  const [totalScans, setTotalScans] = useState(0);
  const [openJobs, setOpenJobs] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [hiredApplicants, setHiredApplicants] = useState(0);
  const [analyticsData, setAnalyticsData] = useState([]);

  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const isChatListOpen = useSelector(isChatListExpand);
  const openChatList = useSelector(chatList);

  useEffect(() => {
    setAnalyticsLoading(false);
  }, [analyticsData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      loadSubUsers();
      loadRecentApplications();
      if (selectedLocation?.include_analytics) {
        setAnalyticsLoading(true);
        setAnalytics(true);
        getBusinessAnalytics(selectedLocation);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedLocation]);

  const getBusinessAnalytics = (selectedLocation) => {
    BusinessAnalyticsDashboard.getBusinessAnalytics(selectedLocation?.id).then(
      (response) => {
        const totalScansResponse = response.data.data.totalScans;
        const openJobsResponse = response.data.data.openJobs;
        const applicantsResponse = response.data.data.applicantsCount;
        const hiredApplicantsResponse = response.data.data.hiredApplicantsCount;

        setTotalScans(totalScansResponse);
        setOpenJobs(openJobsResponse);
        setApplicantsCount(applicantsResponse);
        setHiredApplicants(hiredApplicantsResponse);

        const incentives = [
          {
            name: "Profile Views",
            value: <CountUp start={0} end={totalScansResponse} />,
            icon: (
              <img
                src={profileNewBlackIcon}
                alt="logo"
                className="w-auto h-6 flex-shrink-0 animate-fade-in-from-top-.1s"
              />
            ),
          },
          {
            name: "Open Positions",
            value: <CountUp start={0} end={openJobsResponse} />,
            icon: (
              <img
                src={openPositionsBlackIcon}
                alt="logo"
                className="w-auto h-6 flex-shrink-0 animate-fade-in-from-top-.1s"
              />
            ),
          },
          {
            name: "Applications",
            value: <CountUp start={0} end={applicantsResponse} />,
            icon: (
              <img
                src={applicantIcon}
                alt="logo"
                className="w-auto h-6 flex-shrink-0 animate-fade-in-from-top-.1s"
              />
            ),
          },
          {
            name: "Total Hired",
            value: <CountUp start={0} end={hiredApplicantsResponse} />,
            icon: (
              <img
                src={hiredBlackIcon}
                alt="logo"
                className="w-auto h-6 flex-shrink-0 animate-fade-in-from-top-.1s"
              />
            ),
          },
        ];

        setAnalyticsData(incentives);
      }
    );
  };

  const filterSubUsers = (allUsers) => {
    const _tempUsers = allUsers.filter(
      (item) => item?.location_id === selectedLocation?.id
    );
    setSubUsers(_tempUsers);
  };

  const filterApplications = (allRecentApplications) => {
    const _selectedLocation = selectedLocation
      ? selectedLocation
      : user?.location;
    const _tempApplications = allRecentApplications.filter(
      (item) => item?.location_id === _selectedLocation?.id
    );
    setRecentApplications(_tempApplications);
  };

  const loadRecentApplications = async () => {
    try {
      const response = await DashboardApi.getRecentApplications();
      filterApplications(response.data.data);
    } catch (error) {
      console.error("Error loading recent applications:", error);
    }
  };

  const loadSubUsers = async () => {
    const response = await User.getAll();
    const subU = response.data.data.filter(
      (e) => e?.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
    );
    filterSubUsers(subU);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="mx-auto">
        <div>
          <h1
            // className={`text-lg font-bold tracking-tight ${
            //   mode ? "text-[#2C3E50]" : "text-white"
            // }`}
            className={`text-lg font-extrabold tracking-tight`}
          >
            {selectedLocation?.name && user?.company?.is_enterprise !== 0
              ? "Location Dashboard"
              : "Your Dashboard"}
          </h1>
        </div>

        {!analyticsLoading && analytics && (
          <Illustrator
            header={"Insights about your profile and job analytics"}
            data={analyticsData}
          />
        )}
        <div className="mt-4 grid grid-cols-1 gap-4">
          <div
            className={`lg:overflow-hidden h-full mx-2 md:mx-0 ${
              openChatList ? "" : "xl:w-full"
            } ${isChatListOpen ? "xl:w-3/4" : "xl:w-5/6"}`}
          >
            <DashboardTable
              title={t("dashboard.latest-application-submissions")}
              headers={
                recentApplications?.length > 0
                  ? [
                      t("common.full-name"),
                      "Applied For",
                      t("dashboard.applied-on"),
                    ]
                  : ["No Applicants Yet"]
              }
              mode={true}
            >
              {recentApplications &&
                recentApplications?.slice(0, 5).map((recentApplication, i) => (
                  <tr
                    key={i}
                    className="text-black hover:cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/business/applicant/${encodeIds(
                          recentApplication.job_id
                        )}/${encodeIds(recentApplication.job_seeker_id)}`
                      )
                    }
                  >
                    <td colspan="3" className="p-0">
                      <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                        <div className="flex flex-row">
                          <div className="flex-1 flex my-auto">
                            <img
                              className="rounded-full mr-2 w-6 h-6 my-auto"
                              src={
                                recentApplication?.seeker?.photo?.thumb_url ||
                                defaultUserImage
                              }
                              alt="Profile"
                            />
                            <span>
                              {recentApplication?.seeker?.first_name}{" "}
                              {recentApplication?.seeker?.last_name}
                            </span>
                          </div>
                          <div className="flex-1 text-center my-auto">
                            {recentApplication?.position?.title}
                          </div>
                          <div className="flex-1 text-right my-auto">
                            {dayjs(recentApplication?.created_at).format(
                              DateFormat
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </DashboardTable>
          </div>
        </div>
      </div>

      <CurrentJobOpenings mode={true} />
      {/* <CurrentShiftsOpenings mode={true}/>
      <CurrentEventsOpenings mode={true}/> */}
    </DashboardLayout>
  );
};

export default BusinessDashboard;
