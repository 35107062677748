import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";

import { DashboardLayout } from "../../components/organisms/Layout";
import TableActionDropdown from "../../components/TableActionDropdown";
// import { Button } from "antd";
import QRCodes from "../../api/Qrcode";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { QRcodeSample } from "../../assets/images";
import Loading from "../../components/Loading";
import PaginationView from "../../components/molecules/Pagination";
import { DateFormat } from "../../utils";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../store/authSlice";
import RBAC from "../../api/RBAC";

const QRCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const [datas, setDatas] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "staging.heyhire.app"
    : "heyhire.app";
  const [loading, setLoading] = useState(false);
  const [isQRCreated, setQRCreated] = useState(false);
  const [isQRUpdate, setQRUpdate] = useState(false);
  const [isQRDelete, setQRDelete] = useState(false);

  const loadQRCodes = async () => {
    setLoading(true);
    try {
      const qrs = await QRCodes.getAll();
      setDatas(qrs.data.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Api Error!!");
      }
      setLoading(false);
    }
  };

  const downloadQR = (uuid) => {
    fetch(
      `https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${uuid}`,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "qr-code.jpg");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkQrCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "qr_codes",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setQRCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkQrEdit = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "qr_codes",
        operations: "update",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setQRUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkQrDelete = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "qr_codes",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setQRDelete(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Manage QR Codes";
    loadQRCodes();
    // checkQrCreate();
    // checkQrDelete();
    // checkQrEdit();
  }, [selectedLocation]);

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-2xl">{t("qrcode.manage-qrcode")}</h2>
          {/* {isQRCreated &&  */}
          <Link to={"add"}>
            <button className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50">
              <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
            </button>
          </Link>
          {/* } */}
        </div>

        <div className="overflow-x-auto overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 mt-6 text-sm lg:text-[12px]">
            <thead className="bg-gray-900 text-white whitespace-nowrap">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  QR Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Target
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Date Created
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  Click Code to Download
                </th>
                {/* {(isQRDelete || isQRUpdate) && */}
                <th
                  scope="col"
                  className="px-6 py-3 text-right font-bold tracking-wider"
                >
                  {t("common.action")}
                </th>
                {/* } */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {!loading &&
                datas.map((qrcode, i) => {
                  return (
                    <tr key={i} className="cursor-pointer hover:bg-[#f6f6f6]">
                      <td className="px-6 py-4 whitespace-nowrap">
                        {qrcode?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="font-semibold mr-2">
                          {qrcode?.entity_type}
                        </span>
                        {"->"}
                        <span className="ml-2">
                          {qrcode?.entity_type === "brand"
                            ? qrcode?.brand?.name
                            : qrcode?.entity_type === "location"
                            ? qrcode?.location?.name
                            : qrcode?.name}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {dayjs(qrcode?.created_at).format(DateFormat)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img
                          width={50}
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${qrcode?.uuid}`}
                          alt="alt"
                          onClick={() => downloadQR(qrcode?.uuid)}
                        />
                      </td>
                      {/* {(isQRUpdate || isQRDelete) && */}
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <TableActionDropdown
                          onClickEdit={() =>
                            navigate(`/business/qr-code/${qrcode.id}/edit`)
                          }
                          onClickDelete={() => {
                            // setLocationToDelete(location.id);
                          }}
                          // isDelete={isQRDelete}
                          // isUpdate={isQRUpdate}
                          // isChanged={true}
                        />
                      </td>
                      {/* } */}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {loading && <Loading count={8} />}

          {/* {!loading && <PaginationView current={metaData?.current_page} next={metaData?.to} pageSize={metaData?.per_page} total={metaData?.total} onChange={handlecPageChange}/>} */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default QRCode;
