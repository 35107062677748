import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import User from "../../../api/User";
import { toast } from "react-toastify";
import { DropActionDropdown } from "../../../components/TableActionDropdown";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import useFormatPhoneNumber from "../../../hooks/FormatPhoneNumber";
import PaginationView from "../../../components/molecules/Pagination";
import useQuery from "../../../hooks/useQuery";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectRoles,
  selectSelectedLocation,
} from "../../../store/authSlice";
import RBAC from "../../../api/RBAC";

const ManageUsers = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const formatPhoneNumber = useFormatPhoneNumber;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const currentUser = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const isBusinessOwner = currentUser?.company?.is_enterprise === 0;
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [isSubUserCreate, setSubUserCreate] = useState(false);
  const [isSubUserEdit, setSubUserEdit] = useState(false);
  const [isSubUserDelete, setSubUserDelete] = useState(false);
  const [isSubUserSuspend, setSubUserSuspend] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Users & Permissions";
    // checkAddSubUser();
    // checkEditSubUser();
    // checkDeleteSubUser();
    // checkSuspendSubUser();
  }, [selectedLocation]);

  const checkAddSubUser = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "sub_users",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setSubUserCreate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkEditSubUser = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "sub_users",
        operations: "update",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setSubUserEdit(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkDeleteSubUser = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "sub_users",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setSubUserDelete(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkSuspendSubUser = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "sub_users",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setSubUserSuspend(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [type, query]);

  const loadUsers = async () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const sortData = sort ? `sort=${sort}&` : "";
    const limit = query.get("limit") || pageLimit;
    const queryData = sortData + page + `&limit=${limit}`;
    try {
      const response = await User.getAll(queryData);
      const _users = response.data.data?.filter(
        (i) => i?.id !== currentUser?.id
      );
      let userData = _users;
      if (isBusinessOwner) {
        // filter
        userData = _users.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
      } else {
        userData = filterUsers(userData);
      }
      setUsers(userData);
      setMetaData(response.data.meta);
      setLoading(false);
    } catch (error) {
      toast.error("Error trying to load users. Please try again.");
      setLoading(false);
    }
  };

  const filterUsers = (userList) => {
    let tempList = [];
    switch (roles[0]) {
      case "regional_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
        break;
      case "location_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name === "employee" &&
            e?.roles[0]?.name !== "super-user"
        );
        break;
      case "owner":
        if (type == "Sub Users") {
          tempList = userList.filter(
            (e) =>
              e.roles[0]?.name !== "regional_manager" &&
              e.roles[0]?.name !== "owner" &&
              e?.roles[0]?.name !== "super-user"
          );
        } else {
          tempList = userList.filter((e) => e?.roles[0]?.name !== "super-user");
        }
        break;
      default:
        tempList = userList;
        break;
    }
    return tempList;
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const deleteUser = (userId) => {
    setLoading(true);
    User.deleteUser(userId)
      .then(() => {
        const dataCopy = users.slice();
        dataCopy.splice(
          users.findIndex((item) => item?.id === userId),
          1
        );
        setUsers(dataCopy);
        setLoading(false);
        toast.success("User Deleted.");
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status !== 403) {
          toast.error("Error trying to delete users. Please try again.");
        }
      })
      .then(() => setLoading(false));
  };

  const suspendUser = (userId) => {
    setLoading(true);
    User.suspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = new Date();
          }
        });
        setUsers(dataCopy);
        toast.success("User Suspended.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Error trying to suspend users. Please try again.");
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const unSuspendUser = (userId) => {
    setLoading(true);
    User.unSuspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = null;
          }
        });
        setUsers(dataCopy);
        toast.success("User UnSuspended.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Error trying to unsuspend users. Please try again.");
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg lg:text-2xl">
            {isBusinessOwner
              ? "Manage Sub Users"
              : type || "Manage " + t("business-users.business-users")}
          </h2>

          {/* {isSubUserCreate &&  */}
          <button
            onClick={() => {
              navigate("add");
            }}
            className="flex w-28 h-9 justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
          >
            <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
          </button>
          {/* } */}
        </div>

        <div className="overflow-x-auto overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 mt-6 text-sm lg:text-[12px]">
            <thead className="bg-gray-900 text-white">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.full-name")}
                </th>
                {type === "Managers" ? null : (
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-bold tracking-wider"
                  >
                    {t("common.user-type")}
                  </th>
                )}
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.email")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.phone")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.location")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.last-login")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.status")}
                </th>
                {/* {(isSubUserEdit || isSubUserDelete || isSubUserSuspend) && */}
                <th
                  scope="col"
                  className="px-6 py-3 text-right font-bold tracking-wider"
                >
                  {t("common.action")}
                </th>
                {/* } */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {!loading &&
                users.map((user) => {
                  return (
                    <tr
                      key={user.id}
                      className={`hover:bg-[#f6f6f6] ${
                        user?.roles[0]?.name === "owner" &&
                        currentUser?.roles[0]?.name === "owner"
                          ? "bg-gray-300"
                          : "white"
                      }`}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.first_name} {user.last_name}
                      </td>
                      {type === "Managers" ? null : (
                        <td className="px-6 py-4 whitespace-nowrap capitalize">
                          {user.roles &&
                            user.roles.length > 0 &&
                            user.roles[0].name.replace("_", " ")}
                        </td>
                      )}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.email}
                      </td>
                      <td>
                        <div className="tbl-cell">
                          {formatPhoneNumber(user.phone)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user?.location.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.last_login || "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user?.suspended_at ? (
                          <div className="h-6 pt-0 rounded border border-warmgray-200 bg-red-600 items-center justify-center flex">
                            <span className="text-white text-xs px-2 text-center font-bold">
                              Suspended
                            </span>
                          </div>
                        ) : (
                          <div className="h-6 pt-0 rounded border border-warmgray-200 bg-green-600 items-center justify-center flex">
                            <span className="text-white text-xs px-2 text-center font-bold">
                              Active
                            </span>
                          </div>
                        )}
                      </td>
                      {currentUser?.id == user?.id ? (
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <DropActionDropdown
                            className="ml-5"
                            icon="far fa-ellipsis-h"
                            iconColor="text-primary-500"
                            menus={[
                              {
                                label: "Edit",
                                action: () =>
                                  navigate(`${path}/edit/${user?.id}`),
                              },
                            ]}
                          />
                        </td>
                      ) : (
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          {/* {(isSubUserEdit || isSubUserDelete || isSubUserSuspend) && */}
                          <DropActionDropdown
                            className="ml-5"
                            icon="far fa-ellipsis-h"
                            iconColor="text-primary-500"
                            menus={
                              currentUser?.roles[0]?.name === "owner" &&
                              user?.roles[0]?.name === "owner"
                                ? [
                                    {
                                      label: "Edit",
                                      action: () =>
                                        navigate(`${path}/edit/${user?.id}`),
                                    },
                                  ]
                                : [
                                    {
                                      label: "Edit",
                                      action: () =>
                                        navigate(`${path}/edit/${user?.id}`),
                                    },
                                    {
                                      label: "Delete",
                                      action: () => deleteUser(user?.id),
                                    },
                                    {
                                      label: "Suspend",
                                      action: () => suspendUser(user?.id),
                                      disabled: !!user?.suspended_at,
                                    },
                                    {
                                      label: "Unsuspend",
                                      action: () => unSuspendUser(user?.id),
                                      disabled: !user?.suspended_at,
                                    },
                                  ]
                            }
                            // isUpdate={isSubUserEdit}
                            // isDelete={isSubUserDelete}
                            // isSuspend={isSubUserSuspend}
                            // isChanged={true}
                          />
                          {/* } */}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && <Loading />}
          <div className="flex w-full h-20 mt-2 justify-end items-center">
            {!loading && (
              <PaginationView
                current={metaData?.current_page}
                next={metaData?.to}
                pageSize={metaData?.per_page}
                total={users?.length}
                onChange={handlecPageChange}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ManageUsers;
