import React from "react";
import { Switch } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { setBackgroundcolor, darkmode } from "../../../store/authSlice";
import { useLocation } from "react-router-dom";
import { heyhireIcon } from "../../../assets/images";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuthLayout = ({ children, restrictSwitch = false }) => {
  const dispatch = useDispatch();
  const mode = useSelector(darkmode);
  const location = useLocation();

  const HandleBg = () => {
    dispatch(setBackgroundcolor(!mode));
  };

  return (
    <div
      className={`relative w-full min-h-full overflow-x-auto z-auto ${
        mode ? "bg-white" : "bg-midnight"
      }`}
    >
      <div className="h-screen flex flex-col">
        {location?.pathname !== "/login" && (
          <div
            id="navbar"
            className="navbar top-0 w-full bg-midnight flex justify-between align-middle px-2 md:px-4 lg:px-6 z-[1] rounded-b-xl"
          >
            <div className="mx-4 flex w-full">
              <div className="flex justify-start items-center ml-4 cursor-pointer">
                <img width={100} src={heyhireIcon} alt="heyhire" />
              </div>

              {!restrictSwitch && (
                <div className="flex justify-end items-center w-full">
                  <p className="text-white text-xs font-bold my-auto mr-3">
                    {mode ? "Turn on Dark Mode" : "Turn on Light Mode"}
                  </p>
                  <Switch
                    checked={mode}
                    onChange={HandleBg}
                    className={classNames(
                      mode ? "bg-primary-500" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      className={classNames(
                        mode
                          ? "translate-x-0 bg-white"
                          : "translate-x-5 bg-midnight",
                        "pointer-events-none relative inline-block h-5 w-5 transform rounded-full  shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    >
                      <span
                        className={classNames(
                          mode
                            ? "opacity-100 duration-200 ease-in"
                            : "opacity-0 duration-100 ease-out",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <svg
                          fill="#000000"
                          width="800px"
                          height="1em"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M 15 3 L 15 8 L 17 8 L 17 3 Z M 7.5 6.09375 L 6.09375 7.5 L 9.625 11.0625 L 11.0625 9.625 Z M 24.5 6.09375 L 20.9375 9.625 L 22.375 11.0625 L 25.90625 7.5 Z M 16 9 C 12.144531 9 9 12.144531 9 16 C 9 19.855469 12.144531 23 16 23 C 19.855469 23 23 19.855469 23 16 C 23 12.144531 19.855469 9 16 9 Z M 16 11 C 18.773438 11 21 13.226563 21 16 C 21 18.773438 18.773438 21 16 21 C 13.226563 21 11 18.773438 11 16 C 11 13.226563 13.226563 11 16 11 Z M 3 15 L 3 17 L 8 17 L 8 15 Z M 24 15 L 24 17 L 29 17 L 29 15 Z M 9.625 20.9375 L 6.09375 24.5 L 7.5 25.90625 L 11.0625 22.375 Z M 22.375 20.9375 L 20.9375 22.375 L 24.5 25.90625 L 25.90625 24.5 Z M 15 24 L 15 29 L 17 29 L 17 24 Z" />
                        </svg>
                      </span>
                      <span
                        className={classNames(
                          mode
                            ? "opacity-0 duration-100 ease-out"
                            : "opacity-100 duration-200 ease-in",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path
                            d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </span>
                  </Switch>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex h-full overflow-y-auto flex-col">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
