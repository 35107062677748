import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Input from "../components/form/Input";
import { toast } from "react-toastify";
import JobSeeker from "../api/JobSeeker";
import { DashboardLayout } from "../components/organisms/Layout";
import useFormatPhoneNumber from "../hooks/FormatPhoneNumber";
import { decodeIds } from "../utils";

const AddJobSeeker = () => {
  const { t } = useTranslation();
  const formatPhoneNumber = useFormatPhoneNumber;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const editMode = location.pathname.search("/job-seekers/edit") !== -1;
  const seekerId = decodeIds(params.id);

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit Job Seeker";
      loadSeeker();
    } else {
      document.title = "HeyHire Portal-Add New Job Seeker";
    }
  }, []);

  const loadSeeker = () => {
    JobSeeker.getById(seekerId)
      .then((response) => {
        const _seeker = response.data.data;
        setFirstName(_seeker.first_name);
        setLastName(_seeker.last_name);
        setPhone(_seeker.phone);
        setEmail(_seeker.email);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const editSeeker = () => {
    const body = {
      email: email,
      phone: phone,
    };
    JobSeeker.editSeeker(seekerId, body)
      .then((response) => {
        toast.success("Job Seeker Edited successfully.");
        navigate("/job-seekers");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong. Please try again later");
        }
      });
  };

  return (
    <DashboardLayout darmo={true} className="w-full">
      <h2 className={"text-2xl font-bold"}>
        <Link to={"/job-seekers"}>
          <i className="far fa-chevron-left text-xl mr-2" />
        </Link>{" "}
        {editMode
          ? t("job-seekers.edit-a-job-seeker")
          : t("job-seekers.add-a-job-seeker")}
      </h2>
      <hr className="mt-4" />

      <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <Input
            darmo={true}
            name="first_name"
            label={t("common.first-name")}
            type="text"
            value={firstName}
            placeholder={t("common.first-name")}
            error={
              validationErrors && validationErrors.first_name
                ? validationErrors.first_name[0]
                : null
            }
            onChange={(e) => setFirstName(e.target.value)}
            disabled={editMode}
          />

          <Input
            darmo={true}
            name="last_name"
            label={t("common.last-name")}
            type="text"
            value={lastName}
            placeholder={t("common.last-name")}
            error={
              validationErrors && validationErrors.last_name
                ? validationErrors.last_name[0]
                : null
            }
            onChange={(e) => setLastName(e.target.value)}
            disabled={editMode}
          />

          {!editMode && (
            <Input
              darmo={true}
              name="job_position"
              label={t("common.job-position")}
              type="text"
              value={jobPosition}
              placeholder={t("common.job-position")}
              error={
                validationErrors && validationErrors.job_position
                  ? validationErrors.job_position[0]
                  : null
              }
              onChange={(e) => setJobPosition(e.target.value)}
            />
          )}

          {!editMode && (
            <Input
              darmo={true}
              name="company"
              label={t("common.company")}
              type="text"
              value={companyName}
              placeholder={t("common.company")}
              error={
                validationErrors && validationErrors.company
                  ? validationErrors.company[0]
                  : null
              }
              onChange={(e) => setCompanyName(e.target.value)}
            />
          )}

          <Input
            darmo={true}
            name="phone"
            label={t("common.phone")}
            type="text"
            value={formatPhoneNumber(phone)}
            placeholder={t("common.phone-example")}
            error={
              validationErrors && validationErrors.phone
                ? validationErrors.phone[0]
                : null
            }
            onChange={(e) => setPhone(e.target.value)}
          />

          <Input
            darmo={true}
            name="email"
            label={t("common.email")}
            type="text"
            value={email}
            placeholder={t("common.example-email")}
            error={
              validationErrors && validationErrors.email
                ? validationErrors.email[0]
                : null
            }
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <hr className="my-8" />

        <div className="flex w-full md:w-1/5">
          <button
            className="w-full rounded-xl p-2 m-1 text-white text-lg bg-primary-500 hover:bg-primary-700 hover:opacity-50"
            onClick={(e) => {
              e.preventDefault();
              editSeeker();
            }}
            disabled={loading}
          >
            {t("common.save")}
            {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
          </button>
        </div>

        <div className="text-center mt-5">
          <button>{t("click-here-to-save-as-draft")}</button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddJobSeeker;
