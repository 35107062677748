import { useEffect } from "react";
import { useState } from "react";

const SimpleTable = ({
  data = [],
  title = "",
  content = "",
  showButton = true,
  buttonLabel = "button",
  showActionButton = true,
  actionButtonName = "action",
  actionButtonUrl = "#default",
  onClickButton,
  header = [],
}) => {
  const [rowLength, setRowLength] = useState(0);

  useEffect(() => {
    setRowLength(Array.from({ length: header.length }, (_, i) => i + 1));
  }, []);

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold leading-6 text-gray-900 border-b border-warmgray-300 pb-2">
            {title}
          </h1>
          <p className="mt-2 text-[13px] text-gray-600">{content}</p>
        </div>
        {showButton && (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-primary-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700"
              onClick={onClickButton}
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
      <div className="mt-2 flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle lg:px-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {header.map((item, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-[13px] font-semibold text-gray-900 sm:pl-3 whitespace-nowrap"
                    >
                      {item.name}
                    </th>
                  ))}
                  {showActionButton && (
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">{actionButtonName}</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? undefined : "bg-gray-50"}
                  >
                    {rowLength?.map((rowIndex) => (
                      <td
                        key={rowIndex}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-[13px] font-base text-gray-900 sm:pl-3"
                      >
                        {item[rowIndex]}
                      </td>
                    ))}

                      {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[13px] font-base text-gray-900 sm:pl-3">
                        {item.id}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[13px] font-base text-gray-900 sm:pl-3">
                        {item.amount_due}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[13px] font-base text-gray-900 sm:pl-3">
                        <a href={item.invoice_pdf}>click</a>
                      </td> */}

                    {showActionButton && (
                      <td
                        key={rowLength.length + 1}
                        className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3"
                      >
                        <a
                          href={actionButtonUrl + item[rowLength.length + 1]}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          {actionButtonName}
                          <span className="sr-only">
                            , {data.stripe_card_id}
                          </span>
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;
