import React, { useState } from "react";
import { Form, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import FormInput from "../../components/atoms/FormInput";
// import FormInputWithSuffix from "../../components/atoms/FormInputWithSuffix";
import FormSelect from "../../components/atoms/FormSelect";

import { DashboardLayout } from "../../components/organisms/Layout";
import { QRcodeSample } from "../../assets/images";
import { createQRcodeInitialValues, createQRcodeSchema } from "./schema";
import myAxios from "../../api/api";
import Qrcodes from "../../api/Qrcode";
import { toast } from "react-toastify";
import { useEffect } from "react";
import QRCodes from "../../api/Qrcode";

const targets = [
  { label: "Company", value: "company" },
  { label: "Location", value: "location" },
  { label: "Brand", value: "brand" },
  { label: "Region", value: "region" },
  { label: "Job Position", value: "job-position" },
];
const CreateQRCode = () => {
  const [entityloading, setEntityLoading] = useState(true);
  const [defaultVal, setDefaultVal] = useState("");
  const [entity, setEntity] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const editMode = location.pathname.search("edit") !== -1;

  const SubmitForm = async (data) => {
    setSubmitting(true);
    try {
      let resp;
      if (editMode) {
        resp = await Qrcodes.update(params.id, data);
      } else {
        resp = await Qrcodes.add(data);
      }

      toast.success("Successfully added Qrcode");
      setSubmitting(false);
      return navigate("/business/qr-code");
    } catch (error) {
      setSubmitting(false);
      if (error?.response?.status !== 403) {
        toast.error("api error qrcode not added.");
      }
    }
  };
  const [form] = Form.useForm();

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    isValid,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      entity_type: "",
      entity_id: "",
    },
    validationSchema: createQRcodeSchema,
    onSubmit: async (formData) => {
      setSubmitting(true);

      return SubmitForm(formData);
    },
  });

  const OnTargetSelect = async (value) => {
    setFieldValue("entity_id", "");
    setEntityLoading(true);
    // call endpoint
    const resp = await myAxios.get(`/business/${value}`);
    const dataResp = resp.data.data;

    const entities = dataResp.map((d) => {
      return { label: d?.name || d?.title, value: d.id };
    });

    setEntity(entities);
    setEntityLoading(false);
  };

  const fetchByID = async (id) => {
    try {
      const qr = await QRCodes.getById(id);
      const respData = qr.data.data;
      setFieldValue("name", respData.name);
      setFieldValue("entity_type", respData.entity_type);
      await OnTargetSelect(respData.entity_type);
      setFieldValue("entity_id", respData.entity_id);
      //
      form.setFieldsValue({ ...respData });
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("api Error");
      }
      return navigate("/business/qr-code");
    }
  };

  useEffect(() => {
    if (editMode) {
      // fetch qrcode by id
      fetchByID(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit QR Code";
    } else {
      document.title = "HeyHire Portal-Create New QR Code";
    }
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="overflow-x-auto overflow-hidden mt-10 w-full max-w-7xl">
        <div className="flex justify-between flex-col">
          <h2 className="font-bold text-2xl">
            {editMode ? "Edit QR Code" : "Create QR Code"}
          </h2>
          <hr className="w-full border border-primary-100 mt-1" />
        </div>
        <div className="mt-6 mb-64 w-full md:w-1/2 lg:w-1/3 md:mr-8">
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Space size={18} direction="vertical" className="w-full">
              <div className="flex flex-col">
                <div>
                  <FormInput
                    id="name"
                    name="name"
                    label="QR Name"
                    placeholder={"XYZ Holdings LLC"}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    darmo={true}
                  />
                  {touched.name && errors.name && (
                    <p className="text-red-400 text-xs">{errors.name}</p>
                  )}
                </div>
                <div className="mt-6">
                  <FormSelect
                    name="entity_type"
                    label="Entity Type"
                    value={
                      !!values.entity_type && {
                        label: values.entity_type,
                        value: values.entity_type,
                      }
                    }
                    onChange={(e) => {
                      OnTargetSelect(e.value);
                      setFieldValue("entity_type", e.value);
                    }}
                    onBlur={handleBlur}
                    options={targets}
                    darmo={true}
                  />
                  {touched.entity_type && errors.entity_type && (
                    <p className="text-red-400 text-xs">{errors.entity_type}</p>
                  )}
                </div>
                <div className="mt-4">
                  <FormSelect
                    name="entity_id"
                    label="Select Entity"
                    value={
                      !!values.entity_id &&
                      entity?.find((item) => item.value === values.entity_id)
                    }
                    onChange={(e) => {
                      setFieldValue("entity_id", e.value);
                    }}
                    onBlur={handleBlur}
                    options={entity}
                    loading={entityloading}
                    darmo={true}
                  />
                  {touched.entity_id && errors.entity_id && (
                    <p className="text-red-400 text-xs">{errors.entity_id}</p>
                  )}
                </div>
                <div className="flex mt-8 justify-center">
                  <button
                    className={`cursor-pointer px-6 border mr-1 my-2 flex items-center justify-center bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50
                      text-center text-sm font-bold whitespace-nowrap text-ellipsis rounded-md py-2`}
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                  >
                    {editMode ? "Save" : "Create"} QR Code
                  </button>
                </div>
                {/* <p className="text-sm font-semibold">Preview</p>
                  <div className="min-h-54 p-4 w-full border-[1px] border-[#E4E5E7] border-solid justify-center">
                    <img src={QRcodeSample} alt="logo" className="h-[206px]" />
                  </div> */}
              </div>
            </Space>
          </Form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreateQRCode;
