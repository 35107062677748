import React from "react";
import { ProfileImgArrowIcon } from "../../../assets/images";

const ProfileCard = ({
  imageUrl = "",
  content = "Proprietor: username",
  modifiedheader,
  sidebar,
  selectedLocation,
  role,
}) => {
  return (
    <>
      {/* {tooltipText && <Tooltip id="tooltip-1" style={tooltipStyle} />} */}
      <div className="flex flex-col w-full justify-center">
        <div className="relative flex w-full mx-auto bg-white bg-clip-border shadow-3xl rounded-xl">
          <div className="flex items-center w-full">
            {(role === "regional_manager" || role === "owner") &&
              selectedLocation &&
              selectedLocation?.name !== "Select A Location" && (
                <div
                  className={`relative flex items-center justify-center rounded-full border-white bg-white mx-auto ${
                    sidebar ? "w-[100px]" : "w-[50px] mt-2"
                  }`}
                >
                  <img className="rounded-full p-1" src={imageUrl} alt="" />
                  <img
                    // data-tooltip-id="tooltip-1"
                    // data-tooltip-content={header}
                    // data-tooltip-place="bottom"
                    src={ProfileImgArrowIcon}
                    alt=""
                    className={`absolute ${sidebar ? "h-[90px]" : ""}`}
                  />
                </div>
              )}

            {/* Text Container */}
            <div
              className={`${sidebar ? "flex flex-col ml-3 w-full" : "hidden"}`}
            >
              <h4
                className={`uppercase text-[13px] text-primary-500 font-bold ml-1 mb-0.5`}
              >
                {modifiedheader}
              </h4>
              {/* <p className="font-bold text-gray-600 ml-1">{location}</p> */}
              <p className="text-[13px] font-bold text-gray-600 ml-1">
                {content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
