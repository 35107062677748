import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import DashboardStat from "../components/molecules/DashboardStat";
import DashboardTable from "../components/molecules/DashboardTable";
import { DashboardLayout } from "../components/organisms/Layout";
import DashboardApi from "../api/Dashboard";
import Region from "../api/Region";
import User from "../api/User";
import { formatPhone, encodeIds } from "../utils";
import { defaultUserImage } from "../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  locations,
  selectRoles,
  selectSelectedLocation,
  selectUser,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../store/authSlice";
import Location from "../api/Location";

const BusinessOwnerDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const isEnterprise = user?.company?.is_enterprise === 1;
  const selectedLocation = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const [recentLogins, setRecentLogins] = useState([]);
  const [recentLocations, setRecentLocations] = useState([]);
  const [recentRegions, setRecentRegions] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [subUsers, setSubUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const allLocations = useSelector(locations);
  const showLocation =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";

  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });

  const loadRecentApplications = async () => {
    await DashboardApi.getRecentApplications()
      .then((response) => {
        setRecentApplications(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  const loadRecentLogins = () => {
    DashboardApi.getRecentLogins()
      .then((response) => {
        const userData = response.data.data.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
        setRecentLogins(userData);
      })
      .catch((error) => console.error(error));
  };

  const loadSubUsers = () => {
    User.getAll()
      .then((response) => {
        setTotalUsers(response.data.data);
        const subU = response.data.data.filter(
          (e) =>
            e.roles.length > 0 &&
            e.roles[0].name !== "owner" &&
            e.roles[0].name !== "super-user"
        );
        const _managers = subU.filter((e) => e.roles[0].name !== "employee");
        const _employees = subU.filter((e) => e.roles[0].name == "employee");
        setManagers(_managers);
        setSubUsers(_employees);
        setActiveUsers(_managers);
      })
      .catch((error) => console.error(error));
  };

  const loadRecentLocations = () => {
    DashboardApi.getRecentLocations()
      .then((response) => {
        setRecentLocations(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  const loadRegions = () => {
    Region.getAll()
      .then((response) => {
        setRecentRegions(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  const getLocationRegion = (id) => {
    const _region = recentRegions.filter((item) => item.id === id);
    return _region[0]?.name;
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    loadRecentApplications();
    loadRecentLogins();
    loadRecentLocations();
    loadSubUsers();
    loadRegions();
  }, []);

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      setShowNotification({
        show: true,
        type: "success",
        message: "Location Changed!",
      });
    } catch (error) {
      setShowNotification({
        show: true,
        type: "error",
        message: "Error Occurred during Location switching. Try Again!",
      });
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <div>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Col xs={24} lg={24}>
              <div className="lg:overflow-hidden h-full mx-2 md:mx-0 2xl:w-3/5">
                <DashboardTable
                  title={t("dashboard.latest-application-submissions")}
                  headers={
                    recentApplications?.length > 0
                      ? [
                          t("common.applicants-name"),
                          t("common.applied-for"),
                          t("common.location"),
                        ]
                      : ["No Applicants Yet"]
                  }
                  mode={true}
                >
                  {recentApplications.slice(0, 5).map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="text-black hover:cursor-pointer"
                        onClick={() => {
                          const filteredLocations = allLocations?.filter(
                            (location) => location?.id === item?.location_id
                          );
                          if (filteredLocations[0]) {
                            dispatch(setSelectedLocation(filteredLocations[0]));
                            localStorage.setItem(
                              "selectedLocation",
                              JSON.stringify(filteredLocations[0])
                            );
                            changeLocation(filteredLocations[0]?.id);
                            navigate(
                              `/business/applicant/${encodeIds(
                                item.job_id
                              )}/${encodeIds(item.job_seeker_id)}`
                            );
                            dispatch(setInitialChatFetch(false));
                            dispatch(setVisiblePersons([]));
                            dispatch(setMinimizedPersons([]));
                            dispatch(setSelectedPersons([]));
                          }
                        }}
                      >
                        <td colspan="3" className="p-0">
                          <div className="rounded-xl overflow-hidden p-2 bg-[#f6f6f6] hover:border-[0.5px] hover:border-black px-2 xl:px-3">
                            <div className="flex flex-row">
                              <div className="flex-1 flex my-auto">
                                <img
                                  className="rounded-full mr-2 w-6 h-6 my-auto"
                                  width={24}
                                  src={
                                    item?.seeker?.photo?.thumb_url ||
                                    defaultUserImage
                                  }
                                  alt="Profile"
                                />
                                <span className="my-auto">
                                  {item?.seeker?.first_name}{" "}
                                  {item?.seeker?.last_name}
                                </span>
                              </div>
                              <div className="flex-1 text-center my-auto">
                                {item?.position?.title}
                              </div>
                              <div className="flex-1 text-right my-auto">
                                {item?.location?.name}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {/*recentApplications?.length === 0 && <tr><td className="px-3 py-3 whitespace-nowrap text-center" colSpan={12}><p>No Data Available</p></td></tr>*/}
                </DashboardTable>
              </div>
            </Col>
            <Col xs={24} lg={24}>
              <div className="lg:overflow-hidden h-full mx-2 md:mx-0 my-8 2xl:w-3/5">
                <DashboardTable
                  title={"Recent User Login"}
                  headers={[
                    t("common.full-name"),
                    "Time",
                    t("common.location"),
                  ]}
                  mode={true}
                  collapse={true}
                >
                  {recentLogins.slice(0, 5).map((m, i) => (
                    <tr key={i}>
                      <td colspan="3" className="p-0">
                        <div className="rounded-xl overflow-hidden py-2 bg-[#f6f6f6] px-2 xl:px-3">
                          <div className="flex flex-row">
                            <div className="flex-1 flex">
                              {m?.first_name} {m?.last_name}
                            </div>
                            <div className="flex-1 text-center">
                              {m?.last_login ||
                                "This user has not logged in yet"}
                            </div>
                            <div className="flex-1 text-right">
                              {m?.location?.name}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {recentLogins?.length === 0 && (
                    <tr>
                      <td
                        className="p-3 whitespace-nowrap text-center"
                        colSpan={12}
                      >
                        <p>No Data Available</p>
                      </td>
                    </tr>
                  )}
                </DashboardTable>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default BusinessOwnerDashboard;
