import React, { useState, useEffect } from "react";
import Input from "../form/Input";
import { useTranslation } from "react-i18next";
// import { NavLink } from "react-router-dom";
// import dayjs from "dayjs";

const NeedaHand = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [validationErrors, setValidationErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="px-5 pt-10 pb-20">
      <p className="pb-4 font-bold text-3xl">Need a hand?</p>
      <p className="my-4 font-semibold">
        We'll send an email to your colleague/web developer with the embedded
        code and instructions on how to get HeyHire installed.
      </p>

      <div className="text-left pt-1 pb-8 mb-4 w-2/5">
        <label className="font-semibold">Recipient's email *</label>
        <Input
          className="my-3"
          darmo={true}
          name="email"
          label={""}
          type="text"
          value={email}
          placeholder={t("e.g. dylan@example.com")}
          error={
            validationErrors && validationErrors.email
              ? validationErrors.email[0]
              : null
          }
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="w-2/5 rounded-md p-2 mt-2 text-white text-[14px] bg-primary-500 hover:bg-primary-700 hover:opacity-50 disabled:bg-primary-200"
          onClick={(e) => {
            e.preventDefault();
            //   addAddress();
          }}
          disabled={loading}
        >
          {t("Send Email")}
          {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
        </button>
      </div>
      {/* <textarea className="border-2 border-warmgray-900 rounded-md p-3 w-full max-w-[768px] h-72">
        {`<iframe src="" style="border:0px #ffffff hidden;" name="apployme" scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" height="800px" width="900px" allowfullscreen></iframe>`}
      </textarea> */}
    </div>
  );
};

export default NeedaHand;
