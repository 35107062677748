import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiEyeFill } from "react-icons/ri";
import { DropActionDropdown } from "../../TableActionDropdown";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-toastify";
import ArchivedJob from "../../../api/ArchivedJob";
import { encodeIds } from "../../../utils";
import { applicantIcon, hireApplicantIcon } from "../../../assets/images";

const JobOpeningCard = ({
  className,
  jobPosition,
  loadJobs,
  darmo,
  isArchive,
}) => {
  const navigate = useNavigate();
  const [hiredEmployees, setHiredEmployees] = useState(0);
  const activeApp =
    jobPosition.applications.filter((f) => f.status === "applied") || [];

  useEffect(() => {
    loadEmployees();
  }, []);

  const ArchivePosition = async () => {
    try {
      await JobPosition.archive(jobPosition.id);
      loadJobs();
      toast.success("Job Archived Successfully");
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Failed to archive job. Please try again.");
      }
    }
  };

  const UnarchivePosition = async () => {
    try {
      await ArchivedJob.unachiveJob(jobPosition.id);
      loadJobs();
      toast.success("Job Restored Successfully");
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Failed to archive job. Please try again.");
      }
    }
  };

  const DeletePosition = async () => {
    try {
      await JobPosition.delete(jobPosition.id);
      loadJobs();
      toast.success("Job Deleted Successfully");
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Failed to archive job. Please try again.");
      }
    }
  };

  const loadEmployees = async () => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(jobPosition.id);
      setHiredEmployees(tempUsers?.data?.data.length);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Failed to load. Please try again.");
      }
    }
  };

  return (
    <div className={className}>
      <div
        className={`px-4 py-3 shadow-md rounded-md border border-warmgray-200 flex justify-between items-center ${
          darmo ? "bg-white" : "bg-warmgray-900"
        }`}
      >
        <div className="flex">
          <img
            src={
              jobPosition?.location?.brand?.photo?.thumb_url ||
              "/logo/violet.svg"
            }
            alt={"apploy Me"}
            className="job-opening-image mr-2 cursor-pointer w-8 h-8 my-auto"
            onClick={() =>
              navigate(`/business/view-job/${encodeIds(jobPosition?.id)}`)
            }
          />
          <div className="flex flex-col my-auto">
            <span
              className={`cursor-pointer font-extrabold text-[15px] ${
                darmo ? "text-primary-500" : "text-white"
              } mr-auto self-center h-auto overflow-hidden truncate w-36 md:w-52 xl:w-80`}
              onClick={() =>
                navigate(`/business/view-job/${encodeIds(jobPosition?.id)}`)
              }
            >
              {jobPosition?.title}
            </span>
            <div className="flex flex-col sm:flex-row job-opening-mobile">
              <div className="font-semibold flex md:hidden mr-4 text-sm">
                <img src={applicantIcon} className="mr-1 h-4 w-4 my-auto" />{" "}
                Applicants: ({activeApp?.length})
              </div>

              <div className="font-semibold flex md:hidden text-sm">
                <img src={hireApplicantIcon} className="mr-1 h-4 w-4 my-auto" />{" "}
                Hired: ({hiredEmployees})
              </div>
            </div>
          </div>
        </div>
        {/* <div className="block md:hidden">
              <div className="font-semibold flex md:mr-6"> 
                <img src={applicantIcon} className="mr-1 h-4 w-4 md:w-5 md:h-5"/> Applicants: ({activeApp?.length})
              </div>

              <div className="font-semibold flex">
                <img src={hireApplicantIcon} className="mr-1 h-4 w-4 md:w-5 md:h-5"/> Hired: ({hiredEmployees})
              </div>
            </div> */}

        <div className="flex flex-1 flex-col items-end mx-3">
          <div className="flex flex-col md:flex-row text-sm">
            <div className="font-semibold md:flex md:mr-6 hidden">
              <img src={applicantIcon} className="mr-1 h-4 w-4 md:w-5 md:h-5" />{" "}
              Applicants: ({activeApp?.length})
            </div>

            <div className="font-semibold md:flex hidden">
              <img
                src={hireApplicantIcon}
                className="mr-1 h-4 w-4 md:w-5 md:h-5"
              />{" "}
              Hired: ({hiredEmployees})
            </div>

            {/* {isArchive && */}
            <DropActionDropdown
              image={"settings"}
              className="ml-5"
              icon="fas fa-cog"
              iconColor={`${darmo ? "text-orange-900" : "text-white"}`}
              menus={[
                {
                  label:
                    jobPosition?.archived_at != null
                      ? "Restore the Position"
                      : "Archive Position",
                  action:
                    jobPosition?.archived_at != null
                      ? () => UnarchivePosition()
                      : () => ArchivePosition(),
                },
                // { label: "Delete Position", action: DeletePosition, disabled: jobPosition.deleted_at != null },
              ]}
            />
            {/* } */}
          </div>
        </div>
        {/* <div onClick={() => navigate(`/business/view-job/${encodeIds(jobPosition?.id)}`)}>
          <button
            className={
              "hidden lg:block px-6 py-2 ml-4 font-bold rounded-xl bg-primary-500 text-white text-sm hover:bg-primary-400 transition-all hover:opacity-50"
            }
          >
            View Posting
          </button>
          <RiEyeFill className={`${darmo ? "text-primary-500" : "text-white"} lg:hidden text-base ml-6`} />
        </div> */}
      </div>
    </div>
  );
};

export default JobOpeningCard;
