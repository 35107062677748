import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { toast } from "react-toastify";
import PaginationView from "../../components/molecules/Pagination";
import useQuery from "../../hooks/useQuery";
import { defaultUserImage } from "../../assets/images";
import Brand from "../../api/Brand";
import Button from "../../components/form/Button";
import TableActionDropdown from "../../components/TableActionDropdown";
import FormBrand from "../../components/molecules/FormBrand";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import { encodeIds } from "../../utils";
import SearchBar from "../../components/atoms/SearchBar";

const Brands = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [metaData, setMetaData] = useState({});
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageLimit, setPagelimit] = useState(50);
  const [searchBrand, setSearchBrand] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const query = useQuery();

  useEffect(() => {
    document.title = "HeyHire Portal-Brands";
  }, []);

  useEffect(() => {
    loadBrands();
  }, [query]);

  useCustomEventListener("closeModal", () => {
    setShowDeleteModal(false);
    setShowAddModal(false);
  });

  useCustomEventListener("brandAdded", () => {
    loadBrands();
    setShowAddModal(false);
  });

  const searchBrandName = (txt) => {
    setSearchBrand(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredBrands(brands);
    } else {
      let _brands = brands.filter((j) => j?.name.toLowerCase().includes(text));
      setFilteredBrands(_brands);
    }
  };

  const loadBrands = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const limit = query.get("limit") || pageLimit;
    const pageData = page ? `page=${page}` : "";
    const queryData = pageData + `&limit=${limit}`;

    Brand.getAll(queryData)
      .then((response) => {
        setBrands(response.data.data);
        setMetaData(response.data.meta);
        setFilteredBrands(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong. Please Try Again.");
        }
      })
      .then(() => setLoading(false));
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const addBrand = (values) => {
    Brand.add(values)
      .then(() => {
        toast.success(t("brands.brand-added"));
        emitCustomEvent("brandAdded");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      });
  };

  const deleteBrand = (brandId) => {
    setDeleteLoading(true);
    Brand.deleteById(brandId)
      .then(() => {
        const dataCopy = brands.slice();
        dataCopy.splice(
          brands.findIndex((item) => item.id === brandId),
          1
        );
        setBrands(dataCopy);
        setFilteredBrands(dataCopy);
        setShowDeleteModal(false);
        toast.success("Brand Deleted.");
      })
      .catch()
      .then(() => setDeleteLoading(false));
  };
  return (
    <DashboardLayout darmo={true}>
      <FormModal
        visible={showAddModal}
        onClose={() => setShowAddModal(false)}
        title={t("brands.add-a-brand")}
        subTitle="Vivamus venenatis eros sit amet lectus gravida, volutpat dictum ex pellentesque."
        onSubmit={addBrand}
      >
        <FormBrand />
      </FormModal>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">{t("brands.brands")}</h2>

        <Button onClick={() => navigate("add")}>
          <i className="far fa-plus" /> {t("common.add")}
        </Button>
      </div>
      <SearchBar
        searchValue={searchBrand}
        placeholder="Search Brand By Name"
        searchFunction={(value) => searchBrandName(value)}
      />
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 mt-3">
          <thead className="bg-white">
            <tr>
              <th scope="col" className="font-bold tracking-wider">
                <div className="tbl-cell">{t("brands.brand-name")}</div>
              </th>
              <th scope="col" className="font-bold tracking-wider">
                <div className="tbl-cell">{t("common.company-name")}</div>
              </th>
              <th scope="col" className="font-bold tracking-wider">
                <div className="tbl-cell">{t("common.website")}</div>
              </th>
              <th scope="col" className="font-bold tracking-wider">
                <div className="tbl-cell justify-end">{t("common.action")}</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              filteredBrands.map((brand) => {
                return (
                  <tr
                    key={brand.id}
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td
                      onClick={() =>
                        navigate(`/companies/brand/${encodeIds(brand.id)}`)
                      }
                    >
                      <div className="tbl-cell">
                        <img
                          className="rounded-full mr-2"
                          width={24}
                          src={
                            brand?.photo?.tiny_url ||
                            brand?.photo?.thumb_url ||
                            defaultUserImage
                          }
                          alt="Profile"
                        />
                        {brand?.name}
                      </div>
                    </td>
                    <td>
                      <div className="tbl-cell">{brand?.company?.name}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{brand?.website}</div>
                    </td>
                    <td>
                      <div className="tbl-cell text-right justify-end z-50">
                        <TableActionDropdown
                          onClickEdit={() =>
                            navigate(`/companies/brand/${encodeIds(brand?.id)}`)
                          }
                          onClickDelete={() => {
                            deleteBrand(brand?.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {loading && <Loading />}
        <div className="flex w-full h-20 mt-2 justify-end items-center">
          {!loading && (
            <PaginationView
              current={metaData?.current_page}
              next={metaData?.to}
              pageSize={metaData?.per_page}
              total={metaData?.total}
              onChange={handlecPageChange}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Brands;
