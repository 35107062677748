import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../../api/api.js";
import { login, darkmode, setBackgroundcolor } from "../../../store/authSlice";
import AuthLayout from "../../../components/organisms/Layout/Authlayout";
import CustomNotification from "../../../utils/CustomNotification";
import { Switch } from "@headlessui/react";
import { heyhireBlackIcon, heyhireIcon } from "../../../assets/images.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });

  const HandleBg = () => {
    dispatch(setBackgroundcolor(!mode));
  };

  const passwordToggle = !passwordVisible ? (
    <i
      className={`h-full ${
        mode ? "text-gray-400" : "text-white"
      } far fa-eye-slash`}
    />
  ) : (
    <i
      className={`h-full ${mode ? "text-gray-400" : "text-white"} far fa-eye`}
    />
  );

  useEffect(() => {
    document.title = "HeyHire Portal-Login";
    return () => {
      setEmail("");
      setPassword("");
      setLoading(false);
      setValidationErrors(null);
      setErrorMessage("");
    };
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    setValidationErrors(null);
    setErrorMessage("");

    api
      .post("business/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        // Set store values
        dispatch(login(response.data));

        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
          if (error.response.data.message === "block") {
            setErrorMessage("Location is blocked");
          } else {
            if (
              (error.response.data.errors.email &&
                error.response.data.errors.email[0] ===
                  t("login.error-message-check-email")) ||
              (error.response.data.errors.password &&
                error.response.data.errors.password[0] ===
                  t("login.error-message-check-password"))
            ) {
              setErrorMessage(t("login.error-message"));
            } else {
              setValidationErrors(error.response.data.errors);
            }
          }
        } else {
          setShowNotification({
            show: true,
            type: "error",
            message: t("common.something-went-wrong"),
          });
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <>
      <AuthLayout loginpage title={t("login.sign-in-account")}>
        <div className="flex flex-col min-h-full">
          <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
            <div
              className={`sm:mx-auto sm:w-full sm:max-w-md rounded p-2 mx-2`}
            >
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="mx-auto w-[160px] lg:w-[250px] lg:mt-8"
                  src={mode ? heyhireBlackIcon : heyhireIcon}
                  alt="logo"
                />
                <h2
                  className={`mt-6 text-center text-lg lg:text-2xl font-medium leading-9 tracking-tight ${
                    !mode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {t("login.sign-in-account")}
                </h2>
              </div>
              <div className="flex justify-center">
                {errorMessage && (
                  <p className="text-red-400 text-sm font-bold border border-red-400 px-4 py-2 rounded-md mt-2">
                    {errorMessage}
                  </p>
                )}
              </div>

              <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="p-6 sm:rounded-lg sm:px-12">
                  <form className="space-y-6" action="#" method="POST">
                    <div>
                      <label
                        htmlFor="email"
                        className={`block text-md font-medium leading-6  ${
                          mode ? "text-gray-900" : "text-white"
                        }`}
                      >
                        {t("common.email")}
                      </label>
                      <div>
                        <input
                          className={`block w-full rounded-md border-0 p-2 ${
                            mode
                              ? "text-gray-900 placeholder:text-gray-400 focus:ring-indigo-600 bg-white"
                              : "text-white placeholder:text-gray-300 focus:ring-white bg-slate-800"
                          }  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6`}
                          name="email"
                          label={t("common.email")}
                          type="email"
                          value={email}
                          placeholder={t("login.email-input-placeholder")}
                          onChange={handleInputChange}
                        />
                        {validationErrors?.email && (
                          <p className="text-red-400 text-xs font-bold pt-1">
                            {validationErrors?.email[0]}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className={`block text-md font-medium leading-6 ${
                          mode ? "text-gray-900" : "text-white"
                        }`}
                      >
                        {t("common.password")}
                      </label>
                      <div className="relative">
                        <input
                          className={`block w-full rounded-md border-0 ${
                            mode
                              ? "text-gray-900  placeholder:text-gray-400 focus:ring-indigo-600 bg-white"
                              : "text-white  placeholder:text-gray-300 focus:ring-white bg-slate-800"
                          } p-2 pr-8 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                          name="password"
                          label={t("common.password")}
                          placeholder={t("login.password-input-placeholder")}
                          type={passwordVisible ? "text" : "password"}
                          value={password}
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                        <div
                          className={`absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer ${
                            mode ? "text-gray-600" : "text-white"
                          }`}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordToggle}
                        </div>
                      </div>
                      {validationErrors?.password && (
                        <p className="text-red-400 text-xs font-bold pt-1">
                          {validationErrors?.password[0]}
                        </p>
                      )}
                    </div>

                    <div className="flex items-center justify-between gap-1">
                      <div className="flex items-center flex-shrink-0">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className={`h-4 w-4 text-indigo-600 focus:ring-indigo-600 bg-white`}
                        />
                        <label
                          htmlFor="remember-me"
                          className={`ml-2 block text-sm leading-6 ${
                            mode ? "text-gray-900" : "text-white"
                          }`}
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm leading-6 flex flex-shrink-0">
                        <Link
                          to="/forgot-password"
                          className={`font-medium ${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500"
                              : "text-secondary hover:text-white"
                          }`}
                        >
                          {t("login.forgot-password")}?
                        </Link>
                      </div>
                    </div>

                    <div>
                      <button
                        label={t("login.login")}
                        loading={loading}
                        onClick={handleLogin}
                        type="submit"
                        className="flex w-full justify-center rounded-full bg-primary-500 px-6 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                      >
                        Sign in
                        {loading ? (
                          <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                    <p
                      className={`text-center text-sm ${
                        mode ? "text-black" : "text-white"
                      }`}
                    >
                      Don't have an account?{"   "}
                      <span
                        onClick={() => {
                          localStorage.removeItem("registerStep0");
                          localStorage.removeItem("registerStep1");
                          localStorage.removeItem("registerStep2");
                          localStorage.removeItem("selectedPlan");
                          navigate("/plans");
                        }}
                        className={`cursor-pointer font-semibold leading-6 ${
                          mode
                            ? "text-indigo-600 hover:text-indigo-500 "
                            : "text-secondary hover:text-white"
                        }`}
                      >
                        Sign up
                      </span>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-4 flex justify-center items-end">
            <p
              className={`${
                mode ? "text-gray-600" : "text-white"
              } text-xs font-bold my-auto mr-3`}
            >
              {mode ? "Turn on Dark Mode" : "Turn on Light Mode"}
            </p>
            <Switch
              checked={mode}
              onChange={HandleBg}
              className={classNames(
                mode ? "bg-primary-500" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}
            >
              <span
                className={classNames(
                  mode ? "translate-x-0 bg-white" : "translate-x-5 bg-midnight",
                  "pointer-events-none relative inline-block h-5 w-5 transform rounded-full  shadow ring-0 transition duration-200 ease-in-out"
                )}
              >
                <span
                  className={classNames(
                    mode
                      ? "opacity-100 duration-200 ease-in"
                      : "opacity-0 duration-100 ease-out",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    fill="#000000"
                    width="800px"
                    height="1em"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M 15 3 L 15 8 L 17 8 L 17 3 Z M 7.5 6.09375 L 6.09375 7.5 L 9.625 11.0625 L 11.0625 9.625 Z M 24.5 6.09375 L 20.9375 9.625 L 22.375 11.0625 L 25.90625 7.5 Z M 16 9 C 12.144531 9 9 12.144531 9 16 C 9 19.855469 12.144531 23 16 23 C 19.855469 23 23 19.855469 23 16 C 23 12.144531 19.855469 9 16 9 Z M 16 11 C 18.773438 11 21 13.226563 21 16 C 21 18.773438 18.773438 21 16 21 C 13.226563 21 11 18.773438 11 16 C 11 13.226563 13.226563 11 16 11 Z M 3 15 L 3 17 L 8 17 L 8 15 Z M 24 15 L 24 17 L 29 17 L 29 15 Z M 9.625 20.9375 L 6.09375 24.5 L 7.5 25.90625 L 11.0625 22.375 Z M 22.375 20.9375 L 20.9375 22.375 L 24.5 25.90625 L 25.90625 24.5 Z M 15 24 L 15 29 L 17 29 L 17 24 Z" />
                  </svg>
                </span>
                <span
                  className={classNames(
                    mode
                      ? "opacity-0 duration-100 ease-out"
                      : "opacity-100 duration-200 ease-in",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z"
                      fill="white"
                    />
                  </svg>
                </span>
              </span>
            </Switch>
          </div>
        </div>
      </AuthLayout>

      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
    </>
  );
};

export default Login;
