import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { DateFormat, encodeIds } from "../utils";
import { defaultUserImage, defaultUserImage2 } from "../assets/images";
import ApplicationLogs from "../api/ApplicationLogs";
import Application from "../api/Application";

const CANCELEDSTATUS = ["canceled"];
const REJECTEDSTATUS = ["rejected"];

const ApplicantCard = ({
  applicant,
  application,
  type,
  positionId,
  className,
  isView,
  isCancel,
}) => {
  const uploadApplicationEvent = () => {
    const body = {
      event_type: "view",
      entity_type: "application",
      entity_id: application?.id,
    };
    ApplicationLogs.addEvent(body)
      .then((response) => {})
      .catch((error) => console.log("uploadApplicationEvent -> error", error));
  };

  const recordApplicationView = async () => {
    try {
      const body = {
        application_id: application?.id,
      };
      await Application.postApplicationView(body);
    } catch (error) {
      console.log(
        "Failed to record application view. Please try again.",
        error?.response
      );
    }
  };

  const { t } = useTranslation();

  if (!applicant || !application) return <></>;
  return (
    <div
      className={
        `relative rounded-xl shadow-lg flex flex-col items-stretch text-center py-6 px-2 ${
          type === "employee" ? "bg-primary-500" : "bg-white"
        }
        ${
          CANCELEDSTATUS.includes(application?.status) ||
          REJECTEDSTATUS.includes(application?.status)
            ? "opacity-25"
            : ""
        } ` + className
      }
    >
      <div>
        <div className="flex justify-center">
          <img
            src={
              applicant?.photo?.thumb_url ||
              (type === "employee" ? defaultUserImage2 : defaultUserImage)
            }
            alt="Profile"
            className="rounded-full"
            height={110}
            width={110}
          />
        </div>

        <span
          className={`block text-primary-500 mt-2 text-[16px] font-bold ${
            type === "employee" && "text-white"
          }`}
        >
          {application?.seeker?.first_name +
            " " +
            application?.seeker?.last_name}
        </span>
        <span
          className={`block text-[12px] font-bold ${
            type === "employee" && "text-white"
          }`}
        >
          {type === "applicant" ? "Applied on:" : "Hired on:"}{" "}
          {dayjs(application?.created_at).format(DateFormat)}
        </span>
        {applicant?.city && (
          <span
            className={`block text-black text-[12px] font-bold ${
              type === "employee" && "text-white"
            }`}
          >
            <i
              className={`fas fa-map-marker-alt mr-0.5 ${
                type !== "employee" && "text-purple-600"
              }`}
            />{" "}
            {applicant?.city}, {applicant?.state}
          </span>
        )}
      </div>
      <div className="pt-6 w-full mt-auto">
        {!CANCELEDSTATUS.includes(application?.status) &&
          !REJECTEDSTATUS.includes(application?.status) && (
            // isView && (
            <NavLink
              to={
                type === "applicant"
                  ? `/business/applicant/${encodeIds(positionId)}/${encodeIds(
                      applicant?.id
                    )}`
                  : `/business/hired-applicant/${encodeIds(
                      positionId
                    )}/${encodeIds(applicant?.id)}`
              }
            >
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => {
                    recordApplicationView();
                    uploadApplicationEvent();
                  }}
                  className={`py-2 w-auto cursor-pointer rounded-xl px-4 flex items-center justify-center text-sm font-bold shadow-md 
                  transition-all hover:opacity-50 whitespace-nowrap ${
                    type === "employee"
                      ? "text-black bg-white"
                      : "text-white bg-primary-500 hover:bg-primary-400"
                  }`}
                >
                  {type === "applicant"
                    ? t("applicant.view-applicant")
                    : "Manage Employee"}
                </button>
              </div>
            </NavLink>
          )}

        {CANCELEDSTATUS.includes(application?.status) && isCancel && (
          <div className="w-full flex items-center justify-center">
            <button
              className="py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-sm font-bold shadow-md 
            transition-all hover:opacity-50 whitespace-nowrap bg-rose-500 text-white"
            >
              Cancelled
            </button>
          </div>
        )}

        {REJECTEDSTATUS.includes(application?.status) && isCancel && (
          <div className="w-full flex items-center justify-center">
            <button
              className="py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-sm font-bold shadow-md 
            transition-all hover:opacity-50 whitespace-nowrap bg-rose-500 text-white"
            >
              Rejected
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicantCard;
