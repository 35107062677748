import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setVisiblePersons,
  visiblePersonsArray,
} from "../../../store/authSlice";

const BlinkingEffect = ({ children, isActive, person }) => {
  const [isBlinking, setIsBlinking] = useState(false);
  const dispatch = useDispatch();
  const visiblePersons = useSelector(visiblePersonsArray);

  useEffect(() => {
    if (isActive) {
      setIsBlinking(true);

      const timeoutId = setTimeout(() => {
        setIsBlinking(false);
        const updatedVisiblePersons = visiblePersons?.map((chat) => {
          if (person.conversation_sid === chat.conversation_sid) {
            return {
              ...chat,
              hasNewMessage: false,
            };
          }
          return chat;
        });

        dispatch(setVisiblePersons(updatedVisiblePersons));
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isActive]);

  return (
    <div
      className={`${
        isBlinking ? "blink" : ""
      } w-full bg-primary-500 rounded-t-md`}
    >
      {children}
    </div>
  );
};

export default BlinkingEffect;
