import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import DashboardStat from "../components/molecules/DashboardStat";
import DashboardTable from "../components/molecules/DashboardTable";
import { DashboardLayout } from "../components/organisms/Layout";
import DashboardApi from "../api/Dashboard";
import Region from "../api/Region";
import { formatPhone, encodeIds } from "../utils";
import User from "../api/User";
import { defaultUserImage } from "../assets/images";

const RegionalManagerDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [recentLogins, setRecentLogins] = useState([]);
  const [recentLocations, setRecentLocations] = useState([]);
  const [recentRegions, setRecentRegions] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [switchRegionTab, setSwitchRegionTab] = useState(true);
  const [subUsers, setSubUsers] = useState([]);
  const recentRegionTab = switchRegionTab ? recentRegions : recentLocations;

  const loadRecentApplications = () => {
    DashboardApi.getRecentApplications().then((response) =>
      setRecentApplications(response.data.data)
    );
  };

  const loadRecentLogins = () => {
    DashboardApi.getRecentLogins().then((response) => {
      const userData = response.data.data.filter(
        (e) =>
          e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
      );
      setRecentLogins(userData);
    });
  };

  const loadSubUsers = () => {
    User.getAll().then((response) => {
      const subU = response?.data?.data?.filter((e) => {
        if (e.roles[0]?.name !== "owner" && e.roles[0]?.name !== "super-user") {
          return e;
        }
      });
      setSubUsers(subU);
    });
  };

  const loadRecentLocations = () => {
    DashboardApi.getRecentLocations().then((response) => {
      setRecentLocations(response.data.data);
    });
  };

  const loadRegions = () => {
    Region.getAll().then((response) => {
      setRecentRegions(response.data.data);
    });
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    loadRecentApplications();
    loadRecentLogins();
    loadRecentLocations();
    loadSubUsers();
    loadRegions();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="mb-6">
        {/* <AlertMessage
          className="my-4"
          message={"Last session: November 12th, 2021"}
        /> */}

        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <div className="lg:overflow-hidden shadow-md h-full">
                  <DashboardTable
                    title={t("dashboard.latest-application-submissions")}
                    headers={
                      recentApplications?.length > 0
                        ? [
                            t("common.applicants-name"),
                            t("common.applied-for"),
                            t("common.location"),
                          ]
                        : ["No Applicants Yet"]
                    }
                    mode={true}
                  >
                    {recentApplications
                      .slice(0, 5)
                      .map((recentApplication, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-black hover:text-primary-400 hover:cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/business/applicant/${encodeIds(
                                  recentApplication.job_id
                                )}/${encodeIds(
                                  recentApplication.job_seeker_id
                                )}`
                              )
                            }
                          >
                            <td className="px-3 py-3 whitespace-nowrap flex mr-2 items-center">
                              <img
                                className="rounded-full mr-2"
                                width={24}
                                src={
                                  recentApplication?.seeker?.photo?.thumb_url ||
                                  defaultUserImage
                                }
                                alt="Profile"
                              />
                              {recentApplication?.seeker?.first_name}{" "}
                              {recentApplication?.seeker?.last_name}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap mr-2">
                              {recentApplication?.position?.title}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap  ">
                              {recentApplication?.location?.name}
                            </td>
                          </tr>
                        );
                      })}
                    {/*recentApplications.length === 0 && <tr><td className="px-3 py-3 whitespace-nowrap text-center" colSpan={12}><p>No Data Available</p></td></tr>*/}
                  </DashboardTable>
                </div>
              </Col>
              {/* <Col xs={24} lg={12}>
                <div className="lg:overflow-hidden shadow-md h-full">
                  <DashboardTable
                    titles={["My Regions", "Locations"]}
                    headers={[
                      `${switchRegionTab ? "Region Name" : "Location Name"}`,
                    ]}
                    clickAction={(title) => {
                      if (title == "My Regions") {
                        setSwitchRegionTab(true);
                      } else {
                        setSwitchRegionTab(false);
                      }
                    }}
                    mode={true}
                  >
                    {recentRegionTab.slice(0, 5).map((data, i) => (
                      <tr key={i}>
                        <td className="px-3 py-3 whitespace-nowrap">
                          {data?.name}
                        </td>
                      </tr>
                    ))}
                    {recentRegionTab.length === 0 && (
                      <tr>
                        <td
                          className="px-3 py-3 whitespace-nowrap text-center"
                          colSpan={12}
                        >
                          <p>No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </DashboardTable>
                </div>
              </Col> */}
              <Col xs={24} lg={12}>
                <div className="lg:overflow-hidden shadow-md h-full">
                  <DashboardTable
                    title={"Recent Employee Logins"}
                    headers={["Name", "Last Login", "Manages"]}
                    mode={true}
                  >
                    {recentLogins.slice(0, 5).map((recentLogin, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-4 py-4 whitespace-nowrap flex mr-2">
                            {`${recentLogin?.first_name} ${recentLogin?.last_name}`}
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap mr-2">
                            {recentLogin.last_login ||
                              "This user has not logged in yet"}
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap">
                            {recentLogin?.location?.name}
                          </td>
                        </tr>
                      );
                    })}
                    {recentLogins?.length === 0 && (
                      <tr>
                        <td
                          className="px-3 py-3 whitespace-nowrap text-center"
                          colSpan={12}
                        >
                          <p>No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </DashboardTable>
                </div>
              </Col>
              {/* <Col xs={24} lg={12}>
                <div className="lg:overflow-hidden shadow-md h-full">
                  <DashboardTable
                    title={"Sub Users"}
                    headers={[
                      t("common.full-name"),
                      t("common.job-position"),
                      t("common.company"),
                    ]}
                    mode={true}
                  >
                    {subUsers.map((user, i) => (
                      <tr key={i}>
                        <td className="px-4 py-4 whitespace-nowrap flex">
                          {user?.first_name} {user?.last_name}
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap">
                          {formatPhone(user?.phone)}
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap">
                          {user?.email}
                        </td>
                      </tr>
                    ))}
                    {subUsers?.length === 0 && (
                      <tr>
                        <td
                          className="px-3 py-3 whitespace-nowrap text-center"
                          colSpan={12}
                        >
                          <p>No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </DashboardTable>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default RegionalManagerDashboard;
