import React, { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { defaultUserImage } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { encodeIds } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  conversations,
  minimisedPersonsArray,
  selectedConversation,
  setDeleteChat,
  setMinimizedPersons,
  setSelectedConversation,
  setVisiblePersons,
  unreadData,
  visiblePersonsArray,
} from "../../../store/authSlice";
import dayjs from "dayjs";
import ChatApi from "../../../api/Chat";
import { toast } from "react-toastify";

const ChatItem = ({ chatInfo, isChatListOpen, onClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastMessage, setLastMessage] = useState(null);
  const conversation = useSelector(conversations);
  const unreadDataArray = useSelector(unreadData);
  const selectConversation = useSelector(selectedConversation);
  const visiblePersons = useSelector(visiblePersonsArray);
  const minimizedPersons = useSelector(minimisedPersonsArray);

  const hasUnreadMessages = (conversationSid) => {
    const unreadInfo = unreadDataArray?.find((unreadItem) => {
      const parsedData = JSON.parse(unreadItem.data);
      const itemConversationSid = parsedData?.conversation_sid;
      return itemConversationSid === conversationSid;
    });

    return unreadInfo;
  };

  const loadLastMessage = () => {
    if (!conversation) {
      return;
    }

    const msg = conversation[chatInfo.id].conversations;

    msg
      .getMessages(1)
      .then((messages) => {
        if (messages.items.length > 0) {
          setLastMessage(messages.items[0]);
        }
      })
      .catch(() => {
        setTimeout(() => {
          loadLastMessage();
        }, 5000);
      });
    msg.on("messageAdded", (message) => {
      setLastMessage(message);
    });
  };

  useEffect(() => {
    loadLastMessage();
  }, [conversation]);

  const navigateProfile = (person) => {
    navigate(
      `/business/applicant/${encodeIds(person?.position?.id)}/${encodeIds(
        person?.seeker?.id
      )}`
    );
  };

  const getFormattedDate = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date >= today) {
      return dayjs(date).format("h:mm A");
    } else if (date >= yesterday) {
      return "Yesterday";
    } else {
      const yearDifference = now.getFullYear() - date.getFullYear();
      if (yearDifference > 0) {
        return `${yearDifference} yr`;
      } else {
        return dayjs(date).format("D MMM");
      }
    }
  };

  const deleteChat = (id) => {
    ChatApi.deleteChat(id)
      .then((response) => {
        const updatedSelectedConversation = { ...selectConversation };
        for (const key in updatedSelectedConversation) {
          if (updatedSelectedConversation.hasOwnProperty(key)) {
            if (updatedSelectedConversation[key].conversation_sid === id) {
              delete updatedSelectedConversation[key];
              break;
            }
          }
        }
        dispatch(setSelectedConversation(updatedSelectedConversation));

        const updatedVisiblePersons = (visiblePersons || [])?.filter(
          (person) => person?.conversation_sid !== id
        );
        dispatch(setVisiblePersons(updatedVisiblePersons));

        const updatedMinimizedPersons = (minimizedPersons || [])?.filter(
          (person) => person?.conversation_sid !== id
        );
        dispatch(setMinimizedPersons(updatedMinimizedPersons));

        dispatch(setDeleteChat(true));
        toast.success("Chat Deleted");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          console.error("error", error);
          toast.error("Something went wrong. Please Try again later");
        }
      });
  };

  if (!chatInfo) return <></>;
  else
    return (
      <div
        className="group relative flex items-center px-5 py-5"
        data-tooltip-id={`my-tooltip-chat`}
        data-tooltip-html={`<strong>${chatInfo.seeker?.first_name} ${
          chatInfo.seeker?.last_name
        }</strong><br/><span style="font-size: 0.8em">${
          lastMessage && lastMessage.body
            ? lastMessage.body.length > 25
              ? lastMessage.body.substring(0, 24) + "..."
              : lastMessage.body
            : "No messages here."
        }</span>`}
        data-tooltip-place="left"
      >
        <div className="-m-1 block flex-1 p-1 cursor-pointer" onClick={onClick}>
          <div
            className="absolute inset-0 group-hover:bg-gray-50"
            aria-hidden="true"
          />
          <div className="relative flex min-w-0 flex-1 items-center">
            <span className="relative inline-block flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src={chatInfo.seeker?.photo?.original_url || defaultUserImage}
                alt=""
              />
            </span>
            {isChatListOpen ? (
              <div className="ml-4 truncate">
                <p className="truncate text-sm font-medium text-gray-900 overflow-hidden w-32 md:w-40 lg:w-52 flex">
                  {chatInfo.seeker?.first_name +
                    " " +
                    chatInfo.seeker?.last_name}
                  {lastMessage &&
                    hasUnreadMessages(chatInfo?.conversation_sid) && (
                      <p className="w-3 h-3 bg-red-500 rounded-full ml-1.5 my-auto"></p>
                    )}
                </p>
                <p className="text-sm font-medium text-gray-500 truncate overflow-hidden w-32 md:w-40 lg:w-56">
                  Applied for: {chatInfo?.position?.title}
                </p>
                <div className="flex items-center text-sm font-medium text-gray-500">
                  {lastMessage && lastMessage.dateCreated && (
                    <span className="mr-1">
                      {lastMessage && getFormattedDate(lastMessage.dateCreated)}
                      :
                    </span>
                  )}
                  <p className="truncate">
                    {lastMessage && lastMessage.body
                      ? lastMessage.body.length > 20
                        ? lastMessage.body.substring(0, 19) + "..."
                        : lastMessage.body
                      : "No messages here."}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {isChatListOpen ? (
          <Menu
            as="div"
            className="relative ml-2 inline-block flex-shrink-0 text-left"
          >
            <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Open options menu</span>
              <span className="flex h-full w-full items-center justify-center rounded-full">
                <EllipsisVerticalIcon
                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-9 top-0 z-10 w-40 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          navigateProfile(chatInfo);
                        }}
                        className={`${
                          active ? "bg-warmgray-200 text-black" : "text-black"
                        } block px-4 py-2 text-sm cursor-pointer`}
                      >
                        View profile
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          deleteChat(chatInfo?.conversation_sid);
                        }}
                        className={`${
                          active ? "bg-warmgray-200 text-black" : "text-black"
                        } block px-4 py-2 text-sm cursor-pointer`}
                      >
                        Delete
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          ""
        )}
      </div>
    );
};

export default ChatItem;
