import CardLoading from "../../Loading/CardLoading";

export default function Illustrator({
  overview = "Business Analytics",
  header = "header",
  data = [],
}) {
  return (
    <div className="mt-2 mb-4 md:mb-8 md:mt-4">
      <div className="bg-[#f6f6f6] px-1 sm:px-0 border border-primary-200 shadow-xl w-full mx-auto rounded-2xl">
        <div className="p-4">
          <h3 className="font-extrabold text-gray-900">{overview}</h3>
          <p className="max-w-2xl font-normal text-[#8C8C8C]">{header}</p>
        </div>
        <hr className="h-px bg-gray-200 border-0" />
        <div className="mx-auto max-w-2xl pb-4 px-4 lg:max-w-3xl">
          <div className="grid grid-cols-2 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
            {data.map((item) => (
              <div key={item.name} className="flex flex-col items-center">
                <div className="mb-2">{item.icon}</div>
                <h3 className="text-md font-medium text-gray-900">
                  {item.name}
                </h3>
                <p className="text-xl font-bold text-primary-500 -tracking-widest">
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
