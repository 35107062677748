import { Drawer } from "antd";
import React, { useState, useRef } from "react";
import { useCustomEventListener } from "react-custom-events";
import { BusinessSidebar } from "../BusinessSidebar";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import "./Layout.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectSubscriptionTrialStatus,
  notifyMessage,
  selectRoles,
  selectSelectedLocation,
  setSelectedLocation,
  sidebar,
  setVisiblePersons,
  setMinimizedPersons,
  setSelectedPersons,
  setAllLocations,
  isChatListExpand,
  setInitialChatFetch,
  initialAlertFetch,
  setInitialAlertFetch,
  notificationAlertData,
  setNotificationData,
} from "../../../store/authSlice";
import User from "../../../api/User";
import AdminNavBar from "../AdminNavBar/AdminNavBar";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import StickyBanner from "../../atoms/StickyBanner/StickyBanner";
import { useEffect } from "react";
import Location from "../../../api/Location";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSwitchNavBar from "../AdminNavBar/AdminSwitchNavBar";
import CustomNotification from "../../../utils/CustomNotification";
import { Tooltip } from "react-tooltip";
import BusinessBreadCrumbs from "../BreadCrumb/BusinessBreadCrumbs";
import {
  applicantIcon,
  locationTag1Icon,
  locationWhiteIcon,
  openPositionIcon,
  startChatBlackIcon,
} from "../../../assets/images";
import AlertModal from "../../AlertModal";
import Alert from "../../../api/Alert";
import { toast } from "react-toastify";

function DashboardLayout({ children, className, notification = false, darmo }) {
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const subscriptionTrialStatus = useSelector(selectSubscriptionTrialStatus);
  const getMessage = useSelector(notifyMessage);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [isTrial, setIsTrial] = useState(subscriptionTrialStatus);
  const [isBlocked, setIsBlocked] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [notificationMessage, setNotificationMessage] = useState(getMessage);
  const isBusiness = roles[0] !== "super-user";
  const isEnterprise = user?.company?.is_enterprise === 1;
  const navigate = useNavigate();
  const location = useLocation();
  const open = useSelector(sidebar);
  const scrollContainerRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const showLocation =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const role = roles.length > 0 ? roles[0] : "";
  const [locationStats, setLocationStats] = useState({});
  const isChatListOpen = useSelector(isChatListExpand);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const notificationData = useSelector(notificationAlertData);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const initialFetch = useSelector(initialAlertFetch);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(setNotificationData(null));
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
    LocationStatus();
    loadLocations();
  }, []);

  useCustomEventListener("toggle-sidebar", () => {
    setSideBarVisible(!sideBarVisible);
  });

  const FetchSideBar = () => {
    return <>{isBusiness ? <BusinessSidebar /> : <Sidebar />}</>;
  };

  const LocationStatus = async () => {
    Location.locationBlockStatus()
      .then((response) => {
        const status = response.data.is_blocked;

        if (status) {
          setIsBlocked(true);
          navigate(`/business/billing`);
        } else {
          setIsBlocked(false);
        }
      })
      .catch((error) => {});
  };

  const checkAlertNotification = async (id) => {
    try {
      setLoadingAlert(true);
      const response = await Alert.getById(id);
      if (response?.data?.data) {
        dispatch(setNotificationData(response?.data?.data));
      } else if (response?.data?.error === "Notification alert not found") {
        dispatch(setNotificationData(null));
      }
    } catch (error) {
      console.error("Error loading features:", error);
      toast.error("Something went wrong");
    } finally {
      setLoadingAlert(false);
    }
  };

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    await Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        dispatch(setAllLocations(resLocations));
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocation) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select A Location" }));
              }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    const loadStats = async (locationId) => {
      try {
        const response = await Location.getLocationStats(locationId);
        setLocationStats((prevStats) => ({
          ...prevStats,
          [locationId]: response?.data?.message,
        }));
      } catch (error) {
        console.error("Error fetching location stats:", error);
      }
    };

    if (isBusiness && currentPath === "/dashboard") {
      locations?.forEach((location) => {
        loadStats(location.id);
      });
    }
  }, [locations]);

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      setShowNotification({
        show: true,
        type: "success",
        message: "Location Changed!",
      });
    } catch (error) {
      setShowNotification({
        show: true,
        type: "error",
        message: "Error Occurred during Location switching. Try Again!",
      });
    }
  };

  const tooltipStyle = !open ? {} : { display: "none" };

  useEffect(() => {
    if (user && role !== "super-user" && !initialFetch) {
      checkAlertNotification(user?.location?.id);
      dispatch(setInitialAlertFetch(true));
    }
  }, [user, initialFetch]);

  return (
    <>
      <div>
        {isTrial && user?.id !== 1 && (
          <div className="">
            <StickyBanner
              content={notificationMessage}
              linkText="Add Payment"
            />
          </div>
        )}

        {isBlocked && user?.id !== 1 && (
          <div className="">
            <StickyBanner
              content="The location is blocked.You must have a valid card on file to continue. One or more of your cards needs to be verified"
              linkText="Review"
            />
          </div>
        )}

        <div className={`w-full h-full ${1 === 1 ? "" : "pt-14"}`}>
          <Drawer
            title={false}
            placement="left"
            onClose={() => setSideBarVisible(false)}
            visible={sideBarVisible}
            closable={false}
            width={`${open ? "280px" : "100px"}`}
            className="mobile-side-menu"
          >
            {/* {FetchSideBar()} */}
            <BusinessSidebar isEnterprise={isEnterprise} isMobile={true} />
          </Drawer>
          {user?.id === 1 ? (
            <div className="flex h-full">
              <div className="w-full flex flex-col h-screen">
                <AdminNavBar />
                <AdminSwitchNavBar />
                <div
                  className={`px-4 h-full overflow-auto ${
                    darmo ? "bg-white" : "bg-midnight"
                  }`}
                >
                  <div
                    id="page-content"
                    className="container h-full max-w-full pt-[30px] pb-12"
                  >
                    <BreadCrumb darmo={true} />

                    {children}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-full">
              <div className="w-full flex h-screen bg-white">
                <Tooltip
                  id="tooltip-1"
                  style={{ zIndex: 10, ...tooltipStyle }}
                />
                {location?.pathname !== "/subscription" && (
                  <div
                    className={`hidden lg:block relative left-0 ease-in-out duration-200 border bg-white ${
                      open
                        ? "md:w-[14%] min-w-[19rem]"
                        : "md:w-[5%] min-w-[90px]"
                    }`}
                  >
                    <BusinessSidebar
                      isEnterprise={isEnterprise}
                      isMobile={false}
                    />
                  </div>
                )}

                <div
                  className={`flex h-full w-full ${
                    open ? `lg:w-[86%]` : "lg:w-[95%]"
                  } ${
                    location?.pathname === "/subscription" && "lg:w-full"
                  } overflow-hidden`}
                >
                  <div className="flex flex-col h-screen w-full">
                    <Navbar />
                    <div
                      className={`overflow-auto h-full bg-white mx-auto w-full ${
                        location.pathname !== "/subscription" &&
                        (open ? `lg:w-11/12 2xl:w-4/5` : "lg:w-11/12 2xl:w-3/4")
                      } ${darmo ? "md:bg-white" : "md:bg-midnight"}`}
                    >
                      <div
                        id="page-content"
                        className="lg:pt-0 p-4 2xl:px-12 h-full"
                      >
                        <div className="lg:hidden mb-4">
                          <BusinessBreadCrumbs darmo={true} />
                        </div>
                        {location.pathname === "/dashboard" &&
                          (role === "regional_manager" || role === "owner") &&
                          selectedLocation?.name === "Select A Location" && (
                            <div>
                              <h1 className="text-lg font-extrabold tracking-tight pb-4">
                                Main Dashboard
                              </h1>
                            </div>
                          )}
                        {showLocation &&
                          selectedLocation?.name === "Select A Location" && (
                            <>
                              {location.pathname === "/dashboard" && (
                                <div className="block xl:hidden mb-6 xl:mb-0">
                                  <div className="flex items-center">
                                    <img
                                      src={locationTag1Icon}
                                      alt="logo"
                                      className="w-4 h-4 mr-2"
                                    />
                                    <div className="font-extrabold text-[15px]">
                                      Locations{" "}
                                      <span className="text-primary-500 font-sans">
                                        ({locations?.length})
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={`font-extrabold mt-1 mb-3 leading-none text-primary-500 text-sm`}
                                  >
                                    Select a Location to Manage
                                  </div>

                                  <div className="bg-[#F6F6F6] py-3 px-3 rounded-2xl flex flex-col gap-3">
                                    {locations?.map((item, index) => (
                                      <div
                                        key={index}
                                        className="bg-white flex items-center py-2.5 border border-primary-200 shadow-md rounded-2xl px-2 text-[10px] md:text-[12px] md:px-6"
                                      >
                                        <div
                                          className="flex flex-1 justify-start items-center cursor-pointer"
                                          onClick={() => {
                                            dispatch(setSelectedLocation(item));
                                            localStorage.setItem(
                                              "selectedLocation",
                                              JSON.stringify(item)
                                            );
                                            changeLocation(item?.id);
                                            navigate(
                                              `/business/dashboard?location=${item?.id}`
                                            );
                                            dispatch(
                                              setInitialChatFetch(false)
                                            );
                                            dispatch(setVisiblePersons([]));
                                            dispatch(setMinimizedPersons([]));
                                            dispatch(setSelectedPersons([]));
                                          }}
                                        >
                                          <img
                                            src={
                                              item?.brand?.photo?.thumb_url ||
                                              "/logo/violet.svg"
                                            }
                                            alt={"apploy Me"}
                                            className="mr-1 w-6 h-6 md:mr-2 rounded-full my-auto"
                                          />
                                          <div className="font-extrabold text-primary-500 my-auto pt-0.5">
                                            {item?.name}
                                          </div>
                                        </div>

                                        <div className="flex flex-1 ml-2 font-extrabold justify-start md:justify-center items-center">
                                          {locationStats[item?.id] && (
                                            <>
                                              <img
                                                src={openPositionIcon}
                                                alt="logo"
                                                className="w-4 h-4 mr-1 md:mr-2"
                                              />
                                              <span className="mt-0.5">
                                                Open Positions (
                                                {
                                                  locationStats[item?.id][
                                                    "open-positions"
                                                  ]
                                                }
                                                )
                                              </span>
                                            </>
                                          )}
                                        </div>

                                        <div className="flex flex-1 ml-2 font-extrabold justify-end items-center">
                                          <div className="flex items-center">
                                            {locationStats[item?.id] && (
                                              <>
                                                <img
                                                  src={applicantIcon}
                                                  alt="logo"
                                                  className="w-4 h-4 mr-1 md:mr-2"
                                                />
                                                <span className="mt-0.5">
                                                  Total applicants (
                                                  {
                                                    locationStats[item?.id][
                                                      "total-applicants"
                                                    ]
                                                  }
                                                  )
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        {children}
                        {showLocation &&
                          selectedLocation?.name === "Select A Location" &&
                          location.pathname === "/dashboard" && (
                            <div className="hidden xl:block mb-4 pb-12">
                              <div className="flex items-center">
                                <img
                                  src={locationTag1Icon}
                                  alt="logo"
                                  className="w-4 h-4 mr-2"
                                />
                                <div className="font-extrabold text-base">
                                  Locations{" "}
                                  <span className="text-primary-500 font-sans">
                                    ({locations?.length})
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`font-extrabold mt-2 mb-4 text-[12px] leading-none text-primary-500`}
                              >
                                Select a Location to Manage
                              </div>

                              <div className="bg-[#F6F6F6] py-3 px-3 rounded-2xl flex flex-col gap-3">
                                {locations?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center justify-between py-0.5 border border-primary-200 rounded-2xl shadow-md px-4 text-sm bg-white"
                                  >
                                    <div
                                      className="flex flex-1 items-start cursor-pointer"
                                      onClick={() => {
                                        dispatch(setSelectedLocation(item));
                                        localStorage.setItem(
                                          "selectedLocation",
                                          JSON.stringify(item)
                                        );
                                        changeLocation(item?.id);
                                        navigate(
                                          `/business/dashboard?location=${item?.id}`
                                        );
                                        dispatch(setInitialChatFetch(false));
                                        dispatch(setVisiblePersons([]));
                                        dispatch(setMinimizedPersons([]));
                                        dispatch(setSelectedPersons([]));
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.brand?.photo?.thumb_url ||
                                          "/logo/violet.svg"
                                        }
                                        alt={"apploy Me"}
                                        className="mr-2 w-8 h-8 rounded-full my-auto"
                                      />
                                      <div className="font-extrabold text-primary-500 my-auto pt-0.5 text-[14px]">
                                        {item?.name}
                                      </div>
                                    </div>

                                    <div className="flex">
                                      <div className="flex ml-2 font-bold items-center mr-2">
                                        {locationStats[item?.id] && (
                                          <>
                                            <img
                                              src={openPositionIcon}
                                              alt="logo"
                                              className="w-4 h-4 mr-1 my-auto"
                                            />
                                            <span className="text-[10px]">
                                              Open Positions (
                                              {
                                                locationStats[item?.id][
                                                  "open-positions"
                                                ]
                                              }
                                              )
                                            </span>
                                          </>
                                        )}
                                      </div>

                                      <div className="flex ml-2 font-bold items-center mr-2">
                                        {locationStats[item?.id] && (
                                          <>
                                            <img
                                              src={applicantIcon}
                                              alt="logo"
                                              className="w-4 h-4 mr-1 my-auto"
                                            />
                                            <span className="text-[10px]">
                                              Total applicants (
                                              {
                                                locationStats[item?.id][
                                                  "total-applicants"
                                                ]
                                              }
                                              )
                                            </span>
                                          </>
                                        )}
                                      </div>

                                      <div className="flex ml-2 font-bold items-center mr-2">
                                        {locationStats[item?.id] && (
                                          <>
                                            <img
                                              src={startChatBlackIcon}
                                              alt="logo"
                                              className="w-4 h-4 mr-1 my-auto"
                                            />
                                            <span className="text-[10px]">
                                              Total Active Chats (
                                              {
                                                locationStats[item?.id][
                                                  "open-chats"
                                                ]
                                              }
                                              )
                                            </span>
                                          </>
                                        )}
                                      </div>

                                      <div className="flex flex-1 justify-end items-center">
                                        <button
                                          key={index}
                                          className={`px-2 border my-2 flex items-center justify-center bg-midnight text-white transition-all hover:opacity-80 text-center text-[12px] font-bold whitespace-nowrap text-ellipsis rounded-full py-2`}
                                          onClick={() => {
                                            dispatch(setSelectedLocation(item));
                                            localStorage.setItem(
                                              "selectedLocation",
                                              JSON.stringify(item)
                                            );
                                            changeLocation(item?.id);
                                            navigate(
                                              `/business/dashboard?location=${item?.id}`
                                            );
                                            dispatch(
                                              setInitialChatFetch(false)
                                            );
                                            dispatch(setVisiblePersons([]));
                                            dispatch(setMinimizedPersons([]));
                                            dispatch(setSelectedPersons([]));
                                          }}
                                        >
                                          <img
                                            src={locationWhiteIcon}
                                            alt="logo"
                                            className="mr-2 my-auto w-4 h-4"
                                          />
                                          Manage Location
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
      {!open && <Tooltip id={`sidebar-id`} />}
      {!isChatListOpen && <Tooltip id="my-tooltip-chat" />}
      {role !== "super-user" && !loadingAlert && notificationData && (
        <AlertModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          data={notificationData}
        />
      )}
    </>
  );
}

export default DashboardLayout;
