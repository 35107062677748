import React, { useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useTranslation } from "react-i18next";
import ArchivedJob from "../../api/ArchivedJob";
import { toast } from "react-toastify";
import TableActionDropdown from "../../components/TableActionDropdown";
import Modal from "../../components/Modal";
import AddEditPredefinedJob from "../../components/modals/AddEditPredefinedJob";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../../components/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { ModalDeleteWrapper } from "../../components/organisms/ModalDeleteWrapper";
import { FormModal } from "../../components/organisms/FormModal";
import JobOpeningCard from "../../components/molecules/JobOpeningCard";

const ArchivedJobs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [jobToDelete, setJobToDelete] = useState(null);

  useEffect(() => {
    document.title = "HeyHire Portal-Archived Position";
    loadArchivedJobs();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setJobToEdit(null);
    setJobToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadArchivedJobs();
  });

  const unarchiveJob = async (id) => {
    setLoading(true);
    try {
      await ArchivedJob.unachiveJob(id);
      toast.success(t("pre-defined-jobs.pre-defined-job-added"));
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Validation error
      } else {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong-please-try-again"));
        }
      }
      setLoading(false);
    }
  };

  const loadArchivedJobs = () => {
    setLoading(true);
    ArchivedJob.getAll()
      .then((response) => setJobs(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg lg:text-2xl">
            {t("archived-jobs.archived-jobs")}
          </h2>

          {/*<Button
          className="mr-2.5"
          onClick={() => {
            navigate("/predefined-jobs/add");
            // setShowModal(true)
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>*/}
        </div>

        <div className="w-full">
          <div className="overflow-x-auto h-full w-full mt-2 bg-[#F6F6F6] p-3 rounded-2xl">
            {/* <table className="min-w-full divide-y divide-gray-200 mt-3 pb-10">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("archived-jobs.position")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("archived-jobs.type")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("common.category")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider">
                  <div className="tbl-cell">{t("common.status")}</div>
                </th>
                <th scope="col" className="font-bold tracking-wider text-right">
                  <div className="tbl-cell justify-end">
                    {t("common.action")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200"> */}
            {!loading &&
              jobs.map((job, i) => {
                return (
                  <JobOpeningCard
                    key={i}
                    className={"my-1"}
                    jobPosition={job}
                    loadJobs={loadArchivedJobs}
                    darmo={true}
                  />
                );
                //   return (
                //     <tr key={job.id} className="cursor-pointer">
                //       <td>
                //         <div className="tbl-cell">{job.title}</div>
                //       </td>
                //       <td>
                //         <div className="tbl-cell">{job.availability}</div>
                //       </td>
                //       <td>
                //         <div className="tbl-cell">Food & Restaurants</div>
                //       </td>
                //       <td>
                //         <div className="tbl-cell">Archived</div>
                //       </td>
                //       <td className="text-right">
                //         <div className="tbl-cell text-right justify-end">
                //           <TableActionDropdown
                //             onClickEdit={() => {}}
                //             onClickDelete={() => setShowModalDelete(true)}
                //           />
                //         </div>
                //       </td>
                //     </tr>
                //   );
              })}
            {!loading && jobs.length === 0 && (
              <tr>
                <td
                  className="px-4 py-4 whitespace-nowrap text-center"
                  colSpan={12}
                >
                  <p>No Data Available</p>
                </td>
              </tr>
            )}
            {/* </tbody>
          </table> */}
            {loading && <Loading />}
          </div>

          <FormModal
            visible={showModal}
            onClose={() => setShowModal(false)}
            title={
              jobToEdit
                ? t("pre-defined-jobs.edit-predefined-job")
                : t("pre-defined-jobs.add-a-predefined-job")
            }
            onSubmit={unarchiveJob}
          >
            <AddEditPredefinedJob />
          </FormModal>
          <ModalDeleteWrapper
            visible={showModalDelete}
            onClose={() => setShowModalDelete(false)}
            title={t("common.delete") + " " + t("common.address")}
          ></ModalDeleteWrapper>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ArchivedJobs;
