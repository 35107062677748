import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyApi from "../api/Company";
import Button from "../components/form/Button";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import { DropActionDropdown } from "../components/TableActionDropdown";
import AddCompanyModal from "../components/modals/AddCompanyModal";
import ChangeBrandModal from "../components/modals/ChangeBrandModal";
import SearchBar from "../components/atoms/SearchBar";
import { useCustomEventListener } from "react-custom-events";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../components/Loading";
import { DashboardLayout } from "../components/organisms/Layout";
import PaginationView from "../components/molecules/Pagination";
import useQuery from "../hooks/useQuery";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { selectUser } from "../store/authSlice";

const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const [companies, setCompanies] = useState([]);
  const [sorted, setSorted] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const [showChangeBrandModal, setShowChangeBrandModal] = useState(false);
  const [sortByColumn, setSortByColumn] = useState(null);
  const [sortByType, setSortByType] = useState(0); // 0: off, 1: asc, 2: dsc
  const params = useParams();
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 50);
  const [searchCompany, setSearchCompany] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    document.title = "HeyHire Portal-Companies";
  }, []);

  useEffect(() => {
    loadCompanies();
  }, [query]);

  useCustomEventListener("companyAdded", () => {
    setShowModal(false);
    loadCompanies();
  });

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    loadCompanies();
  });

  useCustomEventListener("closeChangeBrandModal", () => {
    setShowChangeBrandModal(false);
    loadCompanies();
  });

  const searchCompanyName = (txt) => {
    setSearchCompany(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredCompanies(sorted);
    } else {
      let _companies = sorted.filter((j) =>
        j.name.toLowerCase().includes(text)
      );
      setFilteredCompanies(_companies);
    }
  };

  const loadCompanies = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData = sortData + pageData + `&limit=${limit}`;
    CompanyApi.getAll(queryData)
      .then((response) => {
        setCompanies(response.data.data);
        setFilteredCompanies(response.data.data);
        setSorted(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const toggleSortBy = (column) => {
    setSortByColumn(column);
    setSortByType(sortByType === 2 ? 0 : sortByType + 1);

    // Update data
    let sortedData = companies.sort((a, b) => {
      if (sortByType === 0) return a.id - b.id;

      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return sortByType === 1 ? -1 : 1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return sortByType === 1 ? 1 : -1;
      }
      return 0;
    });
    setFilteredCompanies(sortedData);
    setSorted(sortedData);
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };
  const handleSortChange = (row) =>
    setSearchParams({ ...searchParams, sort: row });

  return (
    <DashboardLayout darmo={true}>
      {showModal && (
        <AddCompanyModal
          company={editCompany}
          title={
            editCompany
              ? t("companies.edit-company")
              : t("companies.add-a-company")
          }
          onClose={() => setShowModal(!showModal)}
          visible={showModal}
        />
      )}

      {showChangeBrandModal && (
        <ChangeBrandModal
          company={editCompany}
          title="Change Brand"
          onClose={() => setShowChangeBrandModal(!showChangeBrandModal)}
          visible={showChangeBrandModal}
        />
      )}

      <div className="flex justify-between mt-6">
        <h2 className="font-bold text-2xl">Companies</h2>

        <button
          onClick={() => {
            navigate("/companies/add");
          }}
          className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
        >
          <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
        </button>
      </div>
      <SearchBar
        searchValue={searchCompany}
        placeholder="Search Company By Name"
        searchFunction={(value) => searchCompanyName(value)}
      />
      <div className="h-full overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200 whitespace-nowrap">
          <thead className="bg-gray-900 text-white">
            <tr>
              <th scope="col" className="flex gap-1 p-2 text-left font-bold">
                {t("common.name")}
                <FaArrowAltCircleUp
                  onClick={() => handleSortChange("name")}
                  className="cursor-pointer"
                />
              </th>
              <th scope="col" className="font-bold text-left">
                {t("common.owner")}
              </th>
              <th scope="col" className="p-2 font-bold text-center">
                {t("common.number-of-locations")}
              </th>
              <th scope="col" className="p-2 font-bold text-left">
                {t("common.action")}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              filteredCompanies.map((company) => {
                return (
                  <tr
                    key={company.id}
                    className="cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      setEditCompany(company);
                    }}
                  >
                    <td className="p-2 whitespace-nowrap flex">
                      {company.name}
                    </td>
                    <td className="mr-2">
                      {company.owners &&
                        company.owners.length > 0 &&
                        `${company.owners[0].first_name} ${company.owners[0].last_name}`}
                    </td>
                    <td className="text-center">{company.locations.length}</td>
                    <td className="whitespace-nowrap">
                      <div className="tbl-cell justify-center">
                        <DropActionDropdown
                          className="ml-5"
                          icon="far fa-ellipsis-h"
                          iconColor="text-primary-500"
                          menus={
                            user?.roles[0]?.name === "super-user" &&
                            company?.is_enterprise === 1
                              ? [
                                  {
                                    label: "Edit",
                                    action: () => {
                                      setEditCompany(company);
                                      setShowModal(true);
                                    },
                                  },
                                  {
                                    label: "Change Brand",
                                    action: () => {
                                      setEditCompany(company);
                                      setShowChangeBrandModal(true);
                                    },
                                  },
                                ]
                              : [
                                  {
                                    label: "Edit",
                                    action: () => {
                                      setEditCompany(company);
                                      setShowModal(true);
                                    },
                                  },
                                ]
                          }
                        />
                      </div>
                    </td>
                    {/*<td className="px-6 py-4 whitespace-nowrap text-right">
                      <button
                        className={
                          "bg-yellow-400 hover:bg-yellow-500 rounded px-4 py-2"
                        }
                        onClick={() => {
                          setEditCompany(company);
                          setShowModal(true);
                        }}
                      >
                        EDIT
                      </button>
                      </td>*/}
                  </tr>
                );
              })}
          </tbody>
        </table>

        {loading && <Loading count={8} />}
        <div className="flex w-full h-20 mt-2 justify-end items-center">
          {!loading && (
            <PaginationView
              current={metaData?.current_page}
              next={metaData?.to}
              pageSize={metaData?.per_page}
              total={metaData?.total}
              onChange={handlecPageChange}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Companies;
