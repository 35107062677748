import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CannedMessage from "../api/CannedMessage";
import { toast } from "react-toastify";
import Input from "../components/form/Input";
import TextArea from "../components/form/TextArea";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DashboardLayout } from "../components/organisms/Layout";
import { selectRoles, selectSelectedLocation } from "../store/authSlice";

const AddPrecannedMessage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const editMode = location.pathname.search("edit") !== -1;

  const [shortcut, setShortcut] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [accessType, setAccessType] = useState("company");
  const roles = useSelector(selectRoles);
  const selectedLocation = useSelector(selectSelectedLocation);

  const createMessage = () => {
    setLoading(true);
    setValidationErrors(null);

    CannedMessage.create({
      shortcut: shortcut,
      message: message,
      access_type: accessType,
    })
      .then(() => {
        toast.success(t("pc-messages.pre-canned-message-created"));
        navigate("/messages/canned-messages");
        setShortcut("");
        setMessage("");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong"));
          }
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const updateMessage = () => {
    setLoading(true);
    setValidationErrors(null);

    CannedMessage.update(params.id, {
      shortcut: shortcut,
      message: message,
      access_type: accessType,
    })
      .then(() => {
        toast.success(t("pc-messages.message-updated"));
        navigate("/messages/canned-messages");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong"));
          }
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const loadMessage = () => {
    setLoading(true);
    CannedMessage.getById(params.id)
      .then((response) => {
        setShortcut(response.data.data.shortcut);
        setMessage(response.data.data.message);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit Message";
      loadMessage();
    } else {
      document.title = "HeyHire Portal-Add New Message";
    }
    selectAccessType();
  }, []);

  const selectAccessType = () => {
    let accessType = "company";
    if (roles[0] == "super-user") {
      accessType = "system";
    } else if (
      roles[0] == "regional_manager" ||
      (roles[0] == "owner" && selectedLocation?.id)
    ) {
      accessType = "location";
    } else if (roles[0] == "location_manager") {
      accessType = "location";
    } else {
      accessType = "company";
    }
    setAccessType(accessType);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full pt-3 md:pt-6">
        <h2 className="font-bold text-lg lg:text-2xl my-auto">
          {editMode
            ? t("pc-messages.edit-pre-canned-message")
            : t("pc-messages.add-a-pre-canned-message")}
        </h2>
        <div className="py-6 w-full flex flex-col gap-4">
          <Input
            name="shortcut"
            label="Shortcut"
            type="text"
            value={shortcut}
            placeholder={t("pc-messages.write-a-word-to-shortcut")}
            error={
              validationErrors && validationErrors.shortcut
                ? validationErrors.shortcut[0]
                : null
            }
            onChange={(e) => setShortcut(e.target.value)}
            darmo={true}
          />

          <div>
            <TextArea
              className="my-3"
              name="groupDescription"
              label="Message"
              value={message}
              placeholder={t("pc-messages.write-a-pre-canned-message")}
              error={
                validationErrors && validationErrors.message
                  ? validationErrors.message[0]
                  : null
              }
              onChange={(e) => setMessage(e.target.value)}
              darmo={true}
            />
          </div>

          <div className="flex w-full md:w-1/5">
            <button
              className="w-full rounded-xl p-2 m-1 text-white text-[14px] bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50 disabled:bg-violet-200"
              onClick={editMode ? updateMessage : createMessage}
              disabled={loading}
            >
              {t("common.save")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>
          </div>

          {/* <div className="text-center mt-5">
            <button>{t("click-here-to-save-as-draft")}</button>
          </div> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddPrecannedMessage;
