import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PredefinedJob from "../api/PredefinedJob";
import { emitCustomEvent } from "react-custom-events";
import { toast } from "react-toastify";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import TextArea from "../components/form/TextArea";
import { Link, useNavigate } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import Certification from "../api/Certification";
import { DashboardLayout } from "../components/organisms/Layout";
import Category from "../api/Category";

const AddPredefinedJob = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("2033-12-12"); // TODO: Remove after endpoint removes required property
  const [position, setPosition] = useState("full-time");
  const [description, setDescription] = useState("");
  const [experience, setExperience] = useState("");
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const addPredefinedJob = () => {
    setLoading(true);

    PredefinedJob.add({
      title: title,
      start_date: startDate, // TODO: Remove after endpoint removes required property
      availability: position,
      description: description,
      experience: experience,
      category_id: selectedCategory,
    })
      .then(() => {
        emitCustomEvent("closeModal");
        emitCustomEvent("dataChanged");
        toast.success(t("pre-defined-jobs.pre-defined-job-added"));
        navigate("/predefined-jobs");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadCertifications = () => {
    setLoading(true);

    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch()
      .then(() => setLoading(false));
  };

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
      setSelectedCategory(response.data.data[0].id);
    });
  };

  const addCertification = (certification) => {
    // Make a request to the api
    Certification.add({
      category_id: 1,
      name: certification.name,
    }).then(() => {
      setSelectedCertifications([...selectedCertifications, certification]);
    });
  };

  const removeCertification = (index) => {
    const copy = selectedCertifications.slice(0);
    copy.splice(index, 1);
    setSelectedCertifications(copy);
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Create New Position Template";
    loadCertifications();
    loadCategories();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/predefined-jobs"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("pre-defined-jobs.pre-defined-jobs")}
        </h2>
        <hr className="mt-4" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <Input
              name="title"
              label={t("pre-defined-jobs.job-title")}
              type="text"
              value={title}
              placeholder={t("pre-defined-jobs.job-title")}
              error={
                validationErrors && validationErrors.title
                  ? validationErrors.title[0]
                  : null
              }
              onChange={(e) => setTitle(e.target.value)}
              darmo={"true"}
            />

            <div>
              <Select
                label={t("common.category")}
                value={selectedCategory}
                name="start_date"
                error={
                  validationErrors && validationErrors.category_id
                    ? validationErrors.category_id[0]
                    : null
                }
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                }}
              >
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Select>
            </div>

            <Select
              label={t("pre-defined-jobs.type-of-position")}
              value={position}
              name="company"
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            >
              <option value="full-time">Full Time</option>
              <option value="part-time">Part Time</option>
            </Select>
          </div>

          <TextArea
            className="my-3"
            name="description"
            label={t("pre-defined-jobs.description")}
            value={description}
            placeholder={t("pre-defined-jobs.description")}
            error={
              validationErrors && validationErrors.description
                ? validationErrors.description[0]
                : null
            }
            onChange={(e) => setDescription(e.target.value)}
            darmo={"true"}
          />

          <TextArea
            className="my-3"
            name="experience"
            label={t("pre-defined-jobs.required-experience")}
            value={experience}
            placeholder={t("pre-defined-jobs.required-experience")}
            error={
              validationErrors && validationErrors.experience
                ? validationErrors.experience[0]
                : null
            }
            onChange={(e) => setExperience(e.target.value)}
            darmo={"true"}
          />

          <span className={"block px-3 mb-2"}>
            {t("pre-defined-jobs.required-certifications")}
          </span>

          <ReactTags
            tags={selectedCertifications}
            suggestions={certifications}
            onDelete={removeCertification}
            onAddition={addCertification}
            newTagText={"Add New Certification:"}
            placeholderText={"Search Certifications"}
            allowNew={true}
            classNames={{
              root: "flex p-3 border-2 rounded border-gray-300 focus-within:border-primary-300",
              searchWrapper: "",
              searchInput: "focus:outline-0",
              rootFocused: "focus:bg-blue-500",
              suggestions:
                "p-3 min-w-[250px] z-30 bg-white absolute shadow-lg mt-1 cursor-pointer",
              suggestionActive: "bg-red-500",
            }}
            tagComponent={({ tag, removeButtonText, onDelete }) => {
              return (
                <button
                  className={
                    "bg-primary-400 hover:bg-primary-500 text-white text-sm rounded-full p-1 px-3 mr-2"
                  }
                  onClick={onDelete}
                >
                  {tag.name} <i className={"fas fa-times-circle ml-2"} />
                </button>
              );
            }}
          />

          <hr className="mt-8" />

          <div className="flex w-full md:w-1/5">
            <button
              className="w-full rounded-xl p-2 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
              onClick={(e) => {
                e.preventDefault();
                addPredefinedJob();
              }}
              disabled={loading}
            >
              {t("common.save")}
              {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              ) : (
                ""
              )}
            </button>
          </div>

          <div className="text-center mt-5">
            <button>{t("click-here-to-save-as-draft")}</button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddPredefinedJob;
