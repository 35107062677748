import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import "./ResumeViewer.scss";
import { resumeViewerPDFIcon } from "../../../assets/images";

const ResumeViewer = ({ applicant }) => {
  const [docs, setDocs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (applicant && applicant?.file && applicant?.file?.length > 0) {
      let resumeUrl = applicant?.file[0]?.resume_url;
      // resumeUrl = resumeUrl?.replace(/(?<!:)\/\//g, "/");
      resumeUrl = resumeUrl?.replace(/([^:])\/\//g, "$1/");

      const fileName = applicant?.file[0]?.file_name;
      const fileType = (fileName?.match(/\.([^.]+)$/) || [
        ,
        "pdf",
      ])[1].toLowerCase();

      const newDocs = [
        {
          uri: resumeUrl,
          fileType,
          fileName,
        },
      ];
      setDocs(newDocs);
      setTimeout(() => {
        toggleSection();
      }, 2000);
      setIsLoading(false);
    } else {
      setDocs([]);
      setIsLoading(false);
    }
  }, [applicant]);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="rounded mt-2 border-2 shadow-md border-gray-300">
      <dl>
        <div>
          <dt>
            <div className="flex w-full items-start justify-between text-left py-2 px-4">
              <div className="flex">
                <img
                  src={resumeViewerPDFIcon}
                  alt="resume-logo"
                  className="mr-2 w-5 h-5 my-auto"
                />{" "}
                <span className="font-bold text-lg my-auto">Resume</span>
              </div>
              <span
                className="ml-6 flex h-7 items-center cursor-pointer"
                onClick={toggleSection}
              >
                {isOpen ? (
                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </div>
          </dt>
          <dd>
            {isLoading ? (
              <div className="p-4">Loading...</div>
            ) : isOpen ? (
              docs?.length > 0 ? (
                <DocViewer
                  documents={docs}
                  initialActiveDocument={docs[0]}
                  pluginRenderers={DocViewerRenderers}
                  className="custom-doc-viewer"
                  theme={{
                    primary: "rgb(243 244 246)",
                    secondary: "#ffffff",
                    tertiary: "#ffffff",
                    textPrimary: "#000000",
                    textSecondary: "#5296d8",
                    textTertiary: "#000000",
                    disableThemeScrollbar: true,
                  }}
                  config={{
                    header: { disableHeader: true },
                    pdfZoom: {
                      defaultZoom: 0.7,
                    },
                  }}
                />
              ) : (
                <div className="p-4">No Resume</div>
              )
            ) : null}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default ResumeViewer;
