import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";

import Company from "../../../api/Company";
import FormInput from "../../atoms/FormInput";
import FormSelect from "../../atoms/FormSelect";

const FormBrand = () => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);
    });
  };

  return (
    <Space size={20} direction="vertical" className="w-full">
      <FormInput
        name="name"
        label={t("brands.brand-name")}
        placeholder={t("brands.add-a-brand")}
      />
      <FormSelect
        name="company_id"
        label={t("common.company")}
        placeholder=""
        options={companies.map((_company) => ({
          value: _company.id,
          label: _company.name,
        }))}
      />
      <FormInput
        name="website"
        label={t("common.website")}
        placeholder="www.example.com"
      />
    </Space>
  );
};

export default FormBrand;
