import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JobPosition from "../api/JobPosition";
import Category from "../api/Category";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import TableActionDropdown from "../components/TableActionDropdown";
import Modal from "../components/Modal";
import AddEditPredefinedJob from "../components/modals/AddEditPredefinedJob";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../components/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { DashboardLayout } from "../components/organisms/Layout";
import { encodeIds } from "../utils";
import { selectSelectedLocation } from "../store/authSlice";
import RBAC from "../api/RBAC";

const getJobStatus = (job) => {
  if (job?.archived_at != null) return "Archived";
  else if (job?.deleted_at != null) return "Deleted";
  else return "Active";
};

const JobPositions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [categories, setCategories] = useState([]);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [isPositionCreated, setPositionCreated] = useState(false);
  const [isPositionUpdated, setPositionUpdated] = useState(false);
  const [isPositionDeleted, setPositionDeleted] = useState(false);

  const checkPositionCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionEdit = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "update",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionUpdated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionDelete = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionDeleted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadPredefinedJobs();
    loadCategories();
    // checkPositionCreate();
    // checkPositionEdit();
    // checkPositionDelete();
    document.title = "HeyHire Portal-Active Position";
  }, [selectedLocation]);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setJobToEdit(null);
    setJobToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadPredefinedJobs();
  });

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
    });
  };

  const loadPredefinedJobs = () => {
    setLoading(true);

    JobPosition.getAll()
      .then((response) => setJobs(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const getJobCategory = (id) => {
    const _category = categories.filter((item) => item.id === id);
    return _category[0]?.name;
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg lg:text-2xl my-auto">Positions</h2>
          {/* {selectedLocation?.id && isPositionCreated && ( */}
          <button
            onClick={() => {
              navigate("/job-positions/add");
            }}
            className="flex w-28 h-9 justify-center rounded-xl bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
          >
            <i className="far fa-plus my-auto mr-2" /> {t("common.add")}
          </button>
          {/* )} */}
        </div>

        <div className="overflow-x-auto overflow-hidden mt-6">
          <table className="min-w-full">
            <thead className="bg-gray-900 text-white whitespace-nowrap">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  {t("pre-defined-jobs.position")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-bold tracking-wider"
                >
                  {t("common.category")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  Location
                </th>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  No. of Applicants
                </th>
                <th
                  scope="col"
                  className="px-6 py-2.5 text-left font-bold tracking-wider"
                >
                  {t("common.status")}
                </th>
                {/* {(isPositionUpdated || isPositionDeleted) && */}
                <th
                  scope="col"
                  className="px-6 py-2.5 font-bold tracking-wider text-right"
                >
                  {t("common.action")}
                </th>
                {/* } */}
              </tr>
            </thead>
            <tbody className="divide-white divide-y-8">
              {!loading &&
                jobs.map((job) => {
                  return (
                    <tr key={job.id} className="bg-[#f6f6f6]">
                      <td
                        className="px-6 py-3 whitespace-nowrap cursor-pointer hover:underline"
                        onClick={() =>
                          navigate(`/business/view-job/${encodeIds(job?.id)}`)
                        }
                      >
                        {job.title}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {getJobCategory(job?.location?.category_id)}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {job?.location?.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {job?.applications?.length}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {getJobStatus(job)}
                      </td>
                      {/* {(isPositionUpdated || isPositionDeleted) && */}
                      <td className="px-6 py-3 whitespace-nowrap text-right cursor-pointer">
                        <TableActionDropdown
                          onClickEdit={() =>
                            navigate(`/job-positions/edit/${encodeIds(job.id)}`)
                          }
                          onClickDelete={() => setJobToDelete(job)}
                          // isUpdate={isPositionUpdated}
                          // isDelete={isPositionDeleted}
                          // isChanged={true}
                        />
                      </td>
                      {/* } */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {loading && <Loading />}

        {showModal ? (
          <Modal title={jobToEdit ? "Edit Job Position" : "Add Job Position"}>
            <AddEditPredefinedJob job={jobToEdit} type={"job-position"} />
          </Modal>
        ) : (
          ""
        )}

        {jobToDelete && (
          <DeleteModal
            title={t("common.delete") + " Job Position"}
            name="Job Position"
            deleteRequest={() => JobPosition.delete(jobToDelete.id)}
            onSuccess={() => loadPredefinedJobs()}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default JobPositions;
