import React, { useEffect, useState } from "react";
import Input from "./form/Input";
import FormSelect from "./atoms/FormSelect";
import PlacesAutocomplete from "./form/PlacesAutocomplete";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import Company from "../api/Company";
import AddressBook from "../api/AddressBook";
import { toast } from "react-toastify";

const AddAddressContent = ({ modal = false, companyId = null }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [company, setCompany] = useState(companyId);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const editMode = location.pathname.search("edit") !== -1;
  const disabled = true;

  useCustomEventListener("placeSelected", (data) => {
    setAddress(data.addressLine);
    setState(data.terms[data.terms.length - 2].value);
    setCity(data.terms[data.terms.length - 3].value);
    setZip(data.zipCode);
    setLat(data.lat);
    setLng(data.lng);
  });

  const loadCompanies = () => {
    Company.getAll().then((response) => setCompanies(response.data.data));
  };

  const addAddress = () => {
    setLoading(true);
    setValidationErrors(null);

    const param = {
      name: name,
      address: address,
      city: city,
      state: state,
      zip: zip,
      lat: lat,
      lng: lng,
      company_id: company,
    };

    const request = !editMode
      ? AddressBook.add(param)
      : AddressBook.update(params.id, param);

    request
      .then((response) => {
        toast.success(
          editMode
            ? t("address-book.address-updated")
            : t("address-book.address-added")
        );

        if (!modal) {
          emitCustomEvent("dataChanged");
          emitCustomEvent("closeModal");
          navigate("/admin/address-book");
        } else {
          emitCustomEvent("addressAdded", response.data.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadAddress = () => {
    setLoading(true);

    AddressBook.getById(params.addressId)
      .then((response) => {
        response = response.data.data;
        setName(response.name);
        setCompany(response.company_id);
        setAddress(response.address);
        setCity(response.city);
        setState(response.state);
        setZip(response.zip);
        setLat(response.lat);
        setLng(response.lng);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    loadCompanies();

    if (editMode) {
      loadAddress();
    }
  }, []);

  return (
    <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
      <div
        className={
          modal
            ? "grid grid-cols-1 gap-2"
            : "grid grid-cols-1 md:grid-cols-3 gap-2"
        }
      >
        <Input
          darmo={true}
          name="name"
          label={t("common.name")}
          type="text"
          value={name}
          placeholder={t("common.name")}
          error={
            validationErrors && validationErrors.name
              ? validationErrors.name[0]
              : null
          }
          onChange={(e) => setName(e.target.value)}
        />

        {!modal && (
          <div>
            <FormSelect
              darmo={true}
              name="company"
              label={t("locations.assign-a-business")}
              defaultValue={company}
              placeholder={t("locations.assign-a-business")}
              options={companies.map((_company) => ({
                value: _company.id,
                label: _company.name,
              }))}
              onChange={(value) => {
                setCompany(value);
              }}
              disabled={modal}
            />
          </div>
        )}

        <PlacesAutocomplete
          darmo={true}
          label={t("common.address")}
          placeholder={t("common.address-example")}
          pValue={address}
          error={
            validationErrors && validationErrors.address
              ? validationErrors.address[0]
              : null
          }
        />

        {!disabled && (
          <Input
            darmo={true}
            name="city"
            label={t("common.city")}
            type="text"
            value={city}
            placeholder={t("address-book.city-example")}
            error={
              validationErrors && validationErrors.city
                ? validationErrors.city[0]
                : null
            }
            onChange={(e) => setCity(e.target.value)}
            disabled={true}
          />
        )}

        {!disabled && (
          <Input
            darmo={true}
            name="name"
            label={t("common.state")}
            type="text"
            value={state}
            placeholder={t("address-book.state-example")}
            error={
              validationErrors && validationErrors.state
                ? validationErrors.state[0]
                : null
            }
            onChange={(e) => setState(e.target.value)}
            disabled={true}
          />
        )}

        {!disabled && (
          <Input
            darmo={true}
            name="zip"
            label={t("common.zip")}
            type="text"
            value={zip}
            placeholder={"90210"}
            error={
              validationErrors && validationErrors.zip
                ? validationErrors.zip[0]
                : null
            }
            onChange={(e) => setZip(e.target.value)}
            disabled={true}
          />
        )}
      </div>

      <hr className="my-8" />

      <div className="flex w-full md:w-1/5">
        <button
          className="w-full rounded-md p-2 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
          onClick={(e) => {
            e.preventDefault();
            addAddress();
          }}
          disabled={loading}
        >
          {t("common.save")}
          {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
        </button>
      </div>

      <div className="text-center mt-5">
        {!modal && <button>{t("click-here-to-save-as-draft")}</button>}
      </div>
    </div>
  );
};

export default AddAddressContent;
