import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCustomEventListener } from "react-custom-events";

import AddressBookApi from "../../../api/AddressBook";
import AddAddressModal from "../../../components/modals/AddAddressModal";
import Button from "../../../components/form/Button";
import TableActionDropdown from "../../../components/TableActionDropdown";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { ModalDeleteWrapper } from "../../../components/organisms/ModalDeleteWrapper";
import { FormModal } from "../../../components/organisms/FormModal";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/modals/DeleteModal";
import LocationApi from "../../../api/Location";

const AddressBook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [addressBook, setAddressBook] = useState([]);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    document.title = "HeyHire Portal-Address Book";
    loadAddressBook();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setAddressToEdit(null);
    setAddressToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadAddressBook();
  });

  const loadAddressBook = () => {
    setLoading(true);
    AddressBookApi.getAll()
      .then((response) => setAddressBook(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">{t("address-book.address-book")}</h2>

        <Button
          onClick={() => {
            navigate("add");
            // setShowModal(true)
          }}
        >
          <i className="far fa-plus" /> {t("common.add")}
        </Button>
      </div>

      <div className="overflow-x-auto overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 mt-3">
          <thead className="bg-white">
            <tr>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.name")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.address")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">
                  {t("common.address")} 2
                </div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.city")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.state")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold">{t("common.zip")}</div>
              </th>
              <th scope="col">
                <div className="tbl-cell font-bold justify-end">
                  {t("common.action")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!loading &&
              addressBook.map((address) => {
                return (
                  <tr key={address.id} className="cursor-pointer">
                    <td onClick={() => navigate(`${address.id}/edit`)}>
                      <div className="tbl-cell">{address.name}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{address.address}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{address.address2}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{address.city}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{address.state}</div>
                    </td>
                    <td>
                      <div className="tbl-cell">{address.zip}</div>
                    </td>
                    <td>
                      <div className="tbl-cell justify-end">
                        <TableActionDropdown
                          onClickEdit={null}
                          onClickDelete={() => setAddressToDelete(address.id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {loading && <Loading />}

      {showModal && <AddAddressModal />}
      <FormModal
        visible={false}
        onClose={() => setShowModal(false)}
        title={
          addressToEdit
            ? t("address-book.edit-address")
            : t("address-book.add-address")
        }
        //   onSubmit={addPredefinedJob}
      >
        <AddAddressModal />
      </FormModal>

      {addressToDelete && (
        <DeleteModal
          title="Delete Address"
          name="Address"
          deleteRequest={() => AddressBookApi.delete(addressToDelete)}
          onSuccess={() => {
            setAddressToDelete(null);
            loadAddressBook();
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default AddressBook;
