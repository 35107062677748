import axios from "./api";

const base = "/business/notification";

const Notification = {
  getAllNotifications() {
    return axios.get(`${base}/getAll`);
  },
  getUnreadCount() {
    return axios.get(`${base}/unread-count`);
  },
  updateReadAt(data) {
    return axios.post(`${base}/read`, data);
  },
};

export default Notification;
